import React, { useEffect } from 'react'
import { modalService } from 'services/ModalService'
import { RemoveBonusConfirm } from '@it25syv/25syv-ui'
import BonusService, { setBonusList } from 'services/BonusService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { forfeitBonus } from 'common/adapters/ProviderAdapter'
import UserService from 'services/UserService'

const RemoveBonusConfirmModal = ({ data }) => {
    const { bonusAmount } = data

    const removeBonus = () => {
        forfeitBonus()
            .then((res) => {
                if (res && res.success) {
                    BonusService.removeActiveBonus()
                    UserService.setIsUserLoggedIn()
                    modalService.closeModal('REMOVE_BONUS_CONFIRM')
                } else {
                    NotificationConductor.error('Something went wrong')
                    modalService.closeModal('REMOVE_BONUS_CONFIRM')
                }
            })
            .catch((error) => {
                console.log(error)
                NotificationConductor.error(error.toString())
            })
    }

    return (
        <RemoveBonusConfirm
            bonusAmount={bonusAmount}
            onConfirm={removeBonus}
            onCancel={() => {
                modalService.closeModal('REMOVE_BONUS_CONFIRM')
            }}
        />
    )
}

export default RemoveBonusConfirmModal
