import React, { useEffect, useState } from 'react'
import GamesService from 'services/GamesService'
import { useHistory } from 'react-router-dom'
import { withTheme } from 'styled-components'
import WithGames from 'common/Hocs/WithGames'
import moment from 'moment'
import GamesSlider from 'features/Carousel/NewGamesSlider'

const NewGamesPage = ({ gamesToMap = [] }) => {
    const [newGames, setNewGames] = useState([])
    const [upcomingGames, setUpcomingGames] = useState([])
    const [newLoader, setNewLoader] = useState(true)
    const [upcomingLoader, setUpcomingLoader] = useState(true)
    const history = useHistory()

    useEffect(
        () => {
            if (gamesToMap.length) {
                const filteredGames = gamesToMap
                    .filter(
                        (game) =>
                            game.casinoPageCategories.includes('new') &&
                            game.category.includes('slots')
                    )
                    .sort((a, b) => a.priority - b.priority)
                setNewGames(filteredGames)
                setNewLoader(false)
            }
        },
        [gamesToMap]
    )

    useEffect(() => {
        GamesService.getUpcomingGames()
            .then((games) => {
                const sortedByDate = games.sort(
                    (a, b) =>
                        moment(a.release_date).format('YYYYMMDD') -
                        moment(b.release_date).format('YYYYMMDD')
                )
                setUpcomingGames(sortedByDate)
                setUpcomingLoader(false)
            })
            .catch((e) => {
                console.error('Upcoming games failed: ', e)
                setUpcomingLoader(false)
            })
    }, [])

    const startGame = (game) => {
        if (game.game_id) {
            GamesService.checkLoginBeforeStart(game, history)
            if (
                localStorage.getItem('logged') !== null &&
                localStorage.getItem('logged') !== 'false'
            ) {
                GamesService.pushGameToRecentlyPlayed(game)
            }
        }
    }

    return (
        <>
            <GamesSlider
                title={'Nye spil'}
                allButton
                gamesList={newGames}
                onClick={(game) => startGame(game)}
                loader={newLoader}
            />
            <GamesSlider
                title={'Kommende spil'}
                gamesList={upcomingGames}
                upcoming
                onClick={() => {}}
                loader={upcomingLoader}
                showFavoriteIcon={false}
            />
        </>
    )
}

export default withTheme(WithGames(NewGamesPage))
