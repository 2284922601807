import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { modalService } from 'services/ModalService'
import Box from '@/ui/Box'
import MediaGrid from 'ui/MediaGrid'
import GameList from 'features/GamesList/GameList'
import Flex from '@/ui/Flex'
import Text from '@/ui/Text'
import Displayer from 'ui/Displayer'
import gamesService from 'services/GamesService'
import SearchInput from 'features/SearchModal/SearchInput'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import Icon from 'ui/Icon'
import cross from '@/images/icons/basic-icons/cross.svg'

const StyledMediaGrid = styled(MediaGrid)`
    @media (min-width: ${(props) => props.theme.breakpoints.md + 'px'}) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem 1rem;
    }
`
const SearchInputWrapper = styled.div`
    margin: 1rem auto;
    max-width: 700px;
    width: 100%;
`
const StyledSearchInput = styled(SearchInput)`
    width: 90%;
`

const ModalWrapper = styled(Box)`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    top: 0;
    width: 100%;
    height: 100%;
    ${!detectMobile() &&
        css`
            height: 95%;
        `};
    background-color: white;

    @media (min-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        border-radius: 8px;
        width: 600px;
        position: relative;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`
const CloseButton = styled(Icon)`
    color: #4e4e4e;
    cursor: pointer;
`

let categoryDelay = null

const SearchModal = ({ data }) => {
    const [allGames, setAllGames] = useState([])
    const [searchStr, setSearchStr] = useState(data?.props?.initSearchText)
    const [displayedGames, setDisplayedGames] = useState([])

    useEffect(() => {
        const allGamesSubscription = gamesService
            .getAllProvidersGames()
            .subscribe((games) => {
                console.log('games', games)
                setAllGames(games)
            })
        return () => {
            allGamesSubscription.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            console.log('searchStr', searchStr)
            console.log('allGames', allGames)
            clearTimeout(categoryDelay)
            categoryDelay = setTimeout(() => {
                searchStr.length !== 0
                    ? setDisplayedGames(
                          gamesService.filterGamesByName(allGames, searchStr)
                      )
                    : data?.props?.initNotEmpty
                        ? setDisplayedGames(allGames)
                        : setDisplayedGames([])
            }, 500)
        },
        [searchStr, allGames]
    )

    const onHandler = (searchStr) => {
        setSearchStr(searchStr)
    }

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            event.target.blur()
        }
    }

    return (
        <ModalWrapper>
            <SearchInputWrapper>
                <Flex mx={3} justify={'space-between'}>
                    <StyledSearchInput
                        autoFocus
                        placeholder={'Søg efter spil'}
                        background={'white'}
                        controlColor={'gray'}
                        hideCancel={true}
                        value={searchStr}
                        onChange={onHandler}
                        onKeyPress={keyPressHandler}
                        on
                    />
                    <CloseButton
                        size={'md'}
                        src={cross}
                        onClick={() => modalService.closeModal()}
                    />
                </Flex>
            </SearchInputWrapper>
            {displayedGames && (
                <Displayer
                    display={displayedGames.length ? 'block' : 'none'}
                    style={{
                        overflowY: 'scroll',
                        scrollBehavior: 'smooth',
                        height: '100%',
                    }}
                >
                    <Box py={2} px={3}>
                        <StyledMediaGrid>
                            <GameList
                                games={displayedGames}
                                onGameClick={() => {
                                    modalService.closeModal()
                                }}
                            />
                        </StyledMediaGrid>
                    </Box>
                </Displayer>
            )}

            {displayedGames.length === 0 &&
            allGames.length !== 0 &&
            searchStr.length !== 0 ? (
                <Text align={'center'} pt={3}>
                    Ingen match fundet.
                </Text>
            ) : null}
        </ModalWrapper>
    )
}

export default SearchModal
