import React from 'react'
import puzzle from 'icons/basic-icons/puzzle.svg'
import ProviderListCard from 'features/ProvidersContent/ProviderListCard'
import {
    ProvidersContainer,
    ProvidersText,
} from 'features/ProvidersContent/styled'
import NewGamesGridTitle from 'ui/NewGamesGridTitle'
import Headline from 'ui/Headline'
import COLORS from 'themes/primary/palette'

const ProvidersList = ({ providerClick, providersList }) => {
    return (
        <>
            <Headline
                as={'h3'}
                color={COLORS.dark}
                label={providersList.length}
                colorLabel={COLORS.gray}
            >
                Spiludviklere
            </Headline>
            <ProvidersContainer>
                {providersList.map((provider) => (
                    <ProviderListCard
                        provider={provider}
                        key={provider.name}
                        handleClick={providerClick}
                    />
                ))}
            </ProvidersContainer>
        </>
    )
}

export default ProvidersList
