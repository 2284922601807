import React, { useEffect } from 'react'
import LazyLoad from 'react-lazyload'
import skeletonImg from 'images/game-vertical-skeleton.jpg'
import Text from 'ui/Text'
import {
    GamesCount,
    ImageOverlay,
    InfoWrapper,
    ProviderCard,
    ProviderImage,
    ProviderLogo,
    ProviderWrapper,
    LabelWrapper,
    ImageText,
} from 'features/ProvidersContent/styled'
import Label from 'ui/Label'
import { LIVE_GAME_LABEL, NEW_GAME_LABEL } from '../../configs/ui'

const ProviderListCard = ({ provider, handleClick }) => {
    const isUpcoming =
        (provider && provider.upcoming) ||
        (provider && provider?.games?.count === 0)

    const showNewLabel = !isUpcoming ? NEW_GAME_LABEL : null

    const labelProps = provider?.new
        ? showNewLabel
        : provider?.live
            ? LIVE_GAME_LABEL
            : null

    const enableClick = !isUpcoming

    return (
        <ProviderWrapper
            upcoming={provider.upcoming}
            onClick={() => {
                enableClick && handleClick(provider)
            }}
        >
            <ProviderCard>
                <ImageOverlay upcoming={isUpcoming}>
                    <LazyLoad
                        once
                        placeholder={
                            <img
                                height={'auto'}
                                width={'100%'}
                                src={skeletonImg}
                                alt="skeletonImg"
                                style={{ borderRadius: 8, maxHeight: '327px' }}
                            />
                        }
                        overflow={false}
                    >
                        <LabelWrapper>
                            <Label {...labelProps} />
                        </LabelWrapper>
                        <ProviderImage
                            src={provider.verticalImage || skeletonImg}
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = skeletonImg
                            }}
                            alt={`provider-image-${provider.name || ''}`}
                            loading="lazy"
                        />
                        {isUpcoming ? (
                            <ImageText bold size={'lg'}>
                                Kommer snart
                            </ImageText>
                        ) : null}
                    </LazyLoad>
                </ImageOverlay>
                <InfoWrapper>
                    <ProviderLogo
                        height="50px"
                        width="150px"
                        color={'white'}
                        src={provider.logo || ''}
                        alt={`provider-logo-${provider.label || ''}`}
                    />
                    {!isUpcoming ? (
                        <GamesCount>
                            {!isUpcoming ? provider?.games?.count : 'XXX'} spil
                        </GamesCount>
                    ) : null}
                </InfoWrapper>
            </ProviderCard>
            <Text align={'center'} style={{ paddingTop: '15px' }}>
                {provider.label || ''}
            </Text>
        </ProviderWrapper>
    )
}

export default ProviderListCard
