import React, { lazy } from 'react'
import { withTheme } from 'styled-components'
import { withAnimation } from 'features/UserArea/Components/withAnimation'

import UserArea from 'features/UserArea'
import UserProfile from 'features/UserArea/Components/UserProfile/index.jsx'
import UserProfileSuccess from 'features/UserArea/Components/UserProfile/UserProfileSuccessPopup.jsx'
import Voucher from 'features/UserArea/Components/Voucher'
import SmartFilters from 'features/SmartFilters/SmartFilters'
import SortModal from 'features/SortModal/SmartSorts'
import SignUp from 'features/SignUp'
import PromoPage from 'features/Promo'
import Deposit from 'features/Deposit'
import UpdatePassword from '@/features/UserArea/Components/UpdatePassword'
import GamePopup from 'features/GamePopup'
import GameInfo from 'features/GameInfo'
import UserHistory from 'features/UserHistory'
import PinLogin from 'features/PinLogin'
import PinLoginPopup from 'features/PinLogin/PinLoginPopup'
import UserBonus from 'features/UserBonus'
import BonusPopup from 'features/UserArea/Components/BonusPopup'
import DepositLimit from 'features/DepositLimit'
import FastDepositLimit from 'features/DepositLimit/FastDepositLimit'
import SelfExclusion from 'features/SelfExclusion'
import SelfExclusionTextModal from 'features/SelfExclusion/SelfExcludedTextModal'
import Withdraw from 'features/Withdraw'
import LoaderCover from 'features/LoaderCover/LoaderCover'
import BrandBookModal from 'features/BrandBookModal'
import TextPage from '@/layouts/sections/LeftSidebar/TextPage'
import Security from 'features/UserArea/Components/Security'
import Notifications from 'features/UserArea/Components/Notifications'
import ProductReview from 'features/UserArea/Components/ProductReview'
import SidebarPopup from 'features/SideNavigation/BrandPopup'
import FeedbackModal from 'features/Feedback/FeedbackModal'
import GeoDataModal from 'features/GeoDataModal/GeoDataModal'
import SearchModal from 'features/SearchModal/SearchModal'
import SessionPopup from 'features/SessionPopup'
import VerificationPopup from 'features/VerificationPopup'
import DepositLimitPopup from 'features/DepositLimitPopup'
import CPRConfirmModal from 'features/Login/CPRConfirmModal'
import RemoveBonusConfirmPopup from 'features/Withdraw/RemoveBonusConfirmModal'
import LockOrientationModal from 'features/LockOrientationModal/LockOrientationModal'
import PWAPop from 'features/PWAPop'

import EmailAlreadyExist from 'features/SignUp/EmailAlreadyExist'
import Login from 'features/Login'
import LoginForgotPass from 'features/Login/LoginForgotPass'
import RestorePass from 'features/Login/RestorePass'
import TransactionDetails from 'features/UserHistory/TransactionDetails'
import TransactionChooseDate from 'features/UserHistory/TransactionChooseDate'
import WithdrawNotificationPopup from '../features/Withdraw/WithdrawNotificationPopup'

// const UserArea = lazy(() => import('features/UserArea'))
// const UserProfile = lazy(() => import('@/features/UserArea/Components/UserProfile'))
// const SmartFilters = lazy(() => import('features/SmartFilters/SmartFilters'))
// const SortModal = lazy(() => import('features/SortModal/SmartSorts'))
// const SignUp = lazy(() => import('features/SignUp'))
// const SignUpDesk = lazy(() => import('features/SignUp/Desktop'))
// const PromoPage = lazy(() => import('features/Promo'))
// const Deposit = lazy(() => import('features/Deposit'))
// const UpdatePassword = lazy(() =>
//     import('@/features/UserArea/Components/UpdatePassword')
// )
// const GamePopup = lazy(() => import('features/GamePopup'))
// const GameInfo = lazy(() => import('features/GameInfo'))
// const UserHistory = lazy(() => import('features/UserHistory'))
// const PinLogin = lazy(() => import('features/PinLogin'))
// const UserBonus = lazy(() => import('features/UserBonus'))
// const BonusPopup = lazy(() => import('features/UserArea/Components/BonusPopup'))
// const DepositLimit = lazy(() => import('features/DepositLimit'))
// const FastDepositLimit = lazy(() =>
//     import('features/DepositLimit/FastDepositLimit')
// )
// const SelfExclusion = lazy(() => import('features/SelfExclusion'))
// const Withdraw = lazy(() => import('features/Withdraw'))
// const ForgotPassword = lazy(() =>
//     import('features/Login/Desktop/NemIdLoginModalDesk')
// )
// const LoaderCover = lazy(() => import('features/LoaderCover/LoaderCover'))
// const BrandBookModal = lazy(() => import('features/BrandBookModal'))
// const TextPage = lazy(() => import('@/layouts/sections/LeftSidebar/TextPage'))
// const Security = lazy(() => import('features/UserArea/Components/Security'))
// const Notifications = lazy(() =>
//     import('features/UserArea/Components/Notifications')
// )
// const ProductReview = lazy(() =>
//     import('features/UserArea/Components/ProductReview')
// )
// const SidebarPopup = lazy(() => import('features/SideNavigation/BrandPopup'))
// const FeedbackModal = lazy(() => import('features/Feedback/FeedbackModal'))
// const GeoDataModal = lazy(() => import('features/GeoDataModal/GeoDataModal'))
// const SearchModal = lazy(() => import('features/SearchModal/SearchModal'))
// const SessionPopup = lazy(() => import('features/SessionPopup'))
// const VerificationPopup = lazy(() => import('features/VerificationPopup'))
// const DepositLimitPopup = lazy(() => import('features/DepositLimitPopup'))
// const CPRConfirmModal = lazy(() => import('features/Login/CPRConfirmModal'))
// const RemoveBonusConfirmPopup = lazy(() =>
//     import('features/Withdraw/RemoveBonusConfirmModal')
// )

const ModalsMap = [
    {
        name: 'LOGIN',
        type: 'modal',
        component: Login,
        url: '/log-ind',
    },
    {
        name: 'LOGIN_FORGOT_PASS',
        type: 'modal',
        component: LoginForgotPass,
    },
    {
        name: 'RESTORE_PASS',
        type: 'modal',
        component: RestorePass,
    },
    {
        name: 'PIN_LOGIN',
        type: 'modal',
        component: PinLogin,
    },
    {
        name: 'PIN_LOGIN_POPUP',
        type: 'popup',
        component: withAnimation(withTheme(PinLoginPopup)),
    },
    {
        name: 'SIGN_UP',
        type: 'modal',
        component: SignUp,
        url: '/opret-konto',
    },
    {
        name: 'PROMO',
        type: 'modal',
        component: PromoPage,
    },
    {
        name: 'USER_AREA',
        type: 'modal',
        component: UserArea,
        url: '/konto',
    },
    {
        name: 'DEPOSIT',
        type: 'modal',
        component: Deposit,
        url: '/konto/indbetaling',
    },
    {
        name: 'WITHDRAW',
        type: 'modal',
        component: withAnimation(withTheme(Withdraw)),
        url: '/konto/udbetaling',
    },
    {
        name: 'WITHDRAW_NOTIFICATION_POPUP',
        type: 'popup',
        component: WithdrawNotificationPopup,
    },
    {
        name: 'WITHDRAW_REMOVE_BONUS_POPUP',
        type: 'popup',
        component: WithdrawNotificationPopup,
    },
    {
        name: 'GAME_POPUP',
        type: 'popup',
        component: GamePopup,
    },
    {
        name: 'GAME_INFO',
        type: 'modal',
        component: withTheme(GameInfo),
    },
    {
        name: 'HISTORY',
        type: 'modal',
        component: withAnimation(withTheme(UserHistory)),
        url: '/konto/historik',
    },
    {
        name: 'DEPOSIT_LIMIT',
        type: 'modal',
        component: DepositLimit,
        url: '/konto/indbetalingsgraense',
    },
    {
        name: 'SELF_EXCLUSION',
        type: 'modal',
        component: withAnimation(withTheme(SelfExclusion)),
        url: '/konto/selvudelukkelse',
    },
    {
        name: 'SELF_EXCLUSION_TEXT',
        type: 'modal',
        component: withAnimation(withTheme(SelfExclusionTextModal)),
    },
    {
        name: 'SECURITY',
        type: 'modal',
        component: Security,
        url: '/konto/sikkerheds-indstillinger',
    },
    {
        name: 'UPDATE_PASSWORD',
        type: 'modal',
        component: UpdatePassword,
    },
    {
        name: 'USER_PROFILE',
        type: 'modal',
        component: UserProfile,
        url: '/konto/konto-oplysninger',
    },
    {
        name: 'USER_PROFILE_SUCCESS',
        type: 'popup',
        component: UserProfileSuccess,
    },
    {
        name: 'VOUCHER',
        type: 'modal',
        component: Voucher,
        url: '/konto/voucher',
    },
    {
        name: 'NOTIFICATION',
        type: 'modal',
        component: Notifications,
        url: '/konto/besked-indstillinger',
    },
    {
        name: 'FAST_DEPOSIT_LIMIT',
        type: 'modal',
        component: withTheme(FastDepositLimit),
    },
    {
        name: 'BONUS',
        type: 'modal',
        component: withAnimation(withTheme(UserBonus)),
        url: '/konto/bonusomsaetning',
    },
    {
        name: 'BONUS_POPUP',
        type: 'popup',
        component: withAnimation(withTheme(BonusPopup)),
    },
    {
        name: 'TEXT_PAGE',
        type: 'modal',
        component: TextPage,
    },
    {
        name: 'BRAND_BOOK',
        type: 'modal',
        component: BrandBookModal,
    },
    {
        name: 'LOADER_COVER',
        type: 'modal',
        component: withTheme(LoaderCover),
    },
    {
        name: 'PRODUCT_REVIEW',
        type: 'modal',
        component: withAnimation(withTheme(ProductReview)),
    },
    {
        name: 'SIDEBAR_POPUP',
        type: 'popup',
        component: withTheme(SidebarPopup),
    },
    {
        name: 'FEEDBACK',
        type: 'popup',
        component: withTheme(FeedbackModal),
    },
    {
        name: 'SMART_FILTERS',
        type: 'modal',
        component: SmartFilters,
    },
    {
        name: 'SORTER',
        type: 'modal',
        component: SortModal,
    },
    {
        name: 'GEO_DATA',
        type: 'popup',
        component: withTheme(GeoDataModal),
    },
    {
        name: 'SEARCH',
        type: 'popup',
        component: withTheme(SearchModal),
    },
    {
        name: 'SESSION_EXPIRED',
        type: 'popup',
        component: withTheme(SessionPopup),
    },
    {
        name: 'VERIFICATION_POPUP',
        type: 'popup',
        component: withTheme(VerificationPopup),
    },
    {
        name: 'INSTALL_PWA_MODAL',
        type: 'popup',
        component: withTheme(PWAPop),
    },
    {
        name: 'DEPOSIT_LIMIT_WARNING',
        type: 'popup',
        component: withTheme(DepositLimitPopup),
    },
    {
        name: 'CPR_CONFIRMATION',
        type: 'modal',
        component: CPRConfirmModal,
    },
    {
        name: 'EMAIL_EXIST',
        type: 'modal',
        component: EmailAlreadyExist,
    },
    {
        name: 'LOCK_ORIENTATION',
        type: 'modal',
        component: LockOrientationModal,
    },
    {
        name: 'REMOVE_BONUS_CONFIRM',
        type: 'popup',
        component: withTheme(RemoveBonusConfirmPopup),
    },
    {
        name: 'TRANSACTION_INFO',
        type: 'popup',
        component: withTheme(TransactionDetails),
    },
    {
        name: 'TRANSACTION_CHOOSE_DATE',
        type: 'popup',
        component: TransactionChooseDate,
    },
]

export default ModalsMap
