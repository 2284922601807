import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import Icon from 'ui/Icon'
import arrow from 'images/icons/basic-icons/arrow-thin.svg'
import { detectMobByScreen } from 'common/adapters/DeviceDetectAdapter'
import COLORS from 'themes/primary/palette'

export const Slide = styled.img`
    height: auto;
    width: 100%;
    padding: 0 0.5rem;
`

const backDropStyle = css`
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
`

const NextArrowWrapper = styled.div`
    ${backDropStyle};
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    width: 66px;
    height: 66px;
    border-radius: 8px;
    position: absolute;
    z-index: 2;
    border: 1px solid transparent;

    transition: background-color 0.3s ease;

    ${({ right }) =>
        right
            ? css`
                  right: 30px;
                  transform: translateY(-50%);
                  top: calc(50% - 5px);
              `
            : css`
                  left: 30px;
                  transform: translateY(-50%) rotateZ(180deg);
                  top: 50%;
              `};

    &:hover {
        border-color: #ffffff4d;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.05);
    }
`

const ControlButtons = styled.div`
    right: 0;
    top: 0;
    position: absolute;
    display: flex;
    gap: 8px;
`

const SliderStyled = styled(Slider)`
    cursor: pointer;

    .slick-list {
        height: auto;
    }

    .slick-track {
        margin: 0;
    }

    .slick-dots {
        width: auto;
        margin: 0;
        padding: 0;
        bottom: ${(props) =>
            !props.desktop ? (props.withBottomBar ? '110px' : '10px') : '8px'};
        top: auto;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        list-style: none;
        position: absolute;
    }

    .slick-dots li {
        margin: 0 4px;
    }

    .slick-dots li.slick-active {
    }

    .slick-active > div {
        opacity: 1 !important;
    }
`

const Dot = styled.div`
    padding: 5px 0;

    span {
        background: ${COLORS.primaryDisable};
        width: ${(props) => (props.desktop ? '40px' : '28px')};
        display: block;
        height: 4px;
        border-radius: 5px;
        margin: 0;
        transition: width 0.3s ease;

        .slick-active & {
            background: ${COLORS.primary};
        }
    }
`

const NextArrow = (props) => {
    const { onClick, right, key } = props
    return (
        <NextArrowWrapper
            key={key}
            onClick={onClick}
            right={right}
            data-tip={right ? 'Forrige' : 'Næste'}
            data-place={right ? 'left' : 'right'}
            data-type={'light'}
            data-effect={'solid'}
            data-for={'highlight-tooltip'}
            data-offset={right ? "{'left': 6}" : "{'right': 8}"}
        >
            <Icon
                color="white"
                src={arrow}
                size={'lg'}
                style={{ padding: '10px' }}
            />
        </NextArrowWrapper>
    )
}

const SliderCustom = ({
    children,
    onChange = (f) => f,
    onEdge = (f) => f,
    onReInit = (f) => f,
    arrows = false,
    duration = 300,
    animation = 'ease-out',
    draggable = true,
    infinite = true,
    loop = false,
    perPage = 1,
    dots = false,
    slidesToScroll = 1,
    autoChangeTime = 0,
    showControlButtons = true,
    renderPrevBtn = () => <></>,
    renderNextBtn = () => <></>,
    renderCustomBtn = () => <></>,
    renderControls = () => <></>,
    slickGoTo = () => {},
    customStyle = {},
    withBottomBar = false,
    adaptiveHeight = true,
}) => {
    const sliderRef = useRef(null)
    const [observer, setObserver] = useState()

    const settings = {
        dots: dots,
        adaptiveHeight: adaptiveHeight,
        arrows: arrows,
        slidesToShow: perPage,
        slidesToScroll: slidesToScroll,
        infinite: infinite,
        autoplay: loop,
        autoplaySpeed: autoChangeTime,
        swipeToSlide: true,
        customPaging: (i) => (
            <Dot>
                <span />
            </Dot>
        ),
        withBottomBar: withBottomBar,

        beforeChange: onChange,
        onEdge: onEdge,
        onReInit: onReInit,
        prevArrow: <NextArrow key={'prev'} />,
        nextArrow: <NextArrow key={'next'} right />,
    }

    useEffect(
        () => {
            sliderRef.current !== observer
                ? setObserver(sliderRef.current)
                : null

            /*
                Force rerender to not lose sliderRef
            */
        },
        [observer]
    )

    return (
        <>
            <SliderStyled
                {...settings}
                ref={sliderRef}
                desktop={!detectMobByScreen()}
                style={customStyle}
            >
                {children}
            </SliderStyled>

            {showControlButtons ? (
                <ControlButtons>
                    {renderCustomBtn()}
                    {renderPrevBtn(sliderRef?.current?.slickPrev)}
                    {renderNextBtn(sliderRef?.current?.slickNext)}
                </ControlButtons>
            ) : null}
        </>
    )
}

export default SliderCustom
