export const EXCLUSIVE_GAME_LABEL = {
    background: '#fae311',
    textColor: '#97261f',
    text: 'EKSKLUSIVT',
}

export const POPULAR_GAME_LABEL = {
    background: '#DB1C1B',
    textColor: '#fff',
    text: 'POPULÆR',
}

export const NEW_GAME_LABEL = {
    background: '#186BF5',
    textColor: '#ffffff',
    text: 'NYHED',
}

export const LIVE_GAME_LABEL = {
    background: '#DB1C1B',
    textColor: '#fff',
    text: 'LIVE',
}
