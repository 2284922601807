import React, { useEffect, useRef, useState } from 'react'
import Thumb from 'ui/Thumb'
import skeletonLoader from 'images/skeleton-loader.gif'
import skeletonVert from 'images/game-vertical-skeleton.jpg'
import styled from 'styled-components'
import { getImageSrc, getVerticalImageSrc } from 'services/ImageService'
import Label from 'ui/Label'
import { backDropStyle } from 'ui/StylesUtils'
import Icon from 'ui/Icon'
import infoIcon from 'icons/basic-icons/info.svg'
import ReactTooltip from 'react-tooltip'
import { modalService } from 'services/ModalService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import FavoriteButton from 'features/FavoriteButton'
import DiamondIcon from 'icons/basic-icons/jackpot-diamond.svg'
import Img from '../../ui/Img'

const t = window.T

//animated loading wrapper
//@TODO: keep until it will be needed
const ImageWrapper = styled.div`
    width: 100%;
    position: relative;
`

const LabelWrapper = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
`

const StyledBox = styled.div`
    ${backDropStyle};
    display: inline-block;
    color: white;
    padding: 0 5px;

    font-size: 0.7rem;
    border-radius: 5px;
`

const ServiceButtonWrapper = styled.div`
    top: 7px;
    right: 15px;
    bottom: 9px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 25px;

    svg {
        margin-bottom: 2px;
    }
`

const ThumbGameInfo = styled(StyledBox)`
    width: 24px;
    text-align: center;
    margin-left: 8px;
`

const Jackpot = styled(StyledBox)`
    position: absolute;
    border-radius: 4px;
    background: rgba(30, 30, 30, 0.4);
    backdrop-filter: blur(8px);
    padding: 0 8px;
    bottom: 8px;
    left: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
`

const TooltipProps = () =>
    !detectMobile()
        ? {
              'data-for': 'game-info',
              'data-type': 'light',
              'data-effect': 'solid',
              'data-class': 'tooltip-game-info',
              'data-place': 'bottom',
              'data-offset': '{"bottom": 5}',
              'data-border': true,
              onMouseOver: (e) => {
                  ReactTooltip.rebuild()
              },
              onMouseLeave: (e) => {
                  ReactTooltip.hide()
              },
          }
        : null

const GameCard = ({
    game,
    width,
    height,
    aspectRatio,
    vertical = false,
    labelProps = null,
    showInfoIcon = true,
    showFavoriteIcon = true,
    showJackpotAmount = true,
    gameClickHandler = (game) => {},
    children,
}) => {
    const [imgPath, setImgPath] = useState('')
    const [hoverState, setHoverState] = useState(false)
    const [isFavorite, setIsFavorite] = useState(false)
    const skeletonImage = vertical ? skeletonVert : skeletonLoader
    const childRef = useRef()

    const gameInfoModal = (e, game) => {
        e.preventDefault()
        e.stopPropagation()
        ReactTooltip.hide()
        modalService.showModal('GAME_INFO', {
            game,
            gameClickCallback: () => gameClickHandler(game),
            initialProps: {
                animation: true,
                outsideClose: true,
            },
        })
    }

    const showIconHandler = () => showInfoIcon

    useEffect(() => {
        let mounted = true
        detectMobile() && setHoverState(true)
        vertical
            ? getVerticalImageSrc(
                  game,
                  'md',
                  (imgPath) => mounted && setImgPath(imgPath)
              )
            : getImageSrc(game, (imgPath) => mounted && setImgPath(imgPath))

        return () => {
            ReactTooltip.hide()
            mounted = false // cleanup
        }
    }, [])

    const handleOnClickFavorite = (event) => {
        event.preventDefault()
        event.stopPropagation()
        childRef?.current?.toggleFavorite(event)
    }

    return (
        <ImageWrapper
            onMouseOver={() => !detectMobile() && setHoverState(true)}
            onMouseLeave={() => !detectMobile() && setHoverState(false)}
        >
            {game.game_id ? (
                <>
                    {labelProps && (
                        <LabelWrapper>
                            <Label {...labelProps} />
                        </LabelWrapper>
                    )}
                    {showJackpotAmount && game.jackpot_amount_formatted ? (
                        <>
                            <Jackpot>
                                <Img
                                    src={DiamondIcon}
                                    width={'16px'}
                                    height={'16px'}
                                    style={{ marginTop: '-2px' }}
                                />
                                {game.jackpot_amount_formatted} kr.
                            </Jackpot>
                        </>
                    ) : null}
                    {hoverState && (
                        <ServiceButtonWrapper>
                            {showFavoriteIcon && (
                                <ThumbGameInfo
                                    data-tip={
                                        isFavorite
                                            ? t.favorite.remove
                                            : t.favorite.add
                                    }
                                    onClick={handleOnClickFavorite}
                                    {...TooltipProps()}
                                >
                                    <FavoriteButton
                                        game={game}
                                        setFavorite={setIsFavorite}
                                        ref={childRef}
                                    />
                                </ThumbGameInfo>
                            )}
                            {showIconHandler() && (
                                <ThumbGameInfo
                                    onClick={(e) => gameInfoModal(e, game)}
                                    data-tip={'Vis spilinfo'}
                                    {...TooltipProps()}
                                >
                                    <Icon
                                        src={infoIcon}
                                        size={'sm'}
                                        color={'white'}
                                    />
                                </ThumbGameInfo>
                            )}
                        </ServiceButtonWrapper>
                    )}
                </>
            ) : null}
            {children ? children : null}
            <Thumb
                loading="lazy"
                width={width}
                height={height}
                aspectRatio={aspectRatio}
                src={imgPath ? imgPath : skeletonImage}
                alt={game?.name || ''} // todo only for test
                onError={() => setImgPath(skeletonImage)}
            />
        </ImageWrapper>
    )
}

export default GameCard
