import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Text from 'ui/Text'
import Thumb from 'ui/Thumb'
import Icon from 'ui/Icon'
import { renderLoader } from 'ui/RenderLoader'

import { getImageSrc } from 'services/ImageService'
import GamesService from 'services/GamesService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'

import skeletonLoader from 'images/skeleton-loader.gif'
import arrowIcon from 'images/icons/basic-icons/trailing-arrow.svg'
import Img from '../../ui/Img'

const t = window.T

const CardContainer = styled.div`
    font-size: 15px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 16px;
    background: ${({ cardBackground }) => cardBackground || 'white'};
    position: relative;
    @media (max-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        background-size: cover;
    }
`

const ActionSection = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 100%;
    border-radius: 0 0 16px 16px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    @media (max-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        padding: 10px;
    }
`

const ActionButton = styled.div`
    display: flex;
    height: 80px;
    box-sizing: content-box;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    background: #fff;
    cursor: pointer;
    transition: transform 200ms ease-in-out;

    &:hover {
        transform: scale(1.01);
    }

    @media (max-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        padding: 8px;
    }

    @media (max-width: 375px) {
        justify-content: flex-start;
    }
`

const ImageThumb = styled(Thumb)`
    margin-left: 0;
    max-width: 120px;
`

const ContentContainer = styled.div`
    position: relative;
    bottom: 8px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: 16px;

    @media (max-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
        margin-left: 8px;
    }
`

const AmountTitle = styled.div`
    display: flex;
    gap: 8px;
`

const AmountLabel = styled(Text)`
    font-family: 'ATG Rubrik', sans-serif;
`

const ArrowIcon = styled(Icon)`
    margin-left: auto;
    @media screen and (max-width: 375px) {
        display: none;
    }
`

const CardWithAction = ({
    selectedGame,
    maxJackpotAmount,
    isAmountNumber = false,
    title,
    subtitle,
    amountTitle,
    amountTitleColor,
    amountDisplayText,
    amountColor,
    cardBackground,
    arrowColor,
    additionalIcon,
}) => {
    const history = useHistory()
    const [imgPath, setImgPath] = useState(null)

    useEffect(
        () => {
            if (selectedGame) {
                getImageSrc(selectedGame, (imgPath) => setImgPath(imgPath))
            }
        },
        [selectedGame]
    )

    const showGame = (game) => {
        GamesService.removeMultipleGames()
        if (
            (localStorage.getItem('logged') === 'false' ||
                localStorage.getItem('logged') === null) &&
            detectMobile()
        ) {
            NotificationConductor.warning(t.notLoggedForPlay)
        }

        GamesService.setSearchDisplay(false)
        GamesService.checkLoginBeforeStart(game, history)

        if (
            localStorage.getItem('logged') !== null &&
            localStorage.getItem('logged') !== 'false'
        ) {
            GamesService.pushGameToRecentlyPlayed(game)
        }
    }

    return (
        <CardContainer cardBackground={cardBackground}>
            <Text color={'rgba(255,255,255,0.5)'} bold size={'sm'}>
                {subtitle}
            </Text>
            <Text color={'white'} bold size={'xl'}>
                {title}
            </Text>
            <ActionSection>
                <ActionButton
                    onClick={() => {
                        showGame(selectedGame)
                    }}
                >
                    <ImageThumb
                        loading={'lazy'}
                        src={imgPath ? imgPath : skeletonLoader}
                        onError={() => setImgPath(skeletonLoader)}
                    />
                    <ContentContainer>
                        <AmountTitle>
                            <Text
                                size={'sm'}
                                color={amountTitleColor || '#1E1E1E'}
                                bold
                            >
                                {amountTitle.toUpperCase()}
                            </Text>
                            {additionalIcon && (
                                <Img
                                    src={additionalIcon}
                                    width={'16px'}
                                    height={'16px'}
                                />
                            )}
                        </AmountTitle>
                        <AmountLabel color={amountColor} size={'xl'}>
                            {isAmountNumber && !maxJackpotAmount
                                ? renderLoader()
                                : amountDisplayText}
                        </AmountLabel>
                    </ContentContainer>
                    <ArrowIcon
                        src={arrowIcon}
                        width={'24px'}
                        height={'24px'}
                        color={arrowColor || 'black'}
                    />
                </ActionButton>
            </ActionSection>
        </CardContainer>
    )
}

CardWithAction.propTypes = {
    selectedGame: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    maxJackpotAmount: PropTypes.number,
    isAmountNumber: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    amountLabel: PropTypes.string,
    amountDisplayText: PropTypes.string.isRequired,
    amountColor: PropTypes.string.isRequired,
    cardBackground: PropTypes.string.isRequired,
}

export default CardWithAction
