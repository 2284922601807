import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import * as serviceWorker from './serviceWorker'

const { registerObserver } = require('react-perf-devtool')
import UserAdapter from 'common/adapters/UserAdapter'
import version from './../package.json'
import GamesUtilsService from 'services/GamesUtilsService'
import UserService from 'services/UserService'

registerObserver()
ReactDOM.render(<App />, document.getElementById('root'))

if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('https://api.bet25.dk/casino/sw.js', { scope: './' })
        .then((reg) => console.log('service worked registered', reg))
        .catch(() => console.log('error'))
}

window.login = (username, password) => {
    UserAdapter.login(username, password)
        .then((res) => {
            localStorage.setItem('logged', 'true')
            localStorage.setItem('registerDate', res.registerDate)
            UserService.attachUser()
            UserService.setIsUserLoggedIn()
            GamesUtilsService.fetchFavorite(res.id)
            console.log('Welcome!')
        })

        .catch((err) => {
            console.log(err.errorMessage)
        })
}
