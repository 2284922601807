import * as FetchAdapter from 'common/adapters/FetchAdapter'
import {
    API_PHP,
    API_URL,
    API_URL_OLD,
    AUTH_REST_PATH,
    MAIN_REST_BASE_API,
} from 'configs/rest'
import { APP_NAME } from 'configs/main'
import { getPlatformForApi } from 'common/adapters/DeviceDetectAdapter'
import { getEnv } from 'utils/env'

export const getActiveBonusListAdapter = () =>
    FetchAdapter.get(AUTH_REST_PATH + 'activeBonusList', '', {
        credentials: 'include',
    })

export const getBonus = () =>
    FetchAdapter.get(AUTH_REST_PATH + 'activeBonus', '', {
        credentials: 'include',
    })

export const forfeitBonus = () =>
    FetchAdapter.post(
        AUTH_REST_PATH + 'forfeitBonus',
        {
            headers: { 'content-type': 'application/json' },
        },
        {
            credentials: 'include',
        }
    )

export const getEvolutionGamesStateAdapter = () =>
    FetchAdapter.get(API_URL + 'get-live-tables')

export const getLiveCasinoGamesAdapter = () =>
    FetchAdapter.get(API_URL + 'categories/liveint/games', {
        platform: getPlatformForApi(),
        srv: getEnv(),
        app: APP_NAME,
        'page[size]': 500,
    })

export const getGamesFeaturesAdapter = () =>
    FetchAdapter.get(API_URL + 'games/features', {
        platform: getPlatformForApi(),
        srv: getEnv(),
        app: APP_NAME,
    })

export const getGamesThemesAdapter = () =>
    FetchAdapter.get(API_URL + 'games/themes', {
        platform: getPlatformForApi(),
        srv: getEnv(),
        app: APP_NAME,
    })

export const getJackpots = () =>
    FetchAdapter.get(API_URL + 'categories/jackpots/games', {
        platform: getPlatformForApi(),
        srv: getEnv(),
        app: APP_NAME,
        'page[size]': 500,
    })

export const getPlayngoJackpots = () => {
    return new Promise((resolve) => {
        getJSONP(
            'https://adkcw.playngonetwork.com/jackpots?pid=239&currency=DKK&callback=?',
            function(data) {
                resolve(data)
            }
        )
    })
}

export const searchGames = (searchString) =>
    FetchAdapter.get(API_URL + `games/smart-search`, {
        platform: getPlatformForApi(),
        srv: getEnv(),
        app: APP_NAME,
        searchString,
    })

// TODO move to Service
function getJSONP(url, success) {
    var ud = '_' + +new Date(),
        script = document.createElement('script'),
        head =
            document.getElementsByTagName('head')[0] || document.documentElement

    window[ud] = function(data) {
        head.removeChild(script)
        success && success(data)
    }

    script.src = url.replace('callback=?', 'callback=' + ud)
    head.appendChild(script)
}

export const getJackpotsInfo = () =>
    FetchAdapter.get(API_URL_OLD + 'content/jackpot/all')
