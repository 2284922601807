import userService from 'services/UserService'
import { formatDateWithoutSeparator as formatToApi } from 'utils/dateTime'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserAdapter from 'common/adapters/UserAdapter'
import { makeRequest } from 'utils/REST'
import {
    DIBS_CLIENT_NAME,
    DIBS_ERROR_URL,
    DIBS_EXCHANGE_DK,
    DIBS_PMNT,
} from 'configs/dibs'
import { AUTH_REST_PATH } from 'configs/rest'

class ApplePayService {
    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                return data
            })
    }

    post(method, data) {
        return this.request('post', method, data)
    }

    get(method, data) {
        return this.request('get', method, data)
    }

    ApplePayMerchantVerification() {
        return this.get(
            AUTH_REST_PATH +
                'payment/dibs?op=applepayinit&merchant=824502&name=Roed25'
        )
    }

    CompleteApplePayPayment(inputAmount, payData, bonusEntity) {
        let bonus = bonusEntity?.externalId
            ? `&bonusId=${bonusEntity.externalId}`
            : ''
        return this.get(
            AUTH_REST_PATH +
                `payment/dibs?op=start&client=${DIBS_CLIENT_NAME}&exchcode=${DIBS_EXCHANGE_DK}&errorUrl=${DIBS_ERROR_URL}&amount=${inputAmount *
                    100}&input_amount=${inputAmount}&agree=on&payType=applePay&payData=${payData}&pmnt=${DIBS_PMNT}${bonus}`
        )
    }
}

export default new ApplePayService()

export const depositAction = (status, amount, reason, payType) => {
    const t = window.T
    switch (status) {
        case 'success':
            userService.setIsUserLoggedIn((userData) => {
                UserAdapter.getTransactionList(
                    '01011970',
                    formatToApi(new Date())
                ).then((res) => {
                    if (res.data) {
                        const dataLayerTracking = {
                            event: 'red25.deposit',
                            depositAmount: parseFloat(amount),
                            userId: userData.userId,
                            depositCount: res.data.length ? res.data.length : 0,
                            accountBalance: userData.balance,
                            paymentType: payType,
                        }
                        window.dataLayer.push(dataLayerTracking)
                        console.log('dataLayerTracking', dataLayerTracking)

                        var globalThis = {}
                        globalThis._actGoal = {
                            settings: {
                                value:
                                    '/g/encrypted/goal/txSgBAH7u5l5vCnRgwHyzamqcnK8EW283xKQgrAygWE',
                                type: 'WEBSITE_VISIT',
                                goalUrl: 'https://www3.actito.com:443/actg',
                            },
                        }
                        ;(function() {
                            var _head =
                                document.head ||
                                document.getElementsByTagName('head')[0]
                            var sp = document.createElement('script')
                            sp.type = 'text/javascript'
                            sp.async = true
                            sp.defer = true
                            sp.src =
                                ('https:' == document.location.protocol
                                    ? 'https://cdn3.actito.com'
                                    : 'http://cdn3.actito.com') +
                                '/legacy/actito-goal/goal.js'
                            _head.appendChild(sp)
                        })()
                    }
                })
            })
            NotificationConductor.success(
                `Din indbetaling på ${amount} kr. er gennemført`
            )
            break
        case 'error':
            const errors = t.deposit.fundErrors
            NotificationConductor.error(errors[reason])
            break
    }
}
