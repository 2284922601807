import React from 'react'
import PropTypes from 'prop-types'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import NewJackpotCard from './NewJackpotCard'
import CardWithAction from 'features/CardHighlightSection/CardWithAction'
import CardHighlight from 'features/CardHighlightSection/CardHighlight'
import { thousandsDots } from 'utils/formatters'
import jackpotHighlight from 'images/homepage/jackpot-highlight.png'
import useJackpotsData from './useJackpotsData'

const cardProps = (maxJackpotAmount) => ({
    title: 'Jackpotten kan gå hvert øjeblik!',
    subtitle: 'JACKPOTS',
    amountTitle: 'STØRSTE JACKPOT',
    amountTitleColor: 'rgba(24, 107, 245, 0.50)',
    arrowColor: 'rgba(24, 107, 245, 0.50)',
    isAmountNumber: true,
    amountDisplayText: `${thousandsDots(maxJackpotAmount)} kr.`,
    amountColor: '#186BF5',
    cardBackground: `url(${jackpotHighlight}) center / ${
        detectMobile() ? '170%' : '145%'
    } no-repeat,
    linear-gradient(180deg, rgba(7, 73, 178, 0.00) 50%, #0749B2 100%),
    #AFCCFA`,
})

const JackpotHighlight = ({ games, getAdditionalGames, buttonLink, title }) => {
    const { jackpotsData, selectedGame } = useJackpotsData(games)

    return (
        <CardHighlight games={games} buttonLink={buttonLink} title={title}>
            <CardWithAction
                selectedGame={selectedGame}
                maxJackpotAmount={jackpotsData?.levels['ultra'].amount}
                {...cardProps(jackpotsData?.levels['ultra'].amount)}
            />
            {Object.keys(jackpotsData)?.length && (
                <NewJackpotCard jackpotsData={jackpotsData} />
            )}
        </CardHighlight>
    )
}

JackpotHighlight.propTypes = {
    games: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            jackpotAmount: PropTypes.number,
        })
    ).isRequired, // Array of game objects
    getAdditionalGames: PropTypes.func, // Function to fetch additional games
    buttonLink: PropTypes.string.isRequired, // URL string for the button link
    title: PropTypes.string.isRequired, // Title for the component
}

export default JackpotHighlight
