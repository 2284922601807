import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { byMedia } from 'common/adapters/DeviceDetectAdapter'
import { renderLoader } from 'ui/RenderLoader'
import Box from 'ui/Box'
import CategoryTitle from './CategoryTitle'
import CustomSlider from './CustomSlider'

const onPageDefault = byMedia({
    sm: 1.5,
    md: 3.5,
    lg: 4.5,
    xl: 5,
})

const StyledWrapper = styled(Box)`
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const GamesSlider = ({
    gamesData = [],
    gamesCount = 0,
    gamesToShow = 10,
    squareThumb = false,
    infinite = false,
    slidesToScroll = 1,
    title,
    labelTitle = '',
    buttonLink = '',
    labelProps = null,
    vertical = false,
    showNumberLabel = false,
    onPage = onPageDefault,
}) => {
    const [slides, setSlides] = useState([])
    const [hideControl, setHideControl] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(
        () => {
            if (gamesData) {
                setLoading(false)
                setSlides(gamesData.slice(0, gamesToShow))
                setHideControl(
                    gamesData.filter((item) => !!item.game_id).length <= onPage
                )
            }
        },
        [gamesData]
    )

    const showMoreBtn = () => Boolean(gamesCount > gamesToShow && buttonLink)

    return (
        <div style={{ position: 'relative' }}>
            {loading ? (
                renderLoader('100px')
            ) : (
                <>
                    <StyledWrapper>
                        <CategoryTitle
                            title={title}
                            labelTitle={labelTitle}
                            isShowMore={showMoreBtn()}
                            titleLink={buttonLink}
                        />
                    </StyledWrapper>
                    <CustomSlider
                        slides={slides}
                        gamesCount={gamesCount}
                        showMoreBtn={showMoreBtn()}
                        showMoreBtnValue={'+ ' + (gamesCount - gamesToShow)}
                        showMoreBtnLink={buttonLink}
                        squareThumb={squareThumb}
                        infinite={infinite}
                        slidesToScroll={slidesToScroll}
                        hideControl={hideControl}
                        onPage={onPage}
                        labelProps={labelProps}
                        vertical={vertical}
                        showNumberLabel={showNumberLabel}
                    />
                </>
            )}
        </div>
    )
}

export default GamesSlider
