import React, { useEffect, useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import Text from 'ui/Text'
import styled, { withTheme } from 'styled-components'
import NotificationConductor from 'common/conductors/NotificationConductor'
import withValidation from '@/common/Hocs/WithValidation'
import UserAdapter from 'common/adapters/UserAdapter'
import Box from 'ui/Box'
import { withAnimation } from 'features/UserArea/Components/withAnimation'
import { pipe } from 'rxjs'
import userService from 'services/UserService'
import { Profile as ProfileForm } from '@it25syv/25syv-ui'
import { SUPPORT_EMAIL } from 'configs/main'
import { modalService } from '../../../../services/ModalService'

const t = window.T

const BackgroundHolder = styled(Box)`
    height: 100%;
    background: ${(props) => props.theme.background};
`

const UserProfile = ({
    setValidationHandler,
    validateAllFields,
    theme,
    data: generalInfo = {},
}) => {
    const punterSalt = process.env.REACT_APP_PUNTER_SALT
    const [updateButtonDisable, setUpdateButtonDisable] = useState(true)

    const [email, setEmail] = useState(generalInfo.email || '')
    const [address, setAddress] = useState(generalInfo.address || '')
    const [zip, setZip] = useState(generalInfo.zip || '')
    const [phoneNr, setMobile] = useState(generalInfo.phoneNr || '')
    const [city, setCity] = useState(generalInfo.city || '')

    const submitHandler = (e) => {
        e.preventDefault()

        if (validateAllFields()) {
            UserAdapter.updateProfile({
                email,
                address,
                zip,
                phoneNr,
                city,
            }).then((res) => {
                userService.setIsUserLoggedIn()
                res.success
                    ? NotificationConductor.success(
                          'Dine kontooplysninger er blevet ændret'
                      )
                    : NotificationConductor.error(res.errorMessage)
            })
        } else {
            NotificationConductor.error(t.filledWrong)
        }
    }

    useEffect(() => {
        UserAdapter.isUserLoggedIn().then((res) => {
            setEmail(res.email)
        })

        UserAdapter.getProfile()
            .then((res) => {
                setAddress(res.address)
                setZip(res.zip)
                setMobile(res.phoneNr)
                setCity(res.city)
            })
            .catch((error) => {
                NotificationConductor.error(error.toString())
            })
    }, [])

    const keyPressHandler = (e) => {
        const key = e.charCode || e.keyCode || 0

        if (key === 'Enter' && !updateButtonDisable) {
            e.preventDefault()
            submitHandler(e)
        }
    }

    const showError = (error) => NotificationConductor.error(error.toString())

    return (
        <BackgroundHolder>
            <ModalTopBar background={theme.primary} back color={theme.light}>
                <Text size={'md'} color={theme.light} my={2}>
                    Kontooplysninger
                </Text>
            </ModalTopBar>

            <Wrapper padding={'0px'}>
                <ProfileForm
                    supportEmail={SUPPORT_EMAIL}
                    showSuccess={() =>
                        modalService.showModal('USER_PROFILE_SUCCESS')
                    }
                    showError={showError}
                    punterSalt={punterSalt}
                />
            </Wrapper>
        </BackgroundHolder>
    )
}

export default pipe(
    withValidation,
    withTheme,
    withAnimation
)(UserProfile)
