import { useEffect, useState } from 'react'
import Jackpots from 'common/DataObjects/Jackpots'
import GameJackpotService from 'services/GameJackpotService'
import { LEVELS_NAME } from 'configs/jackpot.config'

const NEW_JACKPOTS_LEVEL = {
    [LEVELS_NAME.ultra]: {
        title: 'Ultra Jackpot',
        background: 'linear-gradient(0deg, #4BAFFE 0%, #05EDFE 100%)',
        iconsNumber: 4,
    },
    [LEVELS_NAME.monthly]: {
        title: 'Super Drop',
        background: 'linear-gradient(0deg, #F47735 0%, #FCC230 100%)',
        iconsNumber: 3,
    },
    [LEVELS_NAME.weekly]: {
        title: 'Ugentlig Drop',
        background: 'linear-gradient(0deg, #1D976C 0%, #93F989 100%)',
        iconsNumber: 2,
    },
    [LEVELS_NAME.daily]: {
        title: 'Daglig Drop',
        background: 'linear-gradient(0deg, #402AB3 0%, #9615CB 100%)',
        iconsNumber: 1,
    },
}

const useJackpotsData = (games) => {
    const [jackpotsData, setJackpotsData] = useState(new Jackpots(null, null))
    const [selectedGame, setSelectedGame] = useState(null)

    useEffect(() => {
        const jackpotInfoSub = GameJackpotService.getJackpotsInfo().subscribe(
            (res) => {
                const jackpotInfo = res

                setJackpotsData({
                    ...jackpotInfo,
                    levels: {
                        ...jackpotInfo.levels,
                        ...updateCardStyles(jackpotInfo.levels), // todo rework
                    },
                })
            }
        )

        return () => {
            jackpotInfoSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            if (games?.length) {
                setJackpotsData(extendsJackpotsData(jackpotsData, games))
            }
        },
        [games]
    )

    const extendsJackpotsData = (data, games) => {
        const evoGames = prepareEvoJackpots(games)
        setSelectedGame(evoGames[0])
        const leftGamesCount = games.length - evoGames.length

        return {
            ...data,
            levels: {
                ...data.levels,
                [LEVELS_NAME.ultra]: {
                    ...data.levels[LEVELS_NAME.ultra],
                    amount: evoGames[0].jackpot_amount,
                    gamesCount: evoGames.length,
                },
                [LEVELS_NAME.monthly]: {
                    ...data.levels[LEVELS_NAME.monthly],
                    gamesCount: leftGamesCount,
                },
                [LEVELS_NAME.weekly]: {
                    ...data.levels[LEVELS_NAME.weekly],
                    gamesCount: leftGamesCount,
                },
                [LEVELS_NAME.daily]: {
                    ...data.levels[LEVELS_NAME.daily],
                    gamesCount: leftGamesCount,
                },
            },
        }
    }

    const updateCardStyles = (levels) => {
        return Object.keys(NEW_JACKPOTS_LEVEL).reduce(
            (acc, key) => ({
                ...acc,
                [key]: {
                    ...levels[key], // Preserve fields from the original `levels`
                    ...NEW_JACKPOTS_LEVEL[key], // Overwrite or add new fields from NEW_JACKPOTS_LEVEL
                },
            }),
            {}
        )
    }

    const prepareEvoJackpots = (games) => {
        const sortedGames = games
            .filter((el) => el.subprovider !== 'isoftbet')
            .sort((a, b) => Number(b.jackpot_amount) - Number(a.jackpot_amount))
        return sortedGames
    }

    return {
        jackpotsData,
        selectedGame,
    }
}

export default useJackpotsData
