import { BehaviorSubject, Subject } from 'rxjs'
import NotificationConductor from 'common/conductors/NotificationConductor'
import {
    getActiveBonusListAdapter,
    getBonus,
} from 'common/adapters/ProviderAdapter'

const activeBonusList = new BehaviorSubject([])
const activeBonus = new Subject()

export const setBonusList = () => {
    getBonus()
        .then(({ data }) => {
            try {
                if (data) {
                    activeBonus.next({
                        totalBonus: data.amount,
                        rollover: data.rollOver,
                        initialRollover: data.initRollOver,
                        expirationDate: data.expire,
                        gameId: data.gameId,
                    })
                } else {
                    activeBonus.next({})
                }
            } catch (e) {
                console.error(e)
            }
        })
        .catch((error) => NotificationConductor.error(error.toString()))
}

export const getBonusList = () => activeBonus.asObservable()

const setActiveBonusList = () => {
    getActiveBonusListAdapter()
        .then(({ data }) => {
            activeBonusList.next(data)
        })
        .catch((error) => {
            NotificationConductor.error(error.toString())
            activeBonusList.next([])
        })
}

const getActiveBonusList = () => activeBonusList.asObservable()

const removeActiveBonus = () => {
    setActiveBonusList()
    activeBonus.next({})
}

export default {
    setActiveBonusList,
    getActiveBonusList,
    removeActiveBonus,
}
