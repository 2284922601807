import React from 'react'
import styled from 'styled-components'
import { JackpotCard } from './NewJackpotCard'
import { byMedia } from 'common/adapters/DeviceDetectAdapter'
import useJackpotsData from './useJackpotsData'
import SliderControls from 'features/GamesSlider/SliderControls'
import CategoryTitle from 'features/GamesSlider/CategoryTitle'

const JackpotSlider = styled.div`
    position: relative;
    display: none;
    margin-top: 60px;

    @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.lg + 'px'}) {
        display: block;
    }
`
const SliderWrapper = styled.div`
    margin-top: 20px;
`

const JackpotCardsSlider = ({ games }) => {
    const { jackpotsData } = useJackpotsData(games)

    return (
        <JackpotSlider>
            <CategoryTitle title={'Jackpot puljer'} />
            <SliderWrapper style={{ marginTop: '20px' }}>
                <SliderControls
                    slidesCount={Object.keys(jackpotsData.levels).length}
                    onPage={byMedia({
                        sm: 1.5,
                        md: 2.2,
                        lg: 3.2,
                    })}
                >
                    {Object.values(jackpotsData.levels).map((item, key) => (
                        <JackpotCard item={item} key={key} />
                    ))}
                </SliderControls>
            </SliderWrapper>
        </JackpotSlider>
    )
}

export default JackpotCardsSlider
