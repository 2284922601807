import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Icon from 'ui/Icon'
import fullscreen from 'icons/basic-icons/fullscreen.svg'
import fullscreenExit from 'icons/basic-icons/fullscreen-exit.svg'
import closeLight from 'icons/basic-icons/close-light.svg'
import addGameIcon from 'icons/basic-icons/add_game.svg'
import minimizeIcon from 'icons/basic-icons/minimize.svg'
import UrlHistoryService from 'services/UrlHistoryService'
import userService from 'services/UserService'
import GamesService from 'services/GamesService'
import { useHistory } from 'react-router-dom'
import Text from 'ui/Text'
import theme from 'themes/primary/palette'
import { MAX_MULTI_GAMES_COUNT } from 'configs/main'
import ReactTooltip from 'react-tooltip'

const t = window.T.gameControls

const ControlsWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`
const ControlFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 50px;
    padding: 8px;
    transition: all 0.3s ease;
    border-radius: 0.25rem;

    &:hover {
        ${({ hoverColor }) => hoverColor && `background-color: ${hoverColor}`};
        cursor: pointer;
    }
`

function requestFullscreen(element) {
    if (element.requestFullscreen) {
        element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
    } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
    }
}

function exitFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
    }
}

const GameControls = ({ controls, hoverColor }) => {
    const history = useHistory()
    const [fullScreen, setFullScreen] = useState(false)
    const [addGameLimit, setAddGameLimit] = useState(false)

    const onShowFullScreen = () => {
        setFullScreen(true)
        GamesService.setFullScreen(true)
        requestFullscreen(document.documentElement)
    }

    const onHideFullScreen = () => {
        setFullScreen(false)
        GamesService.setFullScreen(false)
        exitFullscreen()
    }

    const iframeMassageHandler = ({ data }) => {
        switch (data.type) {
            case 'LOGOUT':
                if (data.data._reason === 'ServerMessage') {
                    window.location.reload()
                } else {
                    closeGame()
                }
                break
            default:
                break
        }
    }

    const exitHandler = () => {
        if (
            !document.fullscreenElement &&
            !document.webkitIsFullScreen &&
            !document.mozFullScreen &&
            !document.msFullscreenElement
        ) {
            setFullScreen(false)
            GamesService.setFullScreen(false)
        }
    }

    const closeGame = () => {
        history.push(UrlHistoryService.getUrl())
        userService.setIsUserLoggedIn()
    }

    const addGameToMultipleGame = (gameObj = {}) => {
        GamesService.setMultipleGames(gameObj)
    }

    const buttonsData = {
        [FULLSCREEN]: {
            icon: fullscreen,
            activeIcon: fullscreenExit,
            tooltip: t.tooltips.fullscreen,
            state: fullScreen,
            action: fullScreen ? onHideFullScreen : onShowFullScreen,
        },
        [ADD_GAME]: {
            icon: addGameIcon,
            disable: addGameLimit,
            tooltip: t.tooltips.addGame,
            action: addGameToMultipleGame,
        },
        [MINIMIZE]: {
            icon: minimizeIcon,
            action: () => alert('Minimize'),
        },
        [CLOSE]: {
            icon: closeLight,
            action: closeGame,
            text: t.text.close,
        },
    }

    useEffect(() => {
        document.addEventListener('fullscreenchange', exitHandler)
        document.addEventListener('webkitfullscreenchange', exitHandler)
        document.addEventListener('mozfullscreenchange', exitHandler)
        document.addEventListener('MSFullscreenChange', exitHandler)

        window.addEventListener('message', iframeMassageHandler)

        return () => {
            window.removeEventListener('message', iframeMassageHandler)
        }
    }, [])

    useEffect(() => {
        const multiGamesSub = GamesService.getMultipleGames().subscribe(
            (games) => {
                setAddGameLimit(games.length >= MAX_MULTI_GAMES_COUNT)
            }
        )

        return () => {
            multiGamesSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            return () => {
                fullScreen && onHideFullScreen()
            }
        },
        [fullScreen]
    )

    return (
        <ControlsWrapper>
            {controls &&
                controls.length &&
                controls.map((control, key) => (
                    <ControlFrame
                        key={key}
                        onClick={() =>
                            !buttonsData[control].disable &&
                            buttonsData[control].action()
                        }
                        hoverColor={hoverColor}
                        {...buttonsData[control].tooltip && {
                            'data-tip': buttonsData[control].tooltip,
                            'data-place': 'bottom',
                            'data-type': 'light',
                            'data-effect': 'solid',
                        }}
                    >
                        <Icon
                            color={'white'}
                            size={'md'}
                            src={
                                buttonsData[control].state
                                    ? buttonsData[control].activeIcon
                                    : buttonsData[control].icon
                            }
                        />
                        {buttonsData[control].text && (
                            <Text size={'sm'} color={theme.light} px={1} bold>
                                {buttonsData[control].text}
                            </Text>
                        )}
                    </ControlFrame>
                ))}
            <ReactTooltip />
        </ControlsWrapper>
    )
}

export default GameControls
export const FULLSCREEN = 'fullScreen',
    ADD_GAME = 'addGame',
    MINIMIZE = 'minimize',
    CLOSE = 'close'
