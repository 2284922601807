import Slider from 'features/Carousel/Slider'
import styled, { css } from 'styled-components'
import React, { useEffect, useState } from 'react'
import GamesService from 'services/GamesService'
import { useHistory } from 'react-router-dom'
import Icon from 'ui/Icon'
import arrowRight from 'images/icons/basic-icons/arrowRightThin.svg'
import rankingBg from 'images/icons/basic-icons/ranking-bg.svg'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import SliderControl from 'ui/controls/SliderControl'
import GameCard from 'features/GamesList/GameCard'
import { modalService } from 'services/ModalService'
import { POPULAR_GAME_LABEL } from '../../configs/ui'
import COLORS from '../../themes/primary/palette'

const SliderControls = ({
    children,
    slidesCount = 0,
    showMoreBtn = false,
    showMoreBtnValue = '',
    showMoreBtnLink = '',
    infinite = false,
    slidesToScroll = 1,
    hideControl = false,
    onPage = 5,
}) => {
    const [nextBtn, setNextBtn] = useState(true)
    const [prevBtn, setPrevBtn] = useState(false)
    const history = useHistory()

    const beforeSlideChange = (oldIndex, newIndex) => {
        setNextBtn(newIndex + onPage < slidesCount)
        setPrevBtn(!!newIndex)
    }

    return (
        <Slider
            showControlButtons={!detectMobile()}
            renderCustomBtn={() =>
                showMoreBtn && (
                    <SliderControl
                        onClick={() => history.push(showMoreBtnLink)}
                        style={{
                            margin: '0 12px 0 0',
                            padding: '0 24px',
                        }}
                    >
                        {showMoreBtnValue}
                    </SliderControl>
                )
            }
            renderNextBtn={(next) => (
                <SliderControl
                    right
                    onClick={next}
                    disabled={!infinite && !nextBtn}
                    hide={hideControl}
                >
                    <Icon
                        src={arrowRight}
                        size={'lg'}
                        scale={0.9}
                        color={'black'}
                    />
                </SliderControl>
            )}
            renderPrevBtn={(prev) => (
                <SliderControl
                    left
                    onClick={prev}
                    disabled={!infinite && !prevBtn}
                    hide={hideControl}
                >
                    <Icon
                        src={arrowRight}
                        size={'lg'}
                        scale={0.9}
                        color={'black'}
                        style={{ transform: 'rotate(180deg)' }}
                    />
                </SliderControl>
            )}
            perPage={onPage}
            slidesToScroll={slidesToScroll}
            onChange={beforeSlideChange}
            infinite={infinite}
            adaptiveHeight={false}
        >
            {children}
        </Slider>
    )
}

export default SliderControls
