import styled, { css } from 'styled-components'
import React, { useEffect, useState } from 'react'
import GamesService from 'services/GamesService'
import { useHistory } from 'react-router-dom'
import { byMedia } from 'common/adapters/DeviceDetectAdapter'
import COLORS from '../../themes/primary/palette'
import SliderControls from './SliderControls'
import { renderLoader } from '../../ui/RenderLoader'
import CategoryTitle from './CategoryTitle'
import Box from '../../ui/Box'

const StyledWrapper = styled(Box)`
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const SlideWrapper = styled.div`
    display: flow;
    justify-content: center;
    padding: 0 8px;
    transition: all 0.3s ease;
    width: 100%;
    height: 108px;

    ${({ disable }) =>
        !disable &&
        css`
            &:hover {
                transform: scale(1.05);
                cursor: pointer;
            }
        `};
`

const ProviderBox = styled.div`
    border-radius: 8px;
    background: ${COLORS.white};
    width: 100%;
    height: 100%;
    position: relative;
`

const ProviderLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
`

const ProviderThumb = styled.img`
    max-width: 70%;
    max-height: 130px;
`

const ProviderTitle = styled.p`
    width: 100%;
    text-align: center;
    color: ${COLORS.gray};
    font-size: 13px;
    position: absolute;
    bottom: 5px;
    margin: 0;
`

const onPage = byMedia({
    sm: 2.8,
    md: 5,
    lg: 6,
    xl: 6,
})

const ProviderSlider = ({
    showMoreBtn = false,
    showMoreBtnValue = '',
    showMoreBtnLink = '',
    infinite = false,
    slidesToScroll = 1,
    hideControl = false,
}) => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [providersForShowing, setProvidersForShowing] = useState([])

    useEffect(() => {
        const providersStatusSub = GamesService.getProvidersGames().subscribe(
            (res) => {
                if (res) {
                    setProvidersForShowing(
                        res.filter((provider) => provider?.games?.count !== 0)
                    )
                    setLoading(false)
                }
            }
        )

        return () => {
            providersStatusSub.unsubscribe()
        }
    }, [])

    const onProviderClick = (provider) => {
        history.push(`/automater/spiludviklere/${provider.name}`)
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading ? (
                renderLoader('100px')
            ) : (
                <>
                    <StyledWrapper>
                        <CategoryTitle
                            title={'Spiludviklere'}
                            titleLink={'/automater/spiludviklere'}
                            isShowMore
                        />
                    </StyledWrapper>
                    {providersForShowing.length ? (
                        <SliderControls
                            slidesCount={providersForShowing.length}
                            showMoreBtn={showMoreBtn}
                            showMoreBtnValue={showMoreBtnValue}
                            showMoreBtnLink={showMoreBtnLink}
                            infinite={infinite}
                            slidesToScroll={slidesToScroll}
                            hideControl={hideControl}
                            onPage={onPage}
                        >
                            {providersForShowing.map((slide, index) => (
                                <SlideWrapper
                                    key={index}
                                    onClick={() => onProviderClick(slide)}
                                >
                                    <ProviderBox>
                                        <ProviderLogo>
                                            <ProviderThumb
                                                src={slide.logoColored}
                                                alt={slide.title}
                                            />
                                        </ProviderLogo>
                                        <ProviderTitle>
                                            {`${slide?.games?.count} spil`}
                                        </ProviderTitle>
                                    </ProviderBox>
                                </SlideWrapper>
                            ))}
                        </SliderControls>
                    ) : null}
                </>
            )}
        </div>
    )
}

export default ProviderSlider
