import React from 'react'
import styled from 'styled-components'
import Text from 'ui/Text'
import Icon from 'ui/Icon'
import Flex from 'ui/Flex'
import { modalService } from 'services/ModalService'
import { getTopBarHeight } from 'configs/layout'
import { byPlatform, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import searchImage from 'images/homepage/search-section.png'
import search from 'images/icons/basic-icons/search.svg'

const StyledFlex = styled(Flex)`
    ${() => detectMobile() && `padding-right: 16px;`};
`

const SectionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${searchImage}) 50% / cover no-repeat;
    width: 100%;
    height: 100%;
    padding: 100px 20px;
    border-radius: 16px;
`
const InputBox = styled.div`
    position: relative;
    display: flex;
    width: 335px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #dfe5ed;
    background: #fff;
    cursor: pointer;
`

const SearchIcon = styled(Icon)`
    position: absolute;
    right: 20px;
    bottom: 50%;
    z-index: 1;
    transform: translateY(50%);
`

const SearchSection = () => {
    const searchHandler = () => {
        modalService.showModal('SEARCH', {
            initialProps: {
                modalTopMargin: byPlatform(getTopBarHeight(), null),
            },
            props: {
                initSearchText: '',
                initNotEmpty: false,
            },
        })
    }
    return (
        <StyledFlex>
            <SectionWrapper>
                <InputBox onClick={searchHandler}>
                    <Text
                        size={'sm'}
                        color={'#4F555E'}
                        align={'center'}
                        width={'100%'}
                    >
                        Noget bestemt du søger?
                    </Text>
                    <SearchIcon size={'sm'} src={search} />
                </InputBox>
            </SectionWrapper>
        </StyledFlex>
    )
}

export default SearchSection
