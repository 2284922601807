import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import CategoryTitle from 'features/GamesSlider/CategoryTitle'
import SliderControl from 'ui/controls/SliderControl'

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 20px;
`

const CardGridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    height: 400px;
    margin-bottom: 20px;

    @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.lg + 'px'}) {
        grid-template-columns: 1fr;
        padding-right: 1rem;
    }
`

const CardHighlight = ({ children, games, title, buttonLink }) => {
    const history = useHistory()

    return (
        <>
            {!detectMobile() && (
                <TitleWrapper>
                    <CategoryTitle
                        title={title}
                        titleLink={buttonLink}
                        hoverEffect={!!games?.length}
                        isShowMore={!!games?.length}
                    />
                    {/*{!!games?.length && (*/}
                    {/*    <SliderControl*/}
                    {/*        onClick={() => history.push(buttonLink)}*/}
                    {/*        style={{ padding: '0 24px' }}*/}
                    {/*    >*/}
                    {/*        {`+ ${games.length}`}*/}
                    {/*    </SliderControl>*/}
                    {/*)}*/}
                </TitleWrapper>
            )}
            <CardGridWrapper>{children}</CardGridWrapper>
        </>
    )
}

export default CardHighlight
