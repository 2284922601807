import React, { useEffect, useState, Fragment } from 'react'
import PageContent from 'ui/PageContent'
import PageWrapper from 'ui/PageWrapper'
import gamesService from 'services/GamesService'
import GamePopup from 'features/GamePopup'
import { withTheme } from 'styled-components'
import GamesService from 'services/GamesService'
import { useHistory, useLocation } from 'react-router-dom'
import Box from 'ui/Box'
import GamesSlider from 'features/GamesSlider'
import GamesGridTitle from 'ui/GamesGridTitle'
import lighting from 'icons/basic-icons/lightning-icon.svg'
import ghost from 'icons/basic-icons/ghost.svg'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import SmartFilterService from 'services/SmartFilterService'

const GamePage = ({ match }) => {
    const [selectedGame, setSelectedGame] = useState({})
    const [checkLogin, setCheckLogin] = useState(true)
    const [sameFeaturesGames, setSameFeaturesGames] = useState(null)
    const [sameThemeGames, setSameThemeGames] = useState(null)
    const [isMultiscreenMode, setIsMultiscreenMode] = useState(false)
    const location = useLocation()
    const history = useHistory()

    const gameId = match.params.game_id

    useEffect(
        () => {
            const gamesSub = GamesService.getAllProvidersGames().subscribe(
                (games) => {
                    if (games.length) {
                        let game = games.find((g) => {
                            if (g.related_id === gameId) {
                                // window.history.pushState(
                                //     '',
                                //     '',
                                //     '/game/' + g.game_id
                                // )
                                history.push('/game/' + g.game_id)
                            }

                            return (
                                g.game_id === gameId || g.related_id === gameId
                            )
                        })

                        gamesService.setGameLaunchState({
                            isLaunched: false,
                            isInit: true,
                        })
                        gamesService.removeMultipleGames()

                        setSelectedGame(game)
                    }
                }
            )

            return () => {
                gamesSub.unsubscribe()
                gamesService.setGameLaunchState({
                    isLaunched: false,
                    isInit: false,
                })
                gamesService
                    .getEndGame()
                    .catch((e) => console.error('Failed fetch endGame', e)) // todo fix json error
            }
        },
        [match.params.game_id]
    )

    useEffect(
        () => {
            if (location.state) {
                setCheckLogin(location.state.checkLogin)
            }
        },
        [location]
    )

    useEffect(
        () => {
            if (
                (!detectMobile() || detectIpad()) &&
                selectedGame &&
                Object.keys(selectedGame).length > 0 &&
                !isMultiscreenMode
            ) {
                GamesService.getRelatedGames(
                    selectedGame,
                    (featureGames, themeGames) => {
                        setSameFeaturesGames(featureGames)
                        setSameThemeGames(themeGames)
                    }
                )

                const SmartFilterSub = SmartFilterService.getTempFilters().subscribe(
                    (filters) => {
                        SmartFilterService.setFilters(filters)
                    }
                )

                return () => {
                    SmartFilterSub.unsubscribe()
                }
            }
        },
        [selectedGame, isMultiscreenMode]
    )

    return (
        <PageWrapper style={{ background: 'black' }}>
            <PageContent
                style={{
                    height: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    background: isMultiscreenMode ? 'none' : 'white',
                }}
                maxWidth={'100%'}
                pt={0}
            >
                <GamePopup
                    game={selectedGame}
                    checkLogin={checkLogin}
                    isMultiscreenMode={(multiscreenMode) =>
                        setIsMultiscreenMode(multiscreenMode)
                    }
                />
                {!isMultiscreenMode && (
                    <Fragment>
                        {sameThemeGames?.length > 0 && (
                            <PageContent
                                pt={5}
                                maxWidth={'1140px'}
                                style={{
                                    display: 'inherit',
                                    marginBottom: '50px',
                                }}
                            >
                                <GamesSlider
                                    gamesData={sameThemeGames}
                                    slidesToScroll={5}
                                    title={'Spil med samme tema'}
                                />
                            </PageContent>
                        )}
                        {sameFeaturesGames?.length > 0 && (
                            <PageContent
                                maxWidth={'1140px'}
                                style={{
                                    display: 'inherit',
                                    marginBottom: '50px',
                                }}
                            >
                                <GamesSlider
                                    gamesData={sameFeaturesGames}
                                    slidesToScroll={5}
                                    title={'Spil med samme funktioner'}
                                />
                            </PageContent>
                        )}
                    </Fragment>
                )}
            </PageContent>
        </PageWrapper>
    )
}

export default withTheme(GamePage)
