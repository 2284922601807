export const userInfo = {
    userId: 164,
    status: 'closed',
}

export const liveTableGamesMock = [
    {
        id: 9178,
        name: 'Lightning Blackjack',
        game_id: 'LightningBlackjack',
        provider_id: '0',
        related_id: 'LightningBlackjackmobile',
        table_id: 'LightningSbj0001',
        group_id: '0',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: true,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 12,
        rod_hide: false,
        exclusive: true,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2021-11-05 07:45:36',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Lightning Blackjack',
            tableId: 'LightningSbj0001',
            gameSubType: null,
            gameType: 'lightningscalablebj',
            language: 'en',
            open: true,
            players: 47,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_lightr1_med?size=S',
                    M: '/tablePicture/bj_lightr1_med?size=M',
                    L: '/tablePicture/bj_lightr1_med?size=L',
                    XL: '/tablePicture/bj_lightr1_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_lightr1_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_lightr1_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_lightr1_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_lightr1_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 25000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3139,
        name: 'Baccarat A',
        game_id: 'evo_ba1',
        provider_id: '0',
        related_id: 'evo_ba1mobile',
        table_id: 'oytmvb9m1zysmc44',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: true,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: true,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Baccarat A',
            tableId: 'oytmvb9m1zysmc44',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 217,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bac2_bs_med?size=S',
                    M: '/tablePicture/bac2_bs_med?size=M',
                    L: '/tablePicture/bac2_bs_med?size=L',
                    XL: '/tablePicture/bac2_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bac2_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bac2_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bac2_bs_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bac2_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'B',
                'P',
                'B',
                'B',
                'B',
                'B',
                'P',
                'B',
                'P',
                'P',
                'B',
                'T',
                'P',
                'B',
                'B',
                'P',
                'P',
                'T',
                'T',
                'P',
                'P',
                'B',
                'B',
                'P',
                'P',
                'B',
                'B',
                'B',
                'B',
                'P',
                'P',
                'P',
                'B',
                'P',
                'P',
                'P',
                'B',
                'P',
                'B',
                'B',
                'P',
                'B',
                'B',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 9835,
        name: 'Grand Roulette',
        game_id: 'sg_410',
        provider_id: '1200031',
        related_id: 'sg_410mobile',
        table_id: 'studio-roulette2-desktop',
        group_id: '0',
        real_id: '145',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: 'hifreq',
        provider: 'scientific',
        subprovider: 'authentic',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: true,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: true,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2022-01-25 14:58:39',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {},
    },
    {
        id: 3204,
        name: 'Triple Card Poker ',
        game_id: 'evo_po_triplecardpoker',
        provider_id: '0',
        related_id: 'evo_po_triplecardpokermobile',
        table_id: 'TRPTable00000001',
        group_id: 'holdem',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'poker',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '96.63%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: true,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'poker'],
        liveCas: {
            name: 'Triple Card Poker',
            tableId: 'TRPTable00000001',
            gameSubType: null,
            gameType: 'TRP',
            language: 'en',
            open: true,
            players: 69,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/pk_gen2_med?size=S',
                    M: '/tablePicture/pk_gen2_med?size=M',
                    L: '/tablePicture/pk_gen2_med?size=L',
                    XL: '/tablePicture/pk_gen2_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/pk_gen2_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/pk_gen2_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/pk_gen2_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/pk_gen2_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3191,
        name: 'American Roulette',
        game_id: 'evo_ro_american',
        provider_id: '0',
        related_id: 'evo_ro_americanmobile',
        table_id: 'AmericanTable001',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '2',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'American Roulette',
            tableId: 'AmericanTable001',
            gameSubType: null,
            gameType: 'AmericanRoulette',
            language: 'en',
            open: true,
            players: 196,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/dzerot1_imrs_med?size=S',
                    M: '/tablePicture/dzerot1_imrs_med?size=M',
                    L: '/tablePicture/dzerot1_imrs_med?size=L',
                    XL: '/tablePicture/dzerot1_imrs_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/dzerot1_imrs_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/dzerot1_imrs_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/dzerot1_imrs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/dzerot1_imrs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 2,
                max: 20000,
            },
            results: ['17', '33', '35', '22', '25', '36', '14', '17', '1', '8'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 9589,
        name: 'Auto VIP',
        game_id: 'sg_580',
        provider_id: '1200041',
        related_id: '',
        table_id: '',
        group_id: '0',
        real_id: '145',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: 'hifreq',
        provider: 'scientific',
        subprovider: 'authentic',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2022-01-28 11:15:46',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {},
    },
    {
        id: 3193,
        name: 'Auto-Roulette',
        game_id: 'evo_ro_auto',
        provider_id: '0',
        related_id: 'evo_ro_automobile',
        table_id: '48z5pjps3ntvqc1b',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Auto-Roulette',
            tableId: '48z5pjps3ntvqc1b',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 2165,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/gen1_ss_thumb_med?size=S',
                    M: '/tablePicture/gen1_ss_thumb_med?size=M',
                    L: '/tablePicture/gen1_ss_thumb_med?size=L',
                    XL: '/tablePicture/gen1_ss_thumb_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/gen1_ss_thumb_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/gen1_ss_thumb_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/gen1_ss_thumb_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/gen1_ss_thumb_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1,
                max: 20000,
            },
            results: ['4', '2', '31', '1', '8', '11', '25', '32', '5', '25'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3196,
        name: 'Auto-Roulette La Partage',
        game_id: 'evo_ro_auto_lapartage',
        provider_id: '0',
        related_id: 'evo_ro_auto_lapartagemobile',
        table_id: 'f1f4rm9xgh4j3u2z',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '2',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Auto-Roulette La Partage',
            tableId: 'f1f4rm9xgh4j3u2z',
            gameSubType: 'french',
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 191,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/spa_ss_thumb_med?size=S',
                    M: '/tablePicture/spa_ss_thumb_med?size=M',
                    L: '/tablePicture/spa_ss_thumb_med?size=L',
                    XL: '/tablePicture/spa_ss_thumb_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/spa_ss_thumb_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/spa_ss_thumb_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/spa_ss_thumb_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/spa_ss_thumb_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 2,
                max: 20000,
            },
            results: ['20', '35', '15', '0', '9', '15', '14', '4', '25', '2'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3195,
        name: 'Auto-Roulette VIP',
        game_id: 'evo_ro_auto_vip',
        provider_id: '0',
        related_id: 'evo_ro_auto_vipmobile',
        table_id: '01rb77cq1gtenhmo',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '2',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Auto-Roulette VIP',
            tableId: '01rb77cq1gtenhmo',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 401,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/vip1_ss_thumb_med?size=S',
                    M: '/tablePicture/vip1_ss_thumb_med?size=M',
                    L: '/tablePicture/vip1_ss_thumb_med?size=L',
                    XL: '/tablePicture/vip1_ss_thumb_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/vip1_ss_thumb_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/vip1_ss_thumb_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/vip1_ss_thumb_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/vip1_ss_thumb_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 2,
                max: 20000,
            },
            results: ['30', '34', '1', '8', '25', '6', '3', '22', '24', '35'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3140,
        name: 'Baccarat B',
        game_id: 'evo_ba5',
        provider_id: '0',
        related_id: 'evo_ba5mobile',
        table_id: '60i0lcfx5wkkv3sy',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Baccarat B',
            tableId: '60i0lcfx5wkkv3sy',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 153,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bac3_bs_med?size=S',
                    M: '/tablePicture/bac3_bs_med?size=M',
                    L: '/tablePicture/bac3_bs_med?size=L',
                    XL: '/tablePicture/bac3_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bac3_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bac3_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bac3_bs_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bac3_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'B',
                'B',
                'P',
                'T',
                'B',
                'B',
                'P',
                'T',
                'B',
                'B',
                'P',
                'B',
                'P',
                'P',
                'T',
                'B',
                'P',
                'B',
                'B',
                'B',
                'T',
                'T',
                'P',
                'P',
                'B',
                'P',
                'B',
                'B',
                'P',
                'B',
                'P',
                'P',
                'B',
                'B',
                'P',
                'P',
                'P',
                'P',
                'P',
                'P',
                'B',
                'B',
                'B',
                'P',
                'B',
                'P',
                'P',
                'T',
                'T',
                'P',
                'B',
                'P',
                'P',
                'B',
                'B',
                'B',
                'B',
                'P',
                'P',
                'P',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3147,
        name: 'Baccarat C',
        game_id: 'evo_ba6',
        provider_id: '0',
        related_id: 'evo_ba6mobile',
        table_id: 'ndgvs3tqhfuaadyg',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Baccarat C',
            tableId: 'ndgvs3tqhfuaadyg',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 179,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bact5_bs_med?size=S',
                    M: '/tablePicture/bact5_bs_med?size=M',
                    L: '/tablePicture/bact5_bs_med?size=L',
                    XL: '/tablePicture/bact5_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bact5_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bact5_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bact5_bs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bact5_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'B',
                'B',
                'B',
                'B',
                'B',
                'B',
                'T',
                'B',
                'B',
                'P',
                'P',
                'B',
                'P',
                'P',
                'P',
                'P',
                'T',
                'P',
                'P',
                'P',
                'T',
                'B',
                'P',
                'P',
                'B',
                'B',
                'T',
                'P',
                'B',
                'B',
                'P',
                'P',
                'P',
                'P',
                'P',
                'B',
                'B',
                'P',
                'B',
                'B',
                'B',
                'P',
                'T',
                'T',
                'B',
                'P',
                'B',
                'T',
                'P',
                'B',
                'P',
                'B',
                'B',
                'B',
                'P',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3137,
        name: 'Baccarat Control Squeeze',
        game_id: 'evo_ba2',
        provider_id: '0',
        related_id: 'evo_ba2mobile',
        table_id: 'k2oswnib7jjaaznw',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '50',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Baccarat Control Squeeze',
            tableId: 'k2oswnib7jjaaznw',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 240,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bac4_bs_med?size=S',
                    M: '/tablePicture/bac4_bs_med?size=M',
                    L: '/tablePicture/bac4_bs_med?size=L',
                    XL: '/tablePicture/bac4_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bac4_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bac4_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bac4_bs_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bac4_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'B',
                'P',
                'P',
                'B',
                'P',
                'B',
                'P',
                'B',
                'B',
                'B',
                'P',
                'P',
                'B',
                'B',
                'B',
                'P',
                'B',
                'B',
                'B',
                'P',
                'B',
                'B',
                'B',
                'B',
                'B',
                'T',
                'B',
                'P',
                'B',
                'P',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3138,
        name: 'Baccarat Squeeze',
        game_id: 'evo_ba3',
        provider_id: '0',
        related_id: 'evo_ba3mobile',
        table_id: 'zixzea8nrf1675oh',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '50',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Baccarat Squeeze',
            tableId: 'zixzea8nrf1675oh',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 142,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bac1_bs_med?size=S',
                    M: '/tablePicture/bac1_bs_med?size=M',
                    L: '/tablePicture/bac1_bs_med?size=L',
                    XL: '/tablePicture/bac1_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bac1_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bac1_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bac1_bs_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bac1_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'P',
                'B',
                'B',
                'B',
                'B',
                'B',
                'P',
                'B',
                'P',
                'B',
                'P',
                'P',
                'B',
                'P',
                'B',
                'B',
                'B',
                'P',
                'B',
                'B',
                'B',
                'P',
                'P',
                'B',
                'B',
                'B',
                'P',
                'B',
                'B',
                'P',
                'B',
                'P',
                'P',
                'B',
                'P',
                'B',
                'P',
                'B',
                'B',
                'P',
                'T',
                'P',
                'P',
                'B',
                'B',
                'B',
                'T',
                'B',
                'B',
                'B',
                'B',
                'B',
                'T',
                'P',
                'P',
                'P',
                'P',
                'P',
                'P',
                'P',
                'P',
                'B',
                'B',
                'B',
                'P',
                'P',
                'T',
                'P',
                'B',
                'P',
                'T',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3148,
        name: 'Blackjack A',
        game_id: 'evo_bj_a',
        provider_id: '0',
        related_id: 'evo_bj_amobile',
        table_id: 'uwd2bl2khwcikjlz',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '50',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack A',
            tableId: 'uwd2bl2khwcikjlz',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 44,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_gen7_med?size=S',
                    M: '/tablePicture/bj_gen7_med?size=M',
                    L: '/tablePicture/bj_gen7_med?size=L',
                    XL: '/tablePicture/bj_gen7_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_gen7_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_gen7_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_gen7_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bj_gen7_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 50,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3149,
        name: 'Blackjack B',
        game_id: 'evo_bj_b',
        provider_id: '0',
        related_id: 'evo_bj_bmobile',
        table_id: 'xphpcthv8e6ivc16',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '100',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack B',
            tableId: 'xphpcthv8e6ivc16',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 17,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_gen2_med?size=S',
                    M: '/tablePicture/bj_gen2_med?size=M',
                    L: '/tablePicture/bj_gen2_med?size=L',
                    XL: '/tablePicture/bj_gen2_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_gen2_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_gen2_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_gen2_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bj_gen2_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 100,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3153,
        name: 'Blackjack C',
        game_id: 'evo_bj_c',
        provider_id: '0',
        related_id: 'evo_bj_cmobile',
        table_id: 'jhs44mm0v3fi3aux',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '150',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack C',
            tableId: 'jhs44mm0v3fi3aux',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 23,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_gen3_med?size=S',
                    M: '/tablePicture/bj_gen3_med?size=M',
                    L: '/tablePicture/bj_gen3_med?size=L',
                    XL: '/tablePicture/bj_gen3_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_gen3_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_gen3_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_gen3_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bj_gen3_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 150,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3156,
        name: 'Blackjack Diamond VIP',
        game_id: 'evo_bj_diamond_vip',
        provider_id: '0',
        related_id: 'evo_bj_diamond_vipmobile',
        table_id: 'rdefcn4sffgo39l7',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10000',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Diamond VIP',
            tableId: 'rdefcn4sffgo39l7',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 13,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [1, 0, 2],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk10_med?size=S',
                    M: '/tablePicture/bj_vipk10_med?size=M',
                    L: '/tablePicture/bj_vipk10_med?size=L',
                    XL: '/tablePicture/bj_vipk10_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk10_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk10_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk10_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk10_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10000,
                max: 25000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3160,
        name: 'Blackjack Fortune VIP',
        game_id: 'evo_bj_fortune_vip',
        provider_id: '0',
        related_id: 'evo_bj_fortune_vipmobile',
        table_id: 'ejx1a04w4ben0mou',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '2500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Fortune VIP',
            tableId: 'ejx1a04w4ben0mou',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 10,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [5, 2, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk6_med?size=S',
                    M: '/tablePicture/bj_vipk6_med?size=M',
                    L: '/tablePicture/bj_vipk6_med?size=L',
                    XL: '/tablePicture/bj_vipk6_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk6_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk6_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk6_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk6_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 2500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3181,
        name: 'Blackjack Grand VIP',
        game_id: 'evo_bj_grand_vip',
        provider_id: '0',
        related_id: 'evo_bj_grand_vipmobile',
        table_id: 'gfzrqe4hqv24kukc',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '5000',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Grand VIP',
            tableId: 'gfzrqe4hqv24kukc',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 7,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [5, 6],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk8_med?size=S',
                    M: '/tablePicture/bj_vipk8_med?size=M',
                    L: '/tablePicture/bj_vipk8_med?size=L',
                    XL: '/tablePicture/bj_vipk8_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk8_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk8_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk8_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk8_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 5000,
                max: 25000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3161,
        name: 'Blackjack Party',
        game_id: 'evo_bj_party',
        provider_id: '0',
        related_id: 'evo_bj_partymobile',
        table_id: 'sni5cza6d1vvl50i',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Party',
            tableId: 'sni5cza6d1vvl50i',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 74,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_party_med?size=S',
                    M: '/tablePicture/bj_party_med?size=M',
                    L: '/tablePicture/bj_party_med?size=L',
                    XL: '/tablePicture/bj_party_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_party_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_party_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_party_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_party_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 5,
                max: 2000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3180,
        name: 'Blackjack Platinum VIP',
        game_id: 'evo_bj_platinum_vip',
        provider_id: '0',
        related_id: 'evo_bj_platinum_vipmobile',
        table_id: 'h463tlq1rhl1lfr2',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '2500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Platinum VIP',
            tableId: 'h463tlq1rhl1lfr2',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 9,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 0, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk12_med?size=S',
                    M: '/tablePicture/bj_vipk12_med?size=M',
                    L: '/tablePicture/bj_vipk12_med?size=L',
                    XL: '/tablePicture/bj_vipk12_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk12_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk12_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk12_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk12_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 2500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3169,
        name: 'Blackjack Silver A',
        game_id: 'evo_bj_silver1',
        provider_id: '0',
        related_id: 'evo_bj_silver1mobile',
        table_id: 'gkmq0o2hryjyqu30',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Silver A',
            tableId: 'gkmq0o2hryjyqu30',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 13,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_cgent87_med?size=S',
                    M: '/tablePicture/bj_cgent87_med?size=M',
                    L: '/tablePicture/bj_cgent87_med?size=L',
                    XL: '/tablePicture/bj_cgent87_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent87_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent87_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent87_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent87_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3171,
        name: 'Blackjack Silver B',
        game_id: 'evo_bj_silver2',
        provider_id: '0',
        related_id: 'evo_bj_silver2mobile',
        table_id: '9f4xhuhdd005xlbl',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Silver B',
            tableId: '9f4xhuhdd005xlbl',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 11,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_cgent10_med?size=S',
                    M: '/tablePicture/bj_cgent10_med?size=M',
                    L: '/tablePicture/bj_cgent10_med?size=L',
                    XL: '/tablePicture/bj_cgent10_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent10_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent10_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent10_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent10_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3172,
        name: 'Blackjack Silver C',
        game_id: 'evo_bj_silver3',
        provider_id: '0',
        related_id: 'evo_bj_silver3mobile',
        table_id: 'qlrc3fq3v7p6awm4',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Silver C',
            tableId: 'qlrc3fq3v7p6awm4',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 8,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_cgent88_med?size=S',
                    M: '/tablePicture/bj_cgent88_med?size=M',
                    L: '/tablePicture/bj_cgent88_med?size=L',
                    XL: '/tablePicture/bj_cgent88_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent88_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent88_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent88_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent88_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3173,
        name: 'Blackjack Silver D',
        game_id: 'evo_bj_silver4',
        provider_id: '0',
        related_id: 'evo_bj_silver4mobile',
        table_id: 'qckwjf2o52r9ikeb',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Silver D',
            tableId: 'qckwjf2o52r9ikeb',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 9,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 6, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_cgent11_med?size=S',
                    M: '/tablePicture/bj_cgent11_med?size=M',
                    L: '/tablePicture/bj_cgent11_med?size=L',
                    XL: '/tablePicture/bj_cgent11_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent11_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent11_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent11_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent11_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3174,
        name: 'Blackjack Silver E',
        game_id: 'evo_bj_silver5',
        provider_id: '0',
        related_id: 'evo_bj_silver5mobile',
        table_id: 'gazgtkid9h1b0dn9',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Silver E',
            tableId: 'gazgtkid9h1b0dn9',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 8,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [5, 0, 2, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_cgent94_med?size=S',
                    M: '/tablePicture/bj_cgent94_med?size=M',
                    L: '/tablePicture/bj_cgent94_med?size=L',
                    XL: '/tablePicture/bj_cgent94_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent94_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent94_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent94_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent94_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3205,
        name: 'Blackjack Silver F',
        game_id: 'evo_bj_silver6',
        provider_id: '0',
        related_id: 'evo_bj_silver6mobile',
        table_id: 'lnofoyxv756qaezy',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Silver F',
            tableId: 'lnofoyxv756qaezy',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 13,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_cgent16_med?size=S',
                    M: '/tablePicture/bj_cgent16_med?size=M',
                    L: '/tablePicture/bj_cgent16_med?size=L',
                    XL: '/tablePicture/bj_cgent16_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent16_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent16_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent16_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent16_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 2500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3206,
        name: 'Blackjack Silver G',
        game_id: 'evo_bj_silver7',
        provider_id: '0',
        related_id: 'evo_bj_silver7mobile',
        table_id: 'lnofpmm3756qae2c',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack Silver G',
            tableId: 'lnofpmm3756qae2c',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 11,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 0, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_cgent5_med?size=S',
                    M: '/tablePicture/bj_cgent5_med?size=M',
                    L: '/tablePicture/bj_cgent5_med?size=L',
                    XL: '/tablePicture/bj_cgent5_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_cgent5_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_cgent5_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_cgent5_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent5_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 2500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 9841,
        name: 'Blackjack VIP 18',
        game_id: 'BlackjackVIP18',
        provider_id: '0',
        related_id: 'BlackjackVIP18mob',
        table_id: 'ps3t7nhskfe2fhkj',
        group_id: '0',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2022-02-03 15:01:59',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack VIP 18',
            tableId: 'ps3t7nhskfe2fhkj',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 11,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipt21_med?size=S',
                    M: '/tablePicture/bj_vipt21_med?size=M',
                    L: '/tablePicture/bj_vipt21_med?size=L',
                    XL: '/tablePicture/bj_vipt21_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipt21_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipt21_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipt21_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipt21_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3150,
        name: 'Blackjack VIP A',
        game_id: 'evo_bj_a_vip',
        provider_id: '0',
        related_id: 'evo_bj_a_vipmobile',
        table_id: '0mvn914lkmo9vaq8',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack VIP A',
            tableId: '0mvn914lkmo9vaq8',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 11,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 6, 2, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk11_med?size=S',
                    M: '/tablePicture/bj_vipk11_med?size=M',
                    L: '/tablePicture/bj_vipk11_med?size=L',
                    XL: '/tablePicture/bj_vipk11_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk11_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk11_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk11_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk11_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1000,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3151,
        name: 'Blackjack VIP B',
        game_id: 'evo_bj_b_vip',
        provider_id: '0',
        related_id: 'evo_bj_b_vipmobile',
        table_id: 'cpxl81x0rgi34cmo',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack VIP B',
            tableId: 'cpxl81x0rgi34cmo',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 9,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk2_med?size=S',
                    M: '/tablePicture/bj_vipk2_med?size=M',
                    L: '/tablePicture/bj_vipk2_med?size=L',
                    XL: '/tablePicture/bj_vipk2_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk2_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk2_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk2_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk2_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1000,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3152,
        name: 'Blackjack VIP C',
        game_id: 'evo_bj_c_vip',
        provider_id: '0',
        related_id: 'evo_bj_c_vipmobile',
        table_id: 'l5aug44hhzr3qvxs',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '500',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack VIP C',
            tableId: 'l5aug44hhzr3qvxs',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 10,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [5, 1, 2, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk3_med?size=S',
                    M: '/tablePicture/bj_vipk3_med?size=M',
                    L: '/tablePicture/bj_vipk3_med?size=L',
                    XL: '/tablePicture/bj_vipk3_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk3_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk3_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk3_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk3_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 2500,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3154,
        name: 'Blackjack VIP D',
        game_id: 'evo_bj_d_vip',
        provider_id: '0',
        related_id: 'evo_bj_d_vipmobile',
        table_id: 'o3d9tx3u8kd0yawc',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '1000',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack VIP D',
            tableId: 'o3d9tx3u8kd0yawc',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 10,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 6, 0],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk4_med?size=S',
                    M: '/tablePicture/bj_vipk4_med?size=M',
                    L: '/tablePicture/bj_vipk4_med?size=L',
                    XL: '/tablePicture/bj_vipk4_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk4_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk4_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk4_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk4_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1000,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3157,
        name: 'Blackjack VIP E',
        game_id: 'evo_bj_e_vip',
        provider_id: '0',
        related_id: 'evo_bj_e_vipmobile',
        table_id: 'psm2um7k4da8zwc2',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '1000',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack VIP E',
            tableId: 'psm2um7k4da8zwc2',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 10,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 6, 0, 2, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk5_med?size=S',
                    M: '/tablePicture/bj_vipk5_med?size=M',
                    L: '/tablePicture/bj_vipk5_med?size=L',
                    XL: '/tablePicture/bj_vipk5_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk5_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk5_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk5_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk5_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1000,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3175,
        name: 'Blackjack VIP G',
        game_id: 'evo_bj_g_vip',
        provider_id: '0',
        related_id: 'evo_bj_g_vipmobile',
        table_id: 'z5pf5pichcsw3d2o',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '1000',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack VIP G',
            tableId: 'z5pf5pichcsw3d2o',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 11,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 1, 0, 2, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_vipk7_med?size=S',
                    M: '/tablePicture/bj_vipk7_med?size=M',
                    L: '/tablePicture/bj_vipk7_med?size=L',
                    XL: '/tablePicture/bj_vipk7_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_vipk7_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_vipk7_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_vipk7_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_vipk7_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1000,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3176,
        name: 'Blackjack VIP H',
        game_id: 'evo_bj_h_vip',
        provider_id: '0',
        related_id: 'evo_bj_h_vipmobile',
        table_id: 's63nx2mpdomgjagb',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '1000',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack VIP H',
            tableId: 's63nx2mpdomgjagb',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 10,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [5, 2, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_cgent66_med?size=S',
                    M: '/tablePicture/bj_cgent66_med?size=M',
                    L: '/tablePicture/bj_cgent66_med?size=L',
                    XL: '/tablePicture/bj_cgent66_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent66_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent66_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent66_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_cgent66_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1000,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3159,
        name: 'Blackjack VIP X',
        game_id: 'evo_bj_f',
        provider_id: '0',
        related_id: 'evo_bj_fmobile',
        table_id: 'bghflgi59db7d7r2',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '250',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Blackjack VIP X',
            tableId: 'bghflgi59db7d7r2',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 14,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [5, 6, 2],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_gen4_med?size=S',
                    M: '/tablePicture/bj_gen4_med?size=M',
                    L: '/tablePicture/bj_gen4_med?size=L',
                    XL: '/tablePicture/bj_gen4_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_gen4_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_gen4_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_gen4_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bj_gen4_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1000,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3207,
        name: 'Caribbean Stud Poker',
        game_id: 'evo_po_caribbeanstud',
        provider_id: '0',
        related_id: 'evo_po_caribbeanstudmobile',
        table_id: 'CSPTable00000001',
        group_id: 'holdem',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'poker',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '96.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'poker'],
        liveCas: {
            name: 'Caribbean Stud Poker',
            tableId: 'CSPTable00000001',
            gameSubType: null,
            gameType: 'CSP',
            language: 'en',
            open: true,
            players: 37,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/pk_crb_med?size=S',
                    M: '/tablePicture/pk_crb_med?size=M',
                    L: '/tablePicture/pk_crb_med?size=L',
                    XL: '/tablePicture/pk_crb_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/pk_crb_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/pk_crb_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/pk_crb_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/pk_crb_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3202,
        name: 'Casino Malta Roulette',
        game_id: 'evo_ro_casinomalta',
        provider_id: '0',
        related_id: 'evo_ro_casinomaltamobile',
        table_id: 'mvrcophqscoqosd6',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Casino Malta Roulette',
            tableId: 'mvrcophqscoqosd6',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 18,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/casmlt1_imr_med?size=S',
                    M: '/tablePicture/casmlt1_imr_med?size=M',
                    L: '/tablePicture/casmlt1_imr_med?size=L',
                    XL: '/tablePicture/casmlt1_imr_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/casmlt1_imr_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/casmlt1_imr_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/casmlt1_imr_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/casmlt1_imr_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 20000,
            },
            results: [
                '10',
                '25',
                '35',
                '14',
                '14',
                '17',
                '4',
                '10',
                '29',
                '19',
            ],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 2101,
        name: 'Dansk Roulette',
        game_id: 'evo_danishtable',
        provider_id: '3aidqufu9szxha2n',
        related_id: 'evo_ro_danskmobile',
        table_id: '3aidqufu9szxha2n',
        group_id: 'roulette',
        real_id: '',
        alt: 1,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '5',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: true,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: true,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Dansk Roulette',
            tableId: '3aidqufu9szxha2n',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'da',
            open: false,
            players: 4,
            operationHours: 'Bounded',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'Bounded',
                value: {
                    startTime: '13:00',
                    endTime: '01:00',
                },
            },
            thumbs: {
                links: {
                    S: '/tablePicture/dgenm1_vir_med?size=S',
                    M: '/tablePicture/dgenm1_vir_med?size=M',
                    L: '/tablePicture/dgenm1_vir_med?size=L',
                    XL: '/tablePicture/dgenm1_vir_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/dgenm1_vir_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/dgenm1_vir_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/dgenm1_vir_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/dgenm1_vir_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 5,
                max: 20000,
            },
            results: ['35', '4', '0', '34', '25', '24', '28', '26', '6', '10'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 10147,
        name: 'dawdawd',
        game_id: 'tretttqt',
        provider_id: 'dwadawd',
        related_id: '',
        table_id: '131232',
        group_id: '0',
        real_id: '3213123',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'scientific',
        subprovider: 'netent',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2022-03-02 18:02:44',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {},
    },
    {
        id: 3190,
        name: 'French Roulette Gold',
        game_id: 'evo_ro_frenchgold',
        provider_id: '0',
        related_id: 'evo_ro_frenchgoldmobile',
        table_id: 'my7fkvys64baf5dl',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '20',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'French Roulette Gold',
            tableId: 'wzg6kdkad1oe7m5k',
            gameSubType: 'french',
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 195,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/vipk1_imr_med?size=S',
                    M: '/tablePicture/vipk1_imr_med?size=M',
                    L: '/tablePicture/vipk1_imr_med?size=L',
                    XL: '/tablePicture/vipk1_imr_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/vipk1_imr_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/vipk1_imr_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/vipk1_imr_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/vipk1_imr_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 20000,
            },
            results: ['29', '0', '4', '6', '19', '11', '17', '1', '4', '20'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 10150,
        name: 'game',
        game_id: 'gameidmob',
        provider_id: 'prov999mob',
        related_id: '',
        table_id: '999mob',
        group_id: '9',
        real_id: '9',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'isoftbet',
        subprovider: 'gdm',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2022-03-02 18:12:13',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {},
    },
    {
        id: 3200,
        name: 'Hippodrome Grand Casino',
        game_id: 'evo_ro_hippodromegrandcasino',
        provider_id: '0',
        related_id: 'evo_ro_hippodromegrandcasinomobile',
        table_id: 'mrpuiwhx5slaurcy',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Hippodrome Grand Casino',
            tableId: 'mrpuiwhx5slaurcy',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 25,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/hippo2_imr_med?size=S',
                    M: '/tablePicture/hippo2_imr_med?size=M',
                    L: '/tablePicture/hippo2_imr_med?size=L',
                    XL: '/tablePicture/hippo2_imr_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/hippo2_imr_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/hippo2_imr_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/hippo2_imr_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/hippo2_imr_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 20000,
            },
            results: ['18', '6', '34', '6', '27', '2', '5', '8', '27', '6'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3186,
        name: 'Immersive Roulette',
        game_id: 'evo_ro_immersive',
        provider_id: '0',
        related_id: 'evo_ro_immersivemobile',
        table_id: '7x0b1tgh7agmf6hv',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Immersive Roulette',
            tableId: '7x0b1tgh7agmf6hv',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 744,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/immersive_med?size=S',
                    M: '/tablePicture/immersive_med?size=M',
                    L: '/tablePicture/immersive_med?size=L',
                    XL: '/tablePicture/immersive_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/immersive_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/immersive_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/immersive_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/immersive_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 20000,
            },
            results: ['3', '36', '30', '4', '29', '1', '16', '21', '33', '5'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3118,
        name: 'Infinite Blackjack',
        game_id: 'evo_bj_infinite',
        provider_id: '0',
        related_id: 'evo_bj_infinitemobile',
        table_id: 'mrfykemt5slanyi5',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '99.47%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Infinite Blackjack',
            tableId: 'mrfykemt5slanyi5',
            gameSubType: null,
            gameType: 'ScalableBlackjack',
            language: 'en',
            open: true,
            players: 356,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_infgenr1_med?size=S',
                    M: '/tablePicture/bj_infgenr1_med?size=M',
                    L: '/tablePicture/bj_infgenr1_med?size=L',
                    XL: '/tablePicture/bj_infgenr1_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_infgenr1_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_infgenr1_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_infgenr1_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_infgenr1_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3381,
        name: 'Instant Roulette',
        game_id: 'evo_ro_instant_roulette',
        provider_id: '0',
        related_id: 'evo_ro_instant_roulettemobile',
        table_id: 'InstantRo0000001',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '2',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Instant Roulette',
            tableId: 'InstantRo0000001',
            gameSubType: null,
            gameType: 'instantroulette',
            language: 'en',
            open: true,
            players: 320,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/s_inror0_med?size=S',
                    M: '/tablePicture/s_inror0_med?size=M',
                    L: '/tablePicture/s_inror0_med?size=L',
                    XL: '/tablePicture/s_inror0_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/s_inror0_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/s_inror0_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/s_inror0_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/s_inror0_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1,
                max: 20000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3117,
        name: 'Lightning Roulette',
        game_id: 'evo_sp_lightning',
        provider_id: '0',
        related_id: 'evo_sp_lightningmobile',
        table_id: 'LightningTable01',
        group_id: 'game_shows',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'andet',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '2',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9000,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'entertainment'],
        liveCas: {
            name: 'Lightning Roulette',
            tableId: 'LightningTable01',
            gameSubType: 'lightning',
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 3399,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/lightr1_imrs_med?size=S',
                    M: '/tablePicture/lightr1_imrs_med?size=M',
                    L: '/tablePicture/lightr1_imrs_med?size=L',
                    XL: '/tablePicture/lightr1_imrs_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/lightr1_imrs_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/lightr1_imrs_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/lightr1_imrs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/lightr1_imrs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 2,
                max: 20000,
            },
            results: [
                '12',
                '15',
                '28',
                '14',
                '5',
                '13',
                '22',
                '33',
                '36',
                '27',
            ],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 10002,
        name: 'LIVE Blaze',
        game_id: 'sg_200',
        provider_id: '1200024',
        related_id: '',
        table_id: 'auto-blaze-desktop',
        group_id: '0',
        real_id: '145',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: 'hifreq',
        provider: 'scientific',
        subprovider: 'authentic',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2022-01-25 14:55:44',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {},
    },
    {
        id: 10029,
        name: 'LIVE Speed 1',
        game_id: 'sg_240',
        provider_id: '1200022',
        related_id: 'sg_240mob',
        table_id: 'auto-speed1-desktop',
        group_id: '0',
        real_id: '145',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: 'hifreq',
        provider: 'scientific',
        subprovider: 'authentic',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2022-02-14 13:36:50',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {},
    },
    {
        id: 3136,
        name: 'No Comm Baccarat',
        game_id: 'evo_ba4',
        provider_id: '0',
        related_id: 'evo_ba4mobile',
        table_id: 'NoCommBac0000001',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'No Commission Baccarat',
            tableId: 'NoCommBac0000001',
            gameSubType: 'nocommission',
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 397,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/ncr1_bs_med?size=S',
                    M: '/tablePicture/ncr1_bs_med?size=M',
                    L: '/tablePicture/ncr1_bs_med?size=L',
                    XL: '/tablePicture/ncr1_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/ncr1_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/ncr1_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/ncr1_bs_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/ncr1_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: ['P', 'B', 'B', 'P', 'B', 'P', 'T', 'T'],
            privateTableConfig: null,
        },
    },
    {
        id: 3146,
        name: 'No Comm Speed Baccarat',
        game_id: 'evo_ba_speed9',
        provider_id: '0',
        related_id: 'evo_ba_speed9mobile',
        table_id: 'ndgv76kehfuaaeec',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'No Commission Speed Baccarat A',
            tableId: 'ndgv76kehfuaaeec',
            gameSubType: 'nocommission',
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 156,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bact4_bs_med?size=S',
                    M: '/tablePicture/bact4_bs_med?size=M',
                    L: '/tablePicture/bact4_bs_med?size=L',
                    XL: '/tablePicture/bact4_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bact4_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bact4_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bact4_bs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bact4_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'P',
                'P',
                'B',
                'B',
                'B',
                'B',
                'P',
                'B',
                'P',
                'B',
                'P',
                'B',
                'P',
                'P',
                'B',
                'B',
                'P',
                'P',
                'P',
                'P',
                'P',
                'T',
                'P',
                'B',
                'B',
                'P',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3379,
        name: 'Power Blackjack',
        game_id: 'evo_bj_power_blackjack',
        provider_id: '0',
        related_id: 'evo_bj_power_blackjackmobile',
        table_id: 'PowerInfiniteBJ1',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.80%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 400,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Power Blackjack',
            tableId: 'PowerInfiniteBJ1',
            gameSubType: null,
            gameType: 'powerscalableblackjack',
            language: 'en',
            open: true,
            players: 117,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_powgenr1_med?size=S',
                    M: '/tablePicture/bj_powgenr1_med?size=M',
                    L: '/tablePicture/bj_powgenr1_med?size=L',
                    XL: '/tablePicture/bj_powgenr1_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/bj_powgenr1_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/bj_powgenr1_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/bj_powgenr1_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_powgenr1_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3187,
        name: 'Roulette',
        game_id: 'evo_ro_roulette',
        provider_id: '0',
        related_id: 'evo_ro_roulettemobile',
        table_id: 'vctlz20yfnmp1ylr',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Roulette',
            tableId: 'vctlz20yfnmp1ylr',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 853,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/green_imrs_med?size=S',
                    M: '/tablePicture/green_imrs_med?size=M',
                    L: '/tablePicture/green_imrs_med?size=L',
                    XL: '/tablePicture/green_imrs_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/green_imrs_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/green_imrs_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/green_imrs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/green_imrs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 5,
                max: 20000,
            },
            results: ['25', '25', '23', '33', '9', '29', '14', '4', '36', '13'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3133,
        name: 'Salon Privé Baccarat',
        game_id: 'evo_ba_privat',
        provider_id: '0',
        related_id: 'evo_ba_privatmobile',
        table_id: 'SalPrivBac000001',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10000',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Salon Privé Baccarat A',
            tableId: 'SalPrivBac000001',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 0,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/spr1_bs_med?size=S',
                    M: '/tablePicture/spr1_bs_med?size=M',
                    L: '/tablePicture/spr1_bs_med?size=L',
                    XL: '/tablePicture/spr1_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/spr1_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/spr1_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/spr1_bs_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/spr1_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10000,
                max: 150000,
            },
            results: null,
            history: [
                'P',
                'B',
                'T',
                'T',
                'B',
                'T',
                'P',
                'B',
                'B',
                'B',
                'P',
                'P',
                'P',
                'P',
                'B',
                'P',
                'B',
                'P',
                'P',
                'P',
                'P',
                'T',
                'P',
                'P',
                'P',
                'P',
                'T',
                'B',
                'P',
                'B',
                'P',
            ],
            privateTableConfig: {
                isPrivate: true,
                minBetsCount: 15,
            },
        },
    },
    {
        id: 3182,
        name: 'Salon Privé Blackjack 1',
        game_id: 'evo_bj_sp1',
        provider_id: '0',
        related_id: 'evo_bj_sp1mobile',
        table_id: 'mdkqdxtkdctrhnsx',
        group_id: '0',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10000',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Salon Privé Blackjack A',
            tableId: 'mdkqdxtkdctrhnsx',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 0,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [6, 0],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spr1_med?size=S',
                    M: '/tablePicture/bj_spr1_med?size=M',
                    L: '/tablePicture/bj_spr1_med?size=L',
                    XL: '/tablePicture/bj_spr1_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spr1_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spr1_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spr1_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bj_spr1_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10000,
                max: 100000,
            },
            results: null,
            history: null,
            privateTableConfig: {
                isPrivate: true,
                minBetsCount: 6,
            },
        },
    },
    {
        id: 3183,
        name: 'Salon Privé Blackjack 2',
        game_id: 'evo_bj_sp2',
        provider_id: '0',
        related_id: 'evo_bj_sp2mobile',
        table_id: 'mdkqfe74dctrhntj',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '15000',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Salon Privé Blackjack C',
            tableId: 'mdkqfe74dctrhntj',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 0,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spr2_med?size=S',
                    M: '/tablePicture/bj_spr2_med?size=M',
                    L: '/tablePicture/bj_spr2_med?size=L',
                    XL: '/tablePicture/bj_spr2_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spr2_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spr2_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spr2_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bj_spr2_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10000,
                max: 100000,
            },
            results: null,
            history: null,
            privateTableConfig: {
                isPrivate: true,
                minBetsCount: 4,
            },
        },
    },
    {
        id: 10135,
        name: 'Salon Privé Blackjack D',
        game_id: 'salon_prive_blackjack_d',
        provider_id: 'olbinkuoylzayeoj',
        related_id: 'salon_prive_blackjack_dmobile',
        table_id: 'olbinkuoylzayeoj',
        group_id: '0',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2022-02-09 14:30:49',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Salon Privé Blackjack D',
            tableId: 'olbinkuoylzayeoj',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 1,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 6, 0, 2, 3],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spr5_med?size=S',
                    M: '/tablePicture/bj_spr5_med?size=M',
                    L: '/tablePicture/bj_spr5_med?size=L',
                    XL: '/tablePicture/bj_spr5_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spr5_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spr5_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spr5_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bj_spr5_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 15000,
                max: 100000,
            },
            results: null,
            history: null,
            privateTableConfig: {
                isPrivate: true,
            },
        },
    },
    {
        id: 3189,
        name: 'Salon Privé Roulette',
        game_id: 'evo_ro_salonprive',
        provider_id: '0',
        related_id: 'evo_ro_salonprivemobile',
        table_id: 'mdkqijp3dctrhnuv',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '1000',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Salon Privé Roulette',
            tableId: 'mdkqijp3dctrhnuv',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 1,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/spr2_imrs_med?size=S',
                    M: '/tablePicture/spr2_imrs_med?size=M',
                    L: '/tablePicture/spr2_imrs_med?size=L',
                    XL: '/tablePicture/spr2_imrs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/spr2_imrs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/spr2_imrs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/spr2_imrs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/spr2_imrs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1000,
                max: 100000,
            },
            results: ['2', '4', '4', '34', '11', '31', '19', '10', '26', '14'],
            history: null,
            privateTableConfig: {
                isPrivate: true,
                minBetsCount: 100,
            },
        },
    },
    {
        id: 3194,
        name: 'Speed Auto Roulette',
        game_id: 'evo_ro_speedauto',
        provider_id: '0',
        related_id: 'evo_ro_speedautomobile',
        table_id: 'SpeedAutoRo00001',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Speed Auto Roulette',
            tableId: 'SpeedAutoRo00001',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 822,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/genr1_ss_thumb_med?size=S',
                    M: '/tablePicture/genr1_ss_thumb_med?size=M',
                    L: '/tablePicture/genr1_ss_thumb_med?size=L',
                    XL: '/tablePicture/genr1_ss_thumb_med?size=XL',
                },
                thumbnails: {
                    S:
                        'https://bshots.egcvi.com/thumbnail/genr1_ss_thumb_med_S.jpg',
                    M:
                        'https://bshots.egcvi.com/thumbnail/genr1_ss_thumb_med_M.jpg',
                    L:
                        'https://bshots.egcvi.com/thumbnail/genr1_ss_thumb_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/genr1_ss_thumb_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 1,
                max: 20000,
            },
            results: ['23', '15', '32', '4', '34', '21', '22', '8', '18', '36'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3134,
        name: 'Speed Baccarat A',
        game_id: 'evo_ba_speed1',
        provider_id: '0',
        related_id: 'evo_ba_speed1mobile',
        table_id: 'leqhceumaq6qfoug',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Speed Baccarat A',
            tableId: 'leqhceumaq6qfoug',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 990,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bac5_bs_med?size=S',
                    M: '/tablePicture/bac5_bs_med?size=M',
                    L: '/tablePicture/bac5_bs_med?size=L',
                    XL: '/tablePicture/bac5_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bac5_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bac5_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bac5_bs_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bac5_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'P',
                'P',
                'B',
                'P',
                'P',
                'B',
                'T',
                'P',
                'T',
                'B',
                'P',
                'P',
                'P',
                'P',
                'P',
                'B',
                'P',
                'T',
                'T',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3135,
        name: 'Speed Baccarat B',
        game_id: 'evo_ba_speed2',
        provider_id: '0',
        related_id: 'evo_ba_speed2mobile',
        table_id: 'lv2kzclunt2qnxo5',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Speed Baccarat B',
            tableId: 'lv2kzclunt2qnxo5',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 617,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bac6_bs_med?size=S',
                    M: '/tablePicture/bac6_bs_med?size=M',
                    L: '/tablePicture/bac6_bs_med?size=L',
                    XL: '/tablePicture/bac6_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bac6_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bac6_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bac6_bs_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/bac6_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'P',
                'T',
                'B',
                'B',
                'P',
                'B',
                'B',
                'T',
                'P',
                'B',
                'T',
                'B',
                'B',
                'P',
                'B',
                'T',
                'P',
                'B',
                'B',
                'B',
                'P',
                'P',
                'B',
                'P',
                'B',
                'P',
                'B',
                'P',
                'B',
                'T',
                'B',
                'T',
                'B',
                'B',
                'T',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3141,
        name: 'Speed Baccarat C',
        game_id: 'evo_ba_speed3',
        provider_id: '0',
        related_id: 'evo_ba_speed3mobile',
        table_id: 'ndgvwvgthfuaad3q',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Speed Baccarat C',
            tableId: 'ndgvwvgthfuaad3q',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 457,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bact1_bs_med?size=S',
                    M: '/tablePicture/bact1_bs_med?size=M',
                    L: '/tablePicture/bact1_bs_med?size=L',
                    XL: '/tablePicture/bact1_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bact1_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bact1_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bact1_bs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bact1_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'B',
                'T',
                'B',
                'B',
                'B',
                'B',
                'B',
                'B',
                'P',
                'B',
                'P',
                'P',
                'B',
                'B',
                'P',
                'P',
                'P',
                'P',
                'P',
                'B',
                'P',
                'P',
                'B',
                'T',
                'B',
                'B',
                'P',
                'B',
                'B',
                'B',
                'B',
                'P',
                'P',
                'P',
                'B',
                'P',
                'B',
                'B',
                'B',
                'B',
                'P',
                'B',
                'B',
                'P',
                'B',
                'P',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3142,
        name: 'Speed Baccarat D',
        game_id: 'evo_ba_speed5',
        provider_id: '0',
        related_id: 'evo_ba_speed5mobile',
        table_id: 'ndgvz5mlhfuaad6e',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Speed Baccarat D',
            tableId: 'ndgvz5mlhfuaad6e',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 334,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bact2_bs_med?size=S',
                    M: '/tablePicture/bact2_bs_med?size=M',
                    L: '/tablePicture/bact2_bs_med?size=L',
                    XL: '/tablePicture/bact2_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bact2_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bact2_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bact2_bs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bact2_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'B',
                'B',
                'P',
                'T',
                'P',
                'P',
                'B',
                'B',
                'P',
                'T',
                'B',
                'B',
                'B',
                'B',
                'P',
                'P',
                'P',
                'P',
                'B',
                'P',
                'B',
                'P',
                'P',
                'B',
                'P',
                'P',
                'P',
                'P',
                'T',
                'P',
                'P',
                'P',
                'B',
                'B',
                'B',
                'P',
                'P',
                'P',
                'T',
                'P',
                'P',
                'T',
                'B',
                'B',
                'P',
                'T',
                'B',
                'P',
                'P',
                'B',
                'B',
                'P',
                'B',
                'B',
                'B',
                'P',
                'B',
                'P',
                'T',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3143,
        name: 'Speed Baccarat E',
        game_id: 'evo_ba_speed6',
        provider_id: '0',
        related_id: 'evo_ba_speed6mobile',
        table_id: 'ndgv45bghfuaaebf',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Speed Baccarat E',
            tableId: 'ndgv45bghfuaaebf',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 385,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bact3_bs_med?size=S',
                    M: '/tablePicture/bact3_bs_med?size=M',
                    L: '/tablePicture/bact3_bs_med?size=L',
                    XL: '/tablePicture/bact3_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bact3_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bact3_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bact3_bs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bact3_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: ['P', 'P', 'P', 'B', 'B', 'P', 'P', 'B', 'P'],
            privateTableConfig: null,
        },
    },
    {
        id: 3144,
        name: 'Speed Baccarat F',
        game_id: 'evo_ba_speed7',
        provider_id: '0',
        related_id: 'evo_ba_speed7mobile',
        table_id: 'nmwde3fd7hvqhq43',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Speed Baccarat F',
            tableId: 'nmwde3fd7hvqhq43',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 262,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bact6_bs_med?size=S',
                    M: '/tablePicture/bact6_bs_med?size=M',
                    L: '/tablePicture/bact6_bs_med?size=L',
                    XL: '/tablePicture/bact6_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bact6_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bact6_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bact6_bs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bact6_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: ['P', 'P', 'P', 'B', 'P', 'T', 'B'],
            privateTableConfig: null,
        },
    },
    {
        id: 3145,
        name: 'Speed Baccarat G',
        game_id: 'evo_ba_speed8',
        provider_id: '0',
        related_id: 'evo_ba_speed8mobile',
        table_id: 'nmwdzhbg7hvqh6a7',
        group_id: 'baccarat',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '98.94%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Speed Baccarat G',
            tableId: 'nmwdzhbg7hvqh6a7',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 384,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bact7_bs_med?size=S',
                    M: '/tablePicture/bact7_bs_med?size=M',
                    L: '/tablePicture/bact7_bs_med?size=L',
                    XL: '/tablePicture/bact7_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bact7_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bact7_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bact7_bs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bact7_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'P',
                'B',
                'B',
                'T',
                'P',
                'T',
                'P',
                'P',
                'P',
                'P',
                'B',
                'P',
                'P',
                'B',
                'P',
                'B',
                'B',
                'P',
                'B',
                'B',
                'B',
                'B',
                'P',
                'T',
                'B',
                'B',
                'P',
                'B',
                'P',
                'T',
                'B',
                'P',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 10132,
        name: 'Speed Baccarat N',
        game_id: 'speed_baccarat_n',
        provider_id: 'ovu5eja74ccmyoiq',
        related_id: 'speed_baccarat_nmobile',
        table_id: 'ovu5eja74ccmyoiq',
        group_id: '0',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'baccarat',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: '',
        bonus: 'Nej',
        info: '',
        min_stake: '',
        payback: '',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: '',
        paylines: '',
        clicks: 0,
        release_date: '2022-02-09 12:51:19',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'baccarat'],
        liveCas: {
            name: 'Speed Baccarat N',
            tableId: 'ovu5eja74ccmyoiq',
            gameSubType: null,
            gameType: 'Baccarat',
            language: 'en',
            open: true,
            players: 292,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bact15_bs_med?size=S',
                    M: '/tablePicture/bact15_bs_med?size=M',
                    L: '/tablePicture/bact15_bs_med?size=L',
                    XL: '/tablePicture/bact15_bs_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bact15_bs_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bact15_bs_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bact15_bs_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bact15_bs_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 50000,
            },
            results: null,
            history: [
                'T',
                'P',
                'B',
                'B',
                'B',
                'P',
                'P',
                'T',
                'P',
                'P',
                'B',
                'B',
                'T',
                'B',
                'B',
                'B',
                'P',
                'P',
                'P',
                'B',
                'P',
                'T',
                'B',
                'P',
                'B',
                'B',
                'P',
                'P',
                'B',
                'P',
                'B',
                'B',
                'B',
                'B',
                'P',
                'B',
                'T',
            ],
            privateTableConfig: null,
        },
    },
    {
        id: 3155,
        name: 'Speed Blackjack D',
        game_id: 'evo_bj_d',
        provider_id: '0',
        related_id: 'evo_bj_dmobile',
        table_id: 'xqyb2u7fqkexxpa0',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '200',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Speed Blackjack D',
            tableId: 'xqyb2u7fqkexxpa0',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 14,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spdt31_med?size=S',
                    M: '/tablePicture/bj_spdt31_med?size=M',
                    L: '/tablePicture/bj_spdt31_med?size=L',
                    XL: '/tablePicture/bj_spdt31_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spdt31_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spdt31_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spdt31_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_spdt31_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 200,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3165,
        name: 'Speed Blackjack G',
        game_id: 'evo_bj_g',
        provider_id: '0',
        related_id: 'evo_bj_gmobile',
        table_id: '1xwfnktjybsolkn6',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '250',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Speed Blackjack G',
            tableId: '1xwfnktjybsolkn6',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 17,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spdt33_med?size=S',
                    M: '/tablePicture/bj_spdt33_med?size=M',
                    L: '/tablePicture/bj_spdt33_med?size=L',
                    XL: '/tablePicture/bj_spdt33_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spdt33_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spdt33_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spdt33_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_spdt33_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 250,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3166,
        name: 'Speed Blackjack H',
        game_id: 'evo_bj_h',
        provider_id: '0',
        related_id: 'evo_bj_hmobile',
        table_id: 'nc3u2l6y0khszjv7',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '250',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Speed Blackjack H',
            tableId: 'nc3u2l6y0khszjv7',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 13,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spdt34_med?size=S',
                    M: '/tablePicture/bj_spdt34_med?size=M',
                    L: '/tablePicture/bj_spdt34_med?size=L',
                    XL: '/tablePicture/bj_spdt34_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spdt34_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spdt34_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spdt34_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_spdt34_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 250,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3167,
        name: 'Speed Blackjack I',
        game_id: 'evo_bj_i',
        provider_id: '0',
        related_id: 'evo_bj_imobile',
        table_id: 'xstnlyzrm345ev95',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '350',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Speed Blackjack I',
            tableId: 'xstnlyzrm345ev95',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 15,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spdt35_med?size=S',
                    M: '/tablePicture/bj_spdt35_med?size=M',
                    L: '/tablePicture/bj_spdt35_med?size=L',
                    XL: '/tablePicture/bj_spdt35_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spdt35_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spdt35_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spdt35_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_spdt35_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 350,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3168,
        name: 'Speed Blackjack J',
        game_id: 'evo_bj_j',
        provider_id: '0',
        related_id: 'evo_bj_jmobile',
        table_id: 'i5j1cyqhrypkih23',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '350',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Speed Blackjack J',
            tableId: 'i5j1cyqhrypkih23',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 16,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spdt36_med?size=S',
                    M: '/tablePicture/bj_spdt36_med?size=M',
                    L: '/tablePicture/bj_spdt36_med?size=L',
                    XL: '/tablePicture/bj_spdt36_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spdt36_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spdt36_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spdt36_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_spdt36_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 350,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3162,
        name: 'Speed Blackjack K',
        game_id: 'evo_bj_white1',
        provider_id: '0',
        related_id: 'evo_bj_white1mobile',
        table_id: 'k4r2ejwx4eqqb6tv',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '100',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Speed Blackjack K',
            tableId: 'k4r2ejwx4eqqb6tv',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 19,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spdt37_med?size=S',
                    M: '/tablePicture/bj_spdt37_med?size=M',
                    L: '/tablePicture/bj_spdt37_med?size=L',
                    XL: '/tablePicture/bj_spdt37_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spdt37_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spdt37_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spdt37_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_spdt37_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 100,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3163,
        name: 'Speed Blackjack L',
        game_id: 'evo_bj_white2',
        provider_id: '0',
        related_id: 'evo_bj_white2mobile',
        table_id: 'k4r2hyhw4eqqb6us',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '150',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Speed Blackjack L',
            tableId: 'k4r2hyhw4eqqb6us',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 13,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spdt38_med?size=S',
                    M: '/tablePicture/bj_spdt38_med?size=M',
                    L: '/tablePicture/bj_spdt38_med?size=L',
                    XL: '/tablePicture/bj_spdt38_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spdt38_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spdt38_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spdt38_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_spdt38_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 150,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3164,
        name: 'Speed Blackjack M',
        game_id: 'evo_bj_white3',
        provider_id: '0',
        related_id: 'evo_bj_white3mobile',
        table_id: 'k4r2kvd34eqqb6vh',
        group_id: 'blackjack',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'blackjack',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '200',
        payback: '99.29%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'blackJack'],
        liveCas: {
            name: 'Speed Blackjack M',
            tableId: 'k4r2kvd34eqqb6vh',
            gameSubType: null,
            gameType: 'Blackjack',
            language: 'en',
            open: true,
            players: 14,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: 7,
            seatsTaken: [4, 5, 0, 2, 3, 6, 1],
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/bj_spdt39_med?size=S',
                    M: '/tablePicture/bj_spdt39_med?size=M',
                    L: '/tablePicture/bj_spdt39_med?size=L',
                    XL: '/tablePicture/bj_spdt39_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/bj_spdt39_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/bj_spdt39_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/bj_spdt39_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/bj_spdt39_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 200,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3192,
        name: 'Speed Roulette',
        game_id: 'evo_ro_speed',
        provider_id: '0',
        related_id: 'evo_ro_speedmobile',
        table_id: 'lkcbrbdckjxajdol',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '5',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'Speed Roulette',
            tableId: 'lkcbrbdckjxajdol',
            gameSubType: null,
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 211,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/spdk1_imr_med?size=S',
                    M: '/tablePicture/spdk1_imr_med?size=M',
                    L: '/tablePicture/spdk1_imr_med?size=L',
                    XL: '/tablePicture/spdk1_imr_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/spdk1_imr_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/spdk1_imr_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/spdk1_imr_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/spdk1_imr_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 5,
                max: 20000,
            },
            results: ['10', '25', '13', '24', '4', '9', '25', '24', '6', '11'],
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3209,
        name: "Texas Hold'em Bonus Poker",
        game_id: 'evo_po_texasholdembonus',
        provider_id: '0',
        related_id: 'evo_po_texasholdembonusmobile',
        table_id: 'THBTable00000001',
        group_id: 'holdem',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'poker',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '5',
        payback: '99.47%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'poker'],
        liveCas: {
            name: "Texas Hold'em Bonus Poker",
            tableId: 'THBTable00000001',
            gameSubType: null,
            gameType: 'THB',
            language: 'en',
            open: true,
            players: 53,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/pk_bth1_med?size=S',
                    M: '/tablePicture/pk_bth1_med?size=M',
                    L: '/tablePicture/pk_bth1_med?size=L',
                    XL: '/tablePicture/pk_bth1_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/pk_bth1_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/pk_bth1_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/pk_bth1_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/pk_bth1_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 5,
                max: 5000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3203,
        name: 'Three Card Poker',
        game_id: 'evo_po_threecardpoker',
        provider_id: '0',
        related_id: 'evo_po_threecardpokermobile',
        table_id: 'n5emwq5c5dwepwam',
        group_id: 'holdem',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'poker',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '10',
        payback: '96.63%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'poker'],
        liveCas: {
            name: 'Three Card Poker',
            tableId: 'n5emwq5c5dwepwam',
            gameSubType: null,
            gameType: 'TCP',
            language: 'en',
            open: true,
            players: 23,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/pk_3card_med?size=S',
                    M: '/tablePicture/pk_3card_med?size=M',
                    L: '/tablePicture/pk_3card_med?size=L',
                    XL: '/tablePicture/pk_3card_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/pk_3card_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/pk_3card_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/pk_3card_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/pk_3card_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 10000,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3208,
        name: "Ultimate Texas Hold'em",
        game_id: 'evo_po_ultimatetexasholdem1',
        provider_id: '0',
        related_id: 'evo_po_ultimatetexasholdem1mobile',
        table_id: 'UTHTable00000001',
        group_id: 'holdem',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'poker',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '5',
        payback: '99.47%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: true,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'poker'],
        liveCas: {
            name: "Ultimate Texas Hold'em",
            tableId: 'UTHTable00000001',
            gameSubType: null,
            gameType: 'UTH',
            language: 'en',
            open: true,
            players: 52,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/pk_uth1_med?size=S',
                    M: '/tablePicture/pk_uth1_med?size=M',
                    L: '/tablePicture/pk_uth1_med?size=L',
                    XL: '/tablePicture/pk_uth1_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/pk_uth1_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/pk_uth1_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/pk_uth1_med_L.jpg',
                    XL: 'https://bshots.egcvi.com/thumbnail/pk_uth1_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 5,
                max: 2500,
            },
            results: null,
            history: null,
            privateTableConfig: null,
        },
    },
    {
        id: 3188,
        name: 'VIP Roulette',
        game_id: 'evo_ro_vip',
        provider_id: '0',
        related_id: 'evo_ro_vipmobile',
        table_id: 'wzg6kdkad1oe7m5k',
        group_id: 'roulette',
        real_id: '',
        alt: 0,
        wide: true,
        platform: 'web',
        category: 'liveint',
        subcat: 'roulette',
        feature: '',
        provider: 'evolution',
        subprovider: 'evolution',
        video: false,
        video_url: null,
        images: {
            thumb: null,
            svg: null,
            vertical: null,
            bg: null,
        },
        type: 'Live Casino',
        bonus: 'Nej',
        info: '',
        min_stake: '20',
        payback: '97.30%',
        bonusrunder: 'Nej',
        spinsrunder: 'Nej',
        tag_hot: false,
        tag_new: false,
        rod_hot: false,
        rod_new: false,
        features: [''],
        jackpot: 'Nej',
        jackpot_amount: '',
        jackpot_amount_formatted: null,
        priority: 9999,
        rod_hide: false,
        exclusive: false,
        theme: 'bordspil',
        paylines: '',
        clicks: 0,
        release_date: '0000-00-00 00:00:00',
        casinoPageCategories: [],
        liveCasinoPageCategories: ['all', 'roulette'],
        liveCas: {
            name: 'VIP Roulette',
            tableId: 'wzg6kdkad1oe7m5k',
            gameSubType: 'french',
            gameType: 'Roulette',
            language: 'en',
            open: true,
            players: 195,
            operationHours: 'FullTime',
            display: 'on_desktop',
            seats: null,
            seatsTaken: null,
            workHours: {
                type: 'FullTime',
            },
            thumbs: {
                links: {
                    S: '/tablePicture/vipk1_imr_med?size=S',
                    M: '/tablePicture/vipk1_imr_med?size=M',
                    L: '/tablePicture/vipk1_imr_med?size=L',
                    XL: '/tablePicture/vipk1_imr_med?size=XL',
                },
                thumbnails: {
                    S: 'https://bshots.egcvi.com/thumbnail/vipk1_imr_med_S.jpg',
                    M: 'https://bshots.egcvi.com/thumbnail/vipk1_imr_med_M.jpg',
                    L: 'https://bshots.egcvi.com/thumbnail/vipk1_imr_med_L.jpg',
                    XL:
                        'https://bshots.egcvi.com/thumbnail/vipk1_imr_med_XL.jpg',
                },
            },
            betLimits: {
                symbol: 'kr.',
                min: 10,
                max: 20000,
            },
            results: ['29', '0', '4', '6', '19', '11', '17', '1', '4', '20'],
            history: null,
            privateTableConfig: null,
        },
    },
]
