import { post, get } from './RequestHandler'
import { PAYMENT_SERVICE_ID, RESET_API_VERSION } from 'configs/main'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'

let chanelLogin = detectIpad() ? 0 : detectMobile() ? 8 : 0
const cacheDate = new Date().getTime()

export default () => ({
    getUser: () => get('getAccountInformation'),

    login: (userName, password) =>
        post('channelLogin', {
            userName,
            password,
            channel: chanelLogin,
            version: RESET_API_VERSION,
        }),

    nemIdLogin: (result, content) =>
        post('nemid_login', { result, signature: content }),

    isUserLoggedIn: () => get('isLoggedIn?t=' + cacheDate),

    logout: () => post('logout'),

    getDepositLimits: () => get('getDepositLimits'),

    setDepositLimits: (limits) => post('setDepositLimits', limits),

    getDepositBonuses: () => get(`getBonusListChannel/${PAYMENT_SERVICE_ID}`),

    getPayout: () => get('getWithdrawMaxAmountEx'),

    withdraw: (amount) => post('withdrawalRequestNem', { amount, text: 'rod' }),

    setPin: (pin) => post('createHash', { pin }),

    pinLogin: (pin, hash, userName) =>
        post('loginPin', { pin, hash, userName }),

    fetchHash: () => get('fetchHash'),

    clearHash: () => post('clearHash'),

    enableNewsletterSubscription: () =>
        post('newsletterSubscription', { on: 1 }),

    disableNewsletterSubscription: (status, email) =>
        post('unsubscribeFromNewsletter', { on: status, email }),

    signup: {
        step1: (cpr) => post('signup_step1', { cpr }),
        step2: (result, signature) =>
            post('signup_step2', { result, signature }),
        step34: (UserData) => post('signup_step3', { ...UserData }),
    },

    resetPassword: (username, email) =>
        post('resetPassword', { userName: username, email }),

    getNemidParameters: () =>
        get('getNemidParameters', { mode: 'lmt', language: 'da' }),

    getSecurityQuestionList: () => get('getSecurityQuestionList'),

    getExpectedBetting: () => get('getExpectedBetting'),

    expectedBetting: (expectBetting) =>
        post('setExpectedBetting', { expectBetting }),

    getUserStatus: () => get('getUserStatus'),

    getProfile: () => get('getAccountInformation?t=' + cacheDate),

    updateProfile: (profile) => post('setAccountInformation', profile),

    updatePassword: (currentPassword, newPassword, passwordConfirm) =>
        post('changePassword', {
            current: currentPassword,
            edit: newPassword,
            confirm: passwordConfirm,
        }),

    excludeUserPermanently: () => post('excludeUserPermanently'),

    excludeUserByPeriod: (exclusionPeriod) =>
        post('excludeUser', { exclusionPeriod }),

    getTransactionGroupList: (group, dateFrom, dateTo, offset, rowCount) =>
        get(
            `getTransactionGroupList/${group}/${dateFrom}/${dateTo}/${offset}/${rowCount}`
        ),

    getTransactionList: (dateFrom, dateTo) =>
        get('getTransactionList/' + dateFrom + '/' + dateTo),

    getTransactionExternalList: (dateFrom, dateTo) =>
        get('getTransactionExternalList/CA/' + dateFrom + '/' + dateTo),

    addTeam: (code, note) => post('addTeam', { code, note }),

    depositVoucher: (serial, code) => post('depositVoucher', { serial, code }),

    register: () => post('register'),
})
