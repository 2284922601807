import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import OperationTypeSwitcher from 'features/Deposit/Components/OperationTypeSwitcher'
import userService from 'services/UserService'
import BonusService from 'services/BonusService'
import Loader from 'ui/Loader'
import { Withdraw as WithdrawComponent } from '@it25syv/25syv-ui'
import UserAdapter from '../../common/adapters/UserAdapter'
import { modalService } from '../../services/ModalService'
import NotificationConductor from '../../common/conductors/NotificationConductor'
import { useHistory } from 'react-router-dom'

const t = window.T

const ModalWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const Withdraw = ({ theme }) => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState({})
    const [bonuses, setBonuses] = useState([])
    const [relatedGameId, setRelatedGameId] = useState('')

    const transferMoneyHandler = (amount) => {
        setLoading(true)
        UserAdapter.withdraw(amount)
            .then((res) => {
                if (res.success) {
                    userService.setIsUserLoggedIn()
                    modalService.showModal('WITHDRAW_NOTIFICATION_POPUP', {
                        amount,
                    })
                } else {
                    NotificationConductor.error('Error: ' + res.errorMessage)
                }
                setLoading(false)
            })
            .catch((error) => {
                NotificationConductor.error(error.errorMessage)
                setLoading(false)
            })
    }

    const getRedirectUrl = () => {
        modalService.closeModal()

        if (relatedGameId) {
            history.push('/game/' + relatedGameId)
        } else {
            history.push('/automater/alle')
        }
    }

    useEffect(() => {
        BonusService.setActiveBonusList()
        userService.checkUserLogin()
        const userSubscription = userService.getUser().subscribe((data) => {
            setLoading(false)
            setUserData(data)
        })

        const activeBonusSubscription = BonusService.getActiveBonusList().subscribe(
            (data) => {
                setBonuses(data)

                if (data.length) {
                    data.find((bonusGame) => {
                        if (bonusGame.bonusType === 'W') {
                            setRelatedGameId(bonusGame.gameId)
                        }
                    })
                }
            }
        )

        return () => {
            userSubscription.unsubscribe()
            activeBonusSubscription.unsubscribe()
            userService.setIsUserLoggedIn()
        }
    }, [])

    return (
        <ModalWrapper>
            <ModalTopBar
                background={theme.primary}
                back
                color={theme.light}
                borderBottom={'none'}
            >
                <Text size={'md'} color={theme.light} my={2}>
                    {t.userArea.withdraw.title}
                </Text>
            </ModalTopBar>
            <Flex background={theme.primary} pb={3} px={3}>
                <OperationTypeSwitcher active={'WITHDRAW'} />
            </Flex>

            <WithdrawComponent
                product={'casino'}
                userData={userData}
                bonusesData={bonuses}
                onRemove={(bonusAmount) => {
                    modalService.showModal('REMOVE_BONUS_CONFIRM', {
                        bonusAmount,
                    })
                }}
                transferMoneyHandler={(amount) => {
                    transferMoneyHandler(amount)
                }}
                redirectUrl={getRedirectUrl}
                payoutPending={loading}
            />
        </ModalWrapper>
    )
}

export default Withdraw
