import React from 'react'
import styled from 'styled-components'
import LiveCasinoCard from './LiveCasinoCard'
import { byMedia } from 'common/adapters/DeviceDetectAdapter'
import useLiveCasinoData from './useLiveCasinoData'
import SliderControls from 'features/GamesSlider/SliderControls'

const LiveCasinoSlider = styled.div`
    position: relative;
    display: none;
    margin-top: 60px;

    @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.lg + 'px'}) {
        display: block;
    }
`
const StyledCategoryTitle = styled.h3`
    font-size: 32px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    color: #111111;

    @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.sm + 'px'}) {
        font-size: 20px;
        color: white;
    }
`
const SliderWrapper = styled.div`
    margin-top: 20px;
`

const LiveCasinoCardsSlider = ({ games }) => {
    const { categorizedGames } = useLiveCasinoData(games)

    return (
        <LiveCasinoSlider>
            <StyledCategoryTitle>Spiltyper</StyledCategoryTitle>
            <SliderWrapper>
                <SliderControls
                    slidesCount={categorizedGames.length}
                    onPage={byMedia({
                        sm: 1.5,
                        md: 2.2,
                        lg: 3.2,
                    })}
                >
                    {categorizedGames.map((slide, index) => (
                        <LiveCasinoCard game={slide} key={index} />
                    ))}
                </SliderControls>
            </SliderWrapper>
        </LiveCasinoSlider>
    )
}

export default LiveCasinoCardsSlider
