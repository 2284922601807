import React from 'react'
import styled from 'styled-components'
import Flex from 'ui/Flex'
import Text from 'ui/Text'
import Box from 'ui/Box'
import Button from 'ui/controls/Button'
import gift from 'images/icons/basic-icons/aсcount-area/gift.svg'
import infoIcon from 'images/icons/basic-icons/aсcount-area/info.svg'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import { modalService } from 'services/ModalService'
import { setBonusList } from 'services/BonusService'
import UserService from 'services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { forfeitBonus } from 'common/adapters/ProviderAdapter'
import BonusService from '../../../services/BonusService'

const t = window.T

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50%;
    background: #fff;
    border-radius: 20px 20px 0 0;
    position: absolute;
    bottom: 0;
`
const Title = styled.h3`
    text-align: center;
    font-size: 1.5rem;
    padding: 20px 0;
    margin: 0;
    color: ${(props) => props.color};
`
const Line = styled.hr`
    background: #33333380;
    width: 100%;
    height: 1px;
    opacity: 0.1;
    margin: 0;
`

const BonusPopup = ({ data = {} }) => {
    const props = data.props

    const takeBonuses = () => {
        if (props.bonuses.totalBonus > 0) {
            forfeitBonus()
                .then((res) => {
                    if (res && res.success) {
                        NotificationConductor.success(
                            'Din bonussaldo er nulstillet'
                        )

                        BonusService.removeActiveBonus()
                        UserService.setIsUserLoggedIn()
                        closePopup()
                    } else {
                        NotificationConductor.error('Something went wrong')
                        closePopup()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    NotificationConductor.error(error.toString())
                })
        }
    }

    const closePopup = () => {
        modalService.closeModal('BONUS_POPUP')
    }

    return (
        <Wrapper>
            <Title color={props.theme.dark}>Nulstil bonussaldo?</Title>
            <Line />
            <Box p={3}>
                <ProfileCard
                    iconSrc={gift}
                    title={'Bonussaldo'}
                    background={props.theme.light}
                    borderRadiusTop
                >
                    <Text
                        size={'lg'}
                        align={'right'}
                        color={props.theme.dark}
                        style={{ whiteSpace: 'nowrap' }}
                        bold
                        mr={2}
                        strike
                    >
                        {props.totalBonus}
                    </Text>
                    <Text size={'lg'} color={props.theme.dark}>
                        {t.currency}
                    </Text>
                </ProfileCard>
                <ProfileCard
                    borderRadiusBottom
                    iconSrc={infoIcon}
                    background={props.theme.light}
                    justify={'flex-start'}
                >
                    <Text
                        color={props.theme.dark}
                        align={'left'}
                        opacity={'.8'}
                    >
                        Nulstilling kan ikke fortrydes.
                    </Text>
                </ProfileCard>
                <Flex width={'100%'} mt={5}>
                    <Button
                        size={'lgB'}
                        onClick={() => closePopup()}
                        bold
                        color={props.theme.dark}
                        style={{ flex: '50%', background: '#F1F1F1' }}
                    >
                        Annuller
                    </Button>
                </Flex>
                <Flex width={'100%'} mt={2}>
                    <Button
                        size={'lgB'}
                        variant={'danger'}
                        style={{ flex: '50%' }}
                        onClick={() => takeBonuses()}
                    >
                        Bekræft
                    </Button>
                </Flex>
            </Box>
        </Wrapper>
    )
}

export default BonusPopup
