import React, { useEffect, useState } from 'react'
import DKRouter from '../routers/dk-router'
import styled, { css } from 'styled-components'
import ContentWrapper from './sections/ContentWrapper'
import NavigationMenu from './sections/NavigationMenu'
import MainNavigation from './sections/MainNavigation'
import LeftSidebarContainer from './sections/LeftSidebar'
import Modals from '@/layouts/sections/Modals'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import gamesService from 'services/GamesService'
import GamesService from 'services/GamesService'
import userService from 'services/UserService'
import { ProductsTransitionBar } from '@it25syv/25syv-ui'
import UserAdapter from '@/common/adapters/UserAdapter'
import rofus from 'icons/basic-icons/rofus.svg'
import betImg from 'images/topbar/bet.png'
import rodImg from 'images/topbar/rod.png'
import derbyImg from 'images/topbar/derby.png'
import { PALETTE } from '@/themes'

import withUser from 'common/Hocs/WithUser'
import { modalService } from 'services/ModalService'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import SideNavigation from 'features/SideNavigation/SideNavigation'
import StatesService from 'services/StatesService'
import Footer from '@/layouts/sections/Footer/Footer'
import SideNavigationContent from 'features/SideNavigation/SideNavigationContent'
import { getGeoPosition } from 'services/GeoPositionService'
import UrlHistoryService from 'services/UrlHistoryService'
import MetaTags from 'react-meta-tags'
import contentService from 'services/ContentService'
import { getFullHeaderHeight, getTopBarHeight } from 'configs/layout'
import BurgerIcon from 'ui/Burger'
import { useDebounceFunc } from 'common/Hooks/Debounce'
import { byEnv, getEnv } from 'utils/env'
import { BottomMenu } from '@it25syv/bottom-menu'
import bottomMenuConfig from 'configs/bottom-menu'
import ReactTooltip from 'react-tooltip'
import GamesUtilsService from 'services/GamesUtilsService'
import { useHistory, useLocation } from 'react-router-dom'
import UserService from 'services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import GameJackpotService from 'services/GameJackpotService'
import { isAndroid } from 'react-device-detect'
import { getCookie } from 'services/CookieService'
import COLORS from '../themes/primary/palette'
import LastLoginPopup from 'features/Login/LastLoginPopup'

const { setSEOData, getSEOData } = contentService

const OPEN_SIDEBAR_WIDTH = 15
const CLOSE_SIDEBAR_WIDTH = 4.5
const HIDDEN_SIDEBAR_WIDTH = 0

const TopBarWrapper = styled.div`
    position: fixed;
    left: auto;
    top: 0;
    right: 0;
    width: ${(props) =>
        `calc(100% - ${
            !detectMobile()
                ? props.isOpen && !props.hideBurger
                    ? OPEN_SIDEBAR_WIDTH + 0.1
                    : props.hide
                        ? HIDDEN_SIDEBAR_WIDTH
                        : CLOSE_SIDEBAR_WIDTH + 0.1
                : 0
        }rem)`};
    z-index: 111;
    transition: all 0.2s ease-in;

    &:hover {
        z-index: 112;
    }
`

const ContentColumn = styled.div`
    transition: all 0.2s ease-in;
    top: ${({ top }) => top}px;
    position: relative;
    padding-left: ${(props) =>
        detectMobile()
            ? 0
            : props.isOpen
                ? OPEN_SIDEBAR_WIDTH
                : props.hide
                    ? HIDDEN_SIDEBAR_WIDTH
                    : CLOSE_SIDEBAR_WIDTH}rem;
`

const SideNavCommon = css`
    transition: all 0.2s ease-in;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: ${detectMobile() ? 'none' : 'block'};
`

const MainContent = styled.div`
    flex: 1 0 auto;
    min-height: 400px;
    background: ${COLORS.neutral};
`

const ScrollWrapper = styled.div`
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    z-index: 107;
    background: ${(props) => props.theme.primary};
    width: ${(props) =>
        props.isOpen
            ? OPEN_SIDEBAR_WIDTH + 0.1
            : props.hide
                ? HIDDEN_SIDEBAR_WIDTH
                : CLOSE_SIDEBAR_WIDTH + 0.1}rem;
    ${SideNavCommon};
`

const StyledSideNavigation = styled(SideNavigation)`
    width: ${(props) =>
        props.isOpen
            ? OPEN_SIDEBAR_WIDTH
            : props.hide
                ? HIDDEN_SIDEBAR_WIDTH
                : CLOSE_SIDEBAR_WIDTH}rem;
    ${SideNavCommon};

    .tooltip {
        opacity: 1 !important;
        font-size: 14px;
        font-weight: bold;
        color: #505050;
        transition: all 0.2s ease;
        border: 1px solid #70707033 !important;
        @media screen and (max-width: 700px) {
            display: none !important;
        }
    }
`

const TopNavigationWrapper = styled.div`
    position: fixed;
    right: 0;
    width: ${(props) =>
        `calc(100% - ${
            !detectMobile()
                ? props.isOpen
                    ? OPEN_SIDEBAR_WIDTH + 0.1
                    : props.isGamePage
                        ? HIDDEN_SIDEBAR_WIDTH
                        : CLOSE_SIDEBAR_WIDTH + 0.1
                : 0
        }rem)`};
    top: ${({ top }) => top}px;
    z-index: 105;
    overflow-x: hidden;
    background-color: ${PALETTE.primary};
    transition: all 0.2s ease-in;
`

const BottomBarWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;

    ${!detectMobile() &&
        css`
            display: none;
        `};
`

const StyledProductsTransitionBar = styled(ProductsTransitionBar)`
    & span {
        font-size: 0.5rem;
    }

    @media (min-width: 1219px) {
        & span {
            font-size: 0.55rem;
        }
    }

    @media (min-width: 1290px) {
        & span {
            font-size: 0.67rem;
        }
    }

    @media (min-width: 1430px) {
        & span {
            font-size: 0.8rem;
        }
    }
`

const Layout = ({ userProfile = {}, profileLoading = true, theme = {} }) => {
    const [leftSidebarStatus, setLeftSidebarStatusState] = useState(false)
    const [sideNavOpen, setSideNavOpen] = useState(
        StatesService.initialNavState()
    )
    const [geoData, setGeoData] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [isGamePage, setIsGamePage] = useState(false)
    const [hideBurger, setHideBurger] = useState(false)
    const [productBarHeight, setProductBarHeight] = useState(getTopBarHeight())
    const [topBarHeight, setTopBarHeight] = useState(getFullHeaderHeight())
    const [activeMenuItem, setActiveMenuItem] = useState('')
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const location = useLocation()
    const history = useHistory()

    const detectLandscape = () =>
        detectMobile() && !detectIpad() && window.orientation !== 0

    const orientationChange = useDebounceFunc(() => {
        modalService[detectLandscape() ? 'showModal' : 'closeModal'](
            'LOCK_ORIENTATION',
            {
                initialProps: { modalTopMargin: '0' },
                styles: { background: 'rgba(10,10,10,0.7)' },
            }
        )

        setProductBarHeight(getTopBarHeight())
        setTopBarHeight(getFullHeaderHeight())
    }, 100)

    const setBackgroundPosition = (status) => {
        setHideBurger(status)
        status
            ? (window.document.body.style.overflow = 'hidden')
            : (window.document.body.style.overflow = 'inherit')
    }

    const setLeftSidebarStatus = (status) => {
        setBackgroundPosition(status)
        setLeftSidebarStatusState(status)
    }

    const getLastLoginTime = (info) => {
        return info.extraInfo && info.extraInfo.loginTime
    }

    const beforeInstallPromptHandler = (event) => {
        window.deferredPrompt = event
        if (isAndroid) {
            event.preventDefault()
            if (
                !(
                    window.matchMedia('(display-mode: standalone)').matches ||
                    window.navigator.standalone === true
                ) &&
                !getCookie('hidePWAPrompt')
            ) {
                modalService.showModal('INSTALL_PWA_MODAL', {
                    deferredPrompt: event,
                    initialProps: {
                        modalTopMargin: '0',
                    },
                })
            } else {
                return false
            }
        }

        window.deferredPrompt.userChoice.then((choiceResult) => {
            const userId = localStorage.getItem('userId')
            if (choiceResult.outcome === 'accepted') {
                window.dataLayer.push({
                    event: 'red25.a2hs.accepted',
                    userId,
                })
            } else {
                window.dataLayer.push({
                    event: 'red25.a2hs.dismissed',
                    userId,
                })
            }
            window.deferredPrompt = null
        })
    }

    const dataLayerTracking = () => {
        const userId = localStorage.getItem('userId')

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'page_view',
                type: 'casino',
                eventUrl: location.pathname,
                userId,
            })
        }
    }

    const appInstalledHandler = () => {
        const userId = localStorage.getItem('userId')
        window.dataLayer.push({
            event: 'red25.a2hs.installed',
            userId,
        })
    }

    useEffect(
        () => {
            setSEOData(history.location.pathname)

            const SEODataSub = getSEOData().subscribe((res) => {
                if (res) {
                    setTitle(res.title)
                    setDescription(res.description)
                }
            })

            const urlParts = history.location.pathname
                .split('/')
                .filter((e) => e)

            const [navLink = ''] = urlParts
            setActiveMenuItem('/' + navLink)

            return () => {
                SEODataSub.unsubscribe()
            }
        },
        [history.location]
    )

    useEffect(() => {
        getEnv() === 'dev'
            ? setGeoData({ allowed: true })
            : getGeoPosition().then((res) => {
                  setGeoData(res)
              })
    }, [])

    useEffect(() => {
        dataLayerTracking()
        history.listen((location) => {
            dataLayerTracking()
            window.scrollTo(0, 0)

            UrlHistoryService.setStack(
                history.location.pathname + history.location.search
            )
        })
        gamesService.setLiveCasinoGames()
        gamesService.setGamesList()
        GameJackpotService.setJackpotsInfo()
        GameJackpotService.setJackpotGames()
        gamesService.setGamesByCategories()

        window.addEventListener(
            'beforeinstallprompt',
            beforeInstallPromptHandler
        )
        window.addEventListener('beforeinstallprompt', (event) => {
            console.log('beforeinstallprompt test')
        })

        window.addEventListener('appinstalled', appInstalledHandler)

        // modalService.showModal('INSTALL_PWA_MODAL', {
        //     initialProps: {
        //         modalTopMargin: '0',
        //     },
        // })

        if (detectMobile()) {
            window.addEventListener('pageshow', (event) => {
                if (event.persisted) {
                    userService.checkUserLogin()
                }
            })
        }

        //Check if user logged in
        const interval = setInterval(() => {
            userService.checkUserLogin().then((data) => {
                if (!data.login) {
                    clearInterval(interval)
                }
            })
        }, 30000)

        window.addEventListener('resize', orientationChange)

        const gameServiceSubscription = GamesService.getGameLaunchState().subscribe(
            (gameState) => {
                setIsGamePage(gameState.isInit)
                if (gameState.isInit) {
                    setSideNavOpen(false)
                } else {
                    setSideNavOpen(StatesService.initialNavState())
                }
            }
        )

        const gameFeatures = GamesService.getAllGamesFeatures().subscribe(
            (res) => {
                GamesService.setGamesByCategories(res)
            }
        )

        const sideNavSub = StatesService.getNavOpen().subscribe((value) => {
            setSideNavOpen(value)
        })

        modalService[detectLandscape() ? 'showModal' : 'closeModal'](
            'LOCK_ORIENTATION',
            {
                initialProps: { modalTopMargin: '0' },
                styles: { background: 'rgba(10,10,10,0.7)' },
            }
        )

        const logedInSub = UserService.getUserLoggedStatus().subscribe(
            (res) => {
                setIsUserLoggedIn(res)
            }
        )

        return () => {
            clearInterval(interval)
            gameServiceSubscription.unsubscribe()
            gameFeatures.unsubscribe()
            sideNavSub.unsubscribe()
            logedInSub.unsubscribe()
            window.removeEventListener('resize', orientationChange)
        }
    }, [])

    useEffect(
        () => {
            if (userProfile.email) {
                UserAdapter.getDepositLimits().then((limit) => {
                    if (
                        limit.hasOwnProperty('dailyAmount') &&
                        limit.dailyAmount === 0 &&
                        limit.hasOwnProperty('weeklyAmount') &&
                        limit.weeklyAmount === 0 &&
                        limit.hasOwnProperty('monthlyAmount') &&
                        limit.monthlyAmount === 0 &&
                        limit.hasOwnProperty('dailyAmountChange') &&
                        limit.dailyAmountChange === 0 &&
                        limit.hasOwnProperty('weeklyAmountChange') &&
                        limit.weeklyAmountChange === 0 &&
                        limit.hasOwnProperty('monthlyAmountChange') &&
                        limit.monthlyAmountChange === 0
                    ) {
                        modalService.showModal('FAST_DEPOSIT_LIMIT', {
                            initialProps: {
                                modalTopMargin: '0',
                                initialLimits: limit,
                            },
                            userStatus: userProfile.status,
                        })
                    }
                })
            }
        },

        [userProfile?.balance] // todo fix multiple calls
    )

    useEffect(
        () => {
            if (userProfile && userProfile.userId) {
                GamesUtilsService.fetchFavorite()
            }
        },
        [userProfile.userId]
    )

    useEffect(
        () => {
            orientationChange()
        },
        [window.screen?.orientation?.angle]
    )

    return (
        <ContentWrapper>
            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
            </MetaTags>
            <TopBarWrapper
                id={'full-header'}
                isOpen={sideNavOpen}
                hide={isGamePage || hideBurger}
                hideBurger={hideBurger}
            >
                <StyledProductsTransitionBar
                    px={2}
                    rodLink={`/casino`}
                    derbyLink={`/heste`}
                    betLink={`/sport`}
                    rofusLogo={rofus}
                    lastLoginTime={getLastLoginTime(userProfile)}
                    showTimer={getLastLoginTime(userProfile)}
                    clockFormat={'HH:mm'}
                    timerFormat={'HH:mm'}
                    activeProject={'rod'}
                    background={theme.primary}
                    color={'white'}
                    betImage={betImg}
                    rodImage={rodImg}
                    derbyImage={derbyImg}
                    minimize={isGamePage}
                    logoOnClickHandler={() => history.push('/')}
                />
            </TopBarWrapper>

            <TopNavigationWrapper
                isGamePage={isGamePage}
                isOpen={sideNavOpen}
                top={productBarHeight || getTopBarHeight()}
            >
                <MainNavigation
                    userProfile={userProfile}
                    onBurgerMenuClickHandler={() => setLeftSidebarStatus(true)}
                    profileLoading={profileLoading}
                    geoData={geoData}
                    isGamePage={isGamePage}
                />
            </TopNavigationWrapper>

            <ScrollWrapper isOpen={sideNavOpen} hide={isGamePage}>
                <StyledSideNavigation
                    isOpen={sideNavOpen}
                    hide={isGamePage}
                    background={theme.primary}
                >
                    <BurgerIcon
                        isOpen={sideNavOpen}
                        onClickHandler={() =>
                            StatesService.setNavOpen(!sideNavOpen)
                        }
                    />
                    <SideNavigationContent
                        isOpen={sideNavOpen}
                        userProfile={userProfile}
                    />
                </StyledSideNavigation>
            </ScrollWrapper>

            <ContentColumn
                isOpen={sideNavOpen}
                hide={isGamePage}
                style={{
                    display: 'flex',
                    minHeight: '100vh',
                    flexDirection: 'column',
                }}
                top={topBarHeight || getFullHeaderHeight()}
            >
                <MainContent>
                    <DKRouter userProfile={userProfile} geoData={geoData} />
                </MainContent>

                <Footer
                    isOpen={sideNavOpen}
                    sidebarWidth={OPEN_SIDEBAR_WIDTH}
                    sidebarClosedWidth={CLOSE_SIDEBAR_WIDTH}
                />
            </ContentColumn>

            <LeftSidebarContainer
                onBackgroundClickHandler={() => setLeftSidebarStatus(false)}
                visible={leftSidebarStatus}
            />

            {detectMobile() && (
                <BottomMenu
                    id={'navigation-mobile'}
                    config={bottomMenuConfig(
                        history,
                        activeMenuItem,
                        isUserLoggedIn
                    )}
                />
            )}

            <LastLoginPopup />

            <ToastContainer />
            <Modals
                isModalVisible={(status) => setBackgroundPosition(status)}
            />
            <ReactTooltip id={'game-info'} />
        </ContentWrapper>
    )
}

export default withUser(Layout)
