import React, { useEffect } from 'react'
import { modalService } from 'services/ModalService'
import { RemoveBonusConfirm, SuccessModal } from '@it25syv/25syv-ui'

const UserProfileSuccessPopup = ({ data }) => {
    return (
        <SuccessModal
            closeModal={() => modalService.closeModal()}
            title={`Oplysninger opdateret`}
            text={`Dine kontooplysninger er nu opdateret og gemt.`}
        />
    )
}

export default UserProfileSuccessPopup
