import React, { useState, useEffect } from 'react'
import userService from 'services/UserService'
import { useHistory, useLocation } from 'react-router-dom'

/**
 *
 * @param Component
 * @return {function(*): *}
 */
const WithUser = (Component) => (props) => {
    const [userProfile, setUserProfile] = useState({})
    const [profileLoading, setProfileLoading] = useState(true)
    const [prevLocation, setPrevLocation] = useState('')
    const history = useHistory()

    useEffect(
        () => {
            prevLocation === 'game' && getUserData()
            setPrevLocation(history.location.pathname.split('/')[1])
        },
        [window.location.href]
    )

    useEffect(() => getUserData(), [])

    const getUserData = () => {
        userService.setIsUserLoggedIn()
        userService.setUserProfile()

        const userProfileSubscription = userService
            .getUserProfile()
            .subscribe((data) => {
                setProfileLoading(false)
                setUserProfile(data)
            })
        return () => {
            userProfileSubscription.unsubscribe()
        }
    }

    const customProps = {
        userProfile,
        profileLoading,
    }

    return <Component {...props} {...customProps} />
}

export default WithUser
