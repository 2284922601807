import React, { useEffect, useState } from 'react'
import { modalService } from 'services/ModalService'
import { TransferMoneyModal } from '@it25syv/25syv-ui'
import styled from 'styled-components'

const t = window.T

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const WithdrawNotificationPopup = ({ data }) => {
    const handleCloseModal = () => {
        modalService.closeModal()
    }

    return (
        <Wrapper>
            <TransferMoneyModal
                amount={data.amount}
                closeModal={handleCloseModal}
            />
        </Wrapper>
    )
}

export default WithdrawNotificationPopup
