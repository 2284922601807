import React, { Suspense, useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import styled, { css, withTheme } from 'styled-components'
import Box from 'ui/Box'
import PageContent from 'ui/PageContent'
import PageWrapper from 'ui/PageWrapper'
import WithGames from 'common/Hocs/WithGames'
import { casinoFilters } from 'configs/categories'
import waveIcon from 'images/icons/waving.svg'
import { byPlatform, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { renderLoader } from 'ui/RenderLoader'
import { modalService } from 'services/ModalService'
import { depositAction } from 'services/DepositService'
import GamesService from 'services/GamesService'
import { signupHelper } from 'utils/signupHelper'
import { sortingByHotClicks } from 'utils/sorting'

import HighlightSlider from 'features/Highlight/HighlightSlider'
import COLORS from '../themes/primary/palette'
import GamesSlider from '../features/GamesSlider'
import GamesSliderWithTabs from '../features/GamesSlider/SliderWithTabs'
import GamesUtilsService from '../services/GamesUtilsService'
import JackpotHighlight from 'features/Jackpots/JackpotHighlight'
import LiveCasinoHighlight from 'features/LiveCasino/LiveCasinoHighlight'
import LiveCasinoCardsSlider from 'features/LiveCasino/LiveCasinoCardsSlider'
import { BREAKPOINTS } from '../themes'
import ProviderSlider from 'features/GamesSlider/ProviderSlider'
import { NEW_GAME_LABEL, POPULAR_GAME_LABEL } from '../configs/ui'
import JackpotCardsSlider from 'features/Jackpots/JackpotCardsSlider'
import HelpCenter from 'features/HelpCenter'
import SearchSection from '../features/SearchSection'

const t = window.T

// const HighlightSlider = lazy(() => import('features/Highlight/HighlightSlider'))
// const GameList = lazy(() => import('features/GamesList/GameList'))
// const ProductTransition = lazy(() =>
//     import('@/layouts/sections/Footer/ProductTransition')
// )

const Title = styled.h3`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.dark};
    margin: 20px 0;
    font-size: 32px;

    @media screen and (max-width: ${BREAKPOINTS.sm + 'px'}) {
        font-size: 20px;
    }
`

const TitleIcon = styled.span`
    display: block;
    width: 32px;
    height: 32px;
    background: url(${waveIcon}) no-repeat;
    margin-left: 8px;
`

const SliderSection = styled.div`
    padding: ${(props) =>
        props.padding
            ? props.padding
            : detectMobile()
                ? '40px 0 40px 16px'
                : '40px 0 0'};

    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        padding: 20px 0 20px 16px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm + 'px'}) {
        border-radius: ${(props) => (props.bdRadius ? props.bdRadius : '0')};
        border-top: ${(props) =>
            props.borderTop ? `1px solid ${COLORS.white}` : 'none'};
        border-bottom: ${(props) =>
            props.borderBottom ? `1px solid ${COLORS.white}` : 'none'};
        background: ${(props) => (props.bg ? props.bg : 'none')};
    }
`

const StyledSection = styled(SliderSection)`
    ${() => detectMobile() && 'padding: 40px 16px'};

    @media screen and (max-width: ${BREAKPOINTS.sm + 'px'}) {
        padding-top: 40px;
    }
`

const StyledGamesSliderWrapper = styled(SliderSection)`
    padding-left: 0;
    @media screen and (max-width: ${BREAKPOINTS.lg + 'px'}) {
        padding: 20px 0;
    }

    @media screen and (max-width: ${BREAKPOINTS.sm + 'px'}) {
        ${({ color }) =>
            color &&
            css`
                & h3 {
                    color: ${color};
                }

                & p {
                    color: ${color};
                }
            `};
    }
`

const greetings = t.greetings

const HomePage = ({
    theme,
    searchText = '',
    gamesToMap = [],
    userProfile = {},
    geoData,
}) => {
    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch()
    const isUserLoggedIn = userProfile && userProfile.userId

    const [recommendedGames, setRecommendedGames] = useState([])
    const [jackpotGames, setJackpotGames] = useState([])
    const [liveCasinoGames, setLiveCasinoGames] = useState([])
    const [topPopularGames, setTopPopularGames] = useState([])
    const [popularGames, setPopularGames] = useState([])
    const [newGames, setNewGames] = useState([])
    const [favoriteGames, setFavoriteGames] = useState([])

    const TOP_GAMES_LIMIT = 5
    const POPULAR_GAMES_LIMIT = 100 //for correct calculation length

    const mapLiveCasinoGames = (casinoGames) => {
        if (casinoGames && casinoGames.length) {
            const games = casinoGames
                .filter((g) => g.liveCas)
                .sort(
                    (a, b) =>
                        Number(b.tag_hot) - Number(a.tag_hot) ||
                        parseFloat(b.clicks || 0) - parseFloat(a.clicks || 0) ||
                        a.name.localeCompare(b.name)
                )
                .sort(
                    (a, b) =>
                        b.tag_hot && a.tag_hot
                            ? parseInt(a.priority) - parseInt(b.priority)
                            : 0
                )
            return games
        }
    }

    useEffect(() => {
        const liveGamesSub = GamesService.getLiveCasinoGames().subscribe(
            (res) => {
                setLiveCasinoGames(mapLiveCasinoGames(res))
            }
        )

        return () => {
            liveGamesSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            if (location.pathname.indexOf('/promo') > -1) {
                setTimeout(() => {
                    window.location.href = `https://www.promo.bet25.dk/casino/${
                        window.location.pathname.split('/').slice(-1)[0]
                    }`
                }, 0)
            }
            if (location.pathname.indexOf('/mitid-verify/confirm') > -1) {
                setTimeout(() => {
                    modalService.showModal('CPR_CONFIRMATION', {
                        initialProps: { modalTopMargin: '0' },
                        idp: match.params.type,
                    })
                }, 0)
            }
            if (location.pathname.indexOf('/mitid-verify/success') > -1) {
                signupHelper(match, history)
            }
            if (location.state?.deposit) {
                const { url, status } = location.state
                const parsedUrl = new URL(url)
                const amount = parsedUrl.searchParams.get('amount')
                const reason = parsedUrl.searchParams.get('reason')
                depositAction(status, amount, reason)
                history.replace({
                    ...history.location,
                    state: { deposit: false },
                })
            }
        },
        [location]
    )

    useEffect(
        () => {
            //move it or refactor
            if (geoData) {
                if (!geoData.allowed) {
                    modalService.showModal('GEO_DATA')
                } else {
                    if (
                        history.location.pathname === '/opret-konto' ||
                        history.location.pathname === '/opret-bruger'
                    ) {
                        setTimeout(() => {
                            modalService.showModal('SIGN_UP', {
                                initialProps: {
                                    modalTopMargin: '0',
                                    ...byPlatform('', { autoScroll: true }),
                                },
                            })
                        }, 0)
                    } else if (history.location.pathname === '/log-ind') {
                        modalService.showModal('LOGIN', {
                            initialProps: { modalTopMargin: '0' },
                        })
                    } else if (
                        history.location.pathname === '/testing/log-ind'
                    ) {
                        modalService.showModal('LOGIN', {
                            initialProps: { modalTopMargin: '0' },
                            isMitId: false,
                        })
                    } else if (
                        history.location.pathname ===
                        '/mitid-verify/forgotpassword'
                    ) {
                        if (Object.keys(userProfile).length) {
                            modalService.showModal('RESTORE_PASS', {
                                userProfile: userProfile,
                                initialProps: { modalTopMargin: '0' },
                            })
                        } else {
                            history.push('/404')
                        }
                    }
                }
            }
        },
        [geoData?.allowed]
    )

    useEffect(
        () => {
            if (isUserLoggedIn) {
                GamesUtilsService.fetchRecommendedGames()
                GamesUtilsService.getRecommendedGames().subscribe((res) => {
                    if (res) {
                        setRecommendedGames(res)
                    }
                })
            }
        },
        [isUserLoggedIn, favoriteGames]
    )

    useEffect(() => {
        window.scrollTo(0, 0)

        GamesService.getPopularGames({
            limit: TOP_GAMES_LIMIT,
            period: 1,
            excludeHot: true,
        })
            .then((res) => {
                if (res.length) {
                    setTopPopularGames(res)
                }
            })
            .catch((e) => console.error(e))

        GamesService.getPopularGames({
            limit: POPULAR_GAMES_LIMIT,
            period: 7,
            rodHot: true,
        })
            .then((res) => {
                if (res.length) {
                    setPopularGames(sortingByHotClicks(res))
                }
            })
            .catch((e) => console.error(e))
    }, [])

    useEffect(
        () => {
            if (gamesToMap?.length) {
                setJackpotGames(
                    gamesToMap
                        .filter(casinoFilters.jackpot)
                        .sort(
                            (a, b) =>
                                parseFloat(b.jackpot_amount) -
                                    parseFloat(a.jackpot_amount) ||
                                a.name.localeCompare(b.name)
                        )
                )

                const filteredNewGames = gamesToMap
                    .filter(
                        (game) =>
                            game.casinoPageCategories.includes('new') &&
                            game.category.includes('slots')
                    )
                    .sort((a, b) => a.priority - b.priority)
                setNewGames(filteredNewGames)
                //setNewLoader(false)
            }
        },
        [gamesToMap]
    )

    const getGreeting = (name) => {
        const currentHour = new Date().getHours()
        let greeting

        if (currentHour >= 5 && currentHour < 10) {
            greeting = greetings.morning
        } else if (currentHour >= 10 && currentHour < 12) {
            greeting = greetings.forenoon
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = greetings.afternoon
        } else if (currentHour >= 18 && currentHour <= 23) {
            greeting = greetings.evening
        } else {
            greeting = greetings.night
        }

        return `${greeting}, ${name}`
    }

    const additionalJackpotGames = (gameIds = []) =>
        gamesToMap.reduce((array, game) => {
            if (gameIds.includes(game.provider_id)) {
                return array.concat(game)
            } else {
                return array
            }
        }, [])

    return (
        <PageWrapper>
            <Suspense fallback={renderLoader('100px')}>
                <HighlightSlider theme={theme} loop={!detectMobile()} />

                <PageContent
                    pl={detectMobile() ? 0 : 3}
                    pr={detectMobile() ? 0 : 3}
                    pb={4}
                    maxWidth={'1140px'}
                >
                    {isUserLoggedIn ? (
                        <SliderSection padding={0}>
                            <Title as={'h3'}>
                                {getGreeting(userProfile.firstName)}
                                <TitleIcon />
                            </Title>

                            <GamesSliderWithTabs
                                gamesUpdateHandler={(games) =>
                                    setFavoriteGames(games)
                                }
                                userProfile={userProfile}
                            />
                        </SliderSection>
                    ) : null}

                    <SliderSection bdRadius={'20px 20px 0 0'} bg={'#F0F4FA'}>
                        {recommendedGames.length && isUserLoggedIn ? (
                            <GamesSlider
                                gamesData={recommendedGames}
                                gamesCount={recommendedGames.length}
                                title={'Anbefalet til dig'}
                            />
                        ) : null}
                        <Box pt={isUserLoggedIn ? 4 : 0}>
                            <GamesSlider
                                gamesData={popularGames}
                                gamesCount={popularGames.length}
                                title={'Mest populære spil'}
                                buttonLink={'/automater/populære'}
                                labelProps={POPULAR_GAME_LABEL}
                            />
                        </Box>
                    </SliderSection>
                    <SliderSection>
                        <GamesSlider
                            gamesData={newGames}
                            gamesCount={newGames?.length}
                            vertical
                            title={'Nyeste spil'}
                            buttonLink={'/automater/nyheder'}
                            labelProps={NEW_GAME_LABEL}
                        />
                    </SliderSection>

                    <StyledSection bg={'#eaf1fe'}>
                        <JackpotHighlight
                            getAdditionalGames={additionalJackpotGames}
                            games={jackpotGames}
                            title={'Jackpots'}
                            buttonLink={'/automater/jackpots'}
                        />

                        <JackpotCardsSlider games={jackpotGames} />

                        <StyledGamesSliderWrapper>
                            <GamesSlider
                                gamesData={jackpotGames}
                                gamesCount={jackpotGames?.length}
                                title={'Største jackpots'}
                                buttonLink={'/automater/jackpots'}
                            />
                        </StyledGamesSliderWrapper>
                    </StyledSection>

                    <SliderSection>
                        <GamesSlider
                            gamesData={topPopularGames}
                            gamesCount={topPopularGames.length}
                            showNumberLabel={true}
                            title={'Dagens Top 5'}
                        />
                    </SliderSection>

                    <StyledSection bg={'#3E1C20'}>
                        <LiveCasinoHighlight
                            games={liveCasinoGames}
                            title={'Live Casino'}
                            buttonLink={'/live-casino/alle'}
                        />
                        <LiveCasinoCardsSlider games={liveCasinoGames} />
                        <StyledGamesSliderWrapper color={'white'}>
                            <GamesSlider
                                gamesData={liveCasinoGames}
                                gamesCount={liveCasinoGames?.length}
                                title={'Mest populære Live Casino'}
                                buttonLink={'/live-casino/alle'}
                            />
                        </StyledGamesSliderWrapper>
                    </StyledSection>

                    <SliderSection>
                        <ProviderSlider />
                    </SliderSection>

                    <SliderSection>
                        <SearchSection />
                    </SliderSection>

                    <SliderSection>
                        <HelpCenter />
                    </SliderSection>
                </PageContent>
            </Suspense>
        </PageWrapper>
    )
}

export default withTheme(WithGames(HomePage))
