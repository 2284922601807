import Slider from 'features/Carousel/Slider'
import styled, { css } from 'styled-components'
import React, { useEffect, useState } from 'react'
import GamesService from 'services/GamesService'
import gamesService from 'services/GamesService'
import { useHistory } from 'react-router-dom'
import Icon from 'ui/Icon'
import arrowRight from 'images/icons/basic-icons/arrowRightThin.svg'
import { byMedia, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import Text from 'ui/Text'
import { renderLoader } from 'ui/RenderLoader'
import SliderControl from 'ui/controls/SliderControl'
import GameCard from 'features/GamesList/GameCard'
import { modalService } from 'services/ModalService'

const onPage = byMedia({
    sm: 2,
    md: 4,
    lg: 5,
    xl: 5,
})

const SlideWrapper = styled.div`
    display: inline-block;
    padding: 5px;
    transition: all 0.3s ease;

    ${({ disable }) =>
        !disable &&
        css`
            &:hover {
                transform: scale(1.05);
                cursor: pointer;
            }
        `};
`

const Slide = styled(GameCard)`
    border-radius: 8px;
    width: 100%;
`

const RecentlyPlayedSlider = ({
    children,
    userId,
    gamesData,
    infinite = false,
    slidesToScroll = 2,
}) => {
    const [slides, setSlides] = useState([])
    const [nextBtn, setNextBtn] = useState(true)
    const [prevBtn, setPrevBtn] = useState(false)
    const [hideControl, setHideControl] = useState(true)
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    useEffect(
        () => {
            const { received, games } = gamesData

            if (received) {
                setLoading(false)
                setSlides(games)
                setHideControl(
                    games.filter((item) => !!item.game_id).length <= onPage
                )
            }
        },
        [gamesData]
    )

    useEffect(
        () => {
            const allGamesSubscription = gamesService
                .getAllProvidersGames()
                .subscribe((games) => {
                    gamesService.setAllProvidersGames(games)
                    gamesService.setRecentlyPlayedGames(userId)
                })
            return () => {
                allGamesSubscription.unsubscribe()
            }
        },
        [userId]
    )

    const beforeSlideChange = (oldIndex, newIndex) => {
        setNextBtn(newIndex + onPage < slides.length)
        setPrevBtn(!!newIndex)
    }

    const startGame = (slide) => {
        if (slide.game_id) {
            GamesService.checkLoginBeforeStart(slide, history)
            if (
                localStorage.getItem('logged') !== null &&
                localStorage.getItem('logged') !== 'false'
            ) {
                GamesService.pushGameToRecentlyPlayed(slide)
            }
        }
    }

    const gameInfoModal = (game) => {
        modalService.showModal('GAME_INFO', {
            game,
            gameClickCallback: () => startGame(game),
            initialProps: {
                animation: true,
                outsideClose: true,
            },
        })
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && renderLoader('100px')}
            {slides.length ? (
                <>
                    {children}
                    <Slider
                        renderNextBtn={(next) => (
                            <SliderControl
                                right
                                onClick={next}
                                disabled={!infinite && !nextBtn}
                                hide={hideControl}
                            >
                                <Icon
                                    src={arrowRight}
                                    size={'lg'}
                                    scale={0.9}
                                    color={'black'}
                                />
                            </SliderControl>
                        )}
                        renderPrevBtn={(prev) => (
                            <SliderControl
                                left
                                onClick={prev}
                                disabled={!infinite && !prevBtn}
                                hide={hideControl}
                            >
                                <Icon
                                    src={arrowRight}
                                    size={'lg'}
                                    scale={0.9}
                                    color={'black'}
                                    style={{ transform: 'rotate(180deg)' }}
                                />
                            </SliderControl>
                        )}
                        perPage={onPage}
                        slidesToScroll={slidesToScroll}
                        onChange={beforeSlideChange}
                        infinite={infinite}
                        adaptiveHeight={false}
                    >
                        {slides.map((slide, index) => (
                            <SlideWrapper
                                key={index}
                                onClick={(e) =>
                                    detectMobile()
                                        ? gameInfoModal(slide)
                                        : startGame(slide)
                                }
                            >
                                <Slide
                                    key={slide.id}
                                    game={slide}
                                    gameClickHandler={() => startGame(slide)}
                                />
                                <Text
                                    align={'center'}
                                    style={{ paddingTop: '5px' }}
                                >
                                    {slide.name ? slide.name : ''}
                                </Text>
                            </SlideWrapper>
                        ))}
                    </Slider>
                </>
            ) : null}
        </div>
    )
}

export default RecentlyPlayedSlider
