import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'ui/Icon'
import Text from 'ui/Text'
import arrowIcon from 'images/icons/basic-icons/trailing-arrow.svg'

const SmallCard = styled.div`
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${({ background }) => background ?? 'white'};
    cursor: pointer;
    transition: transform 200ms ease-in-out;

    &:hover {
        transform: scale(1.01);
    }

    @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.lg + 'px'}) {
        margin: 5px;
    }
`
const IconsContainer = styled.div`
    display: flex;
    margin-bottom: 8px;
`
const Title = styled(Text)`
    font-family: 'ATG Rubrik', sans-serif;
    font-size: 1.5em !important;
`
const AmountContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 20px;
`
const StyledText = styled(Text)`
    line-height: 24px;
`

const LiveCasinoCard = ({ game }) => {
    const history = useHistory()

    const onCategoryClick = (url) => {
        history.push(`/live-casino/${url}`)
    }

    return (
        <SmallCard
            background={game.background}
            onClick={() => onCategoryClick(game.url)}
        >
            <IconsContainer>
                <Icon
                    src={game.icon}
                    color="white"
                    height="24px"
                    width="24px"
                />
            </IconsContainer>
            <Title size={'lg'} color={'#fff'}>
                {game.title}
            </Title>
            <AmountContainer>
                <StyledText size={'sm'} color={'#fff'}>
                    {game.gamesCount || 0} spil
                </StyledText>
                <Icon
                    src={arrowIcon}
                    height={'24px'}
                    width={'24px'}
                    color={'white'}
                />
            </AmountContainer>
        </SmallCard>
    )
}

LiveCasinoCard.propTypes = {
    game: PropTypes.shape({
        title: PropTypes.string.isRequired, // Title of the game
        url: PropTypes.string.isRequired, // URL path for navigation
        icon: PropTypes.string.isRequired, // Path to the icon image
        background: PropTypes.string, // Background style for the card
        gamesCount: PropTypes.number, // Number of games in the category
    }).isRequired, // Game object
}

export default LiveCasinoCard
