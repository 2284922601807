import React from 'react'
import styled, { css } from 'styled-components'
import COLORS from '../../themes/primary/palette'

const ControlButton = styled.button`
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
    width: ${({ width }) => (width ? width : 'auto')};
    height: 40px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    top: ${({ top }) => top || '0'};
    right: 0;
    border: 1px solid ${COLORS.stroke_400};
    cursor: pointer;
    background: white;
    transition: all 0.3s ease;
    border-radius: 8px;
    background: ${(props) => (props.bg ? props.bg : COLORS.light)};
    color: ${COLORS.black_font_900};

    border: 1px solid
        ${({ active = true }) => (active ? COLORS.stroke_400 : 'transparent')};
    ${({ disabled }) =>
        disabled
            ? css`
                  background: ${COLORS.lightGray};
                  cursor: default;

                  svg {
                      opacity: 0.2;
                  }
              `
            : css`
                  &:hover {
                      box-shadow: 0 0 3px #9698a1;
                  }
              `};
`

const SliderControl = ({ children, ...props }) => {
    return <ControlButton {...props}>{children}</ControlButton>
}

export default SliderControl
