import { useEffect, useState } from 'react'
import { CMS_PATH } from 'configs/rest'
import rouletteIcon from 'images/casino/icons/roulette-vector.svg'
import blackjackIcon from 'images/casino/icons/blackjack-vector.svg'
import pokerIcon from 'images/casino/icons/poker-vector.svg'
import baccaratIcon from 'images/casino/icons/baccarat-vector.svg'

const t = window.T

const IMG_PATH =
    CMS_PATH +
    '/upload/files/rod25/images/uploads/roed25/img/live-casino/categories/'

const LIVE_CASINO_CATEGORIES = [
    {
        name: 'roulette',
        title: t.liveCasinoPages.roulette,
        url: 'roulette',
        icon: rouletteIcon,
        background: `url('${IMG_PATH}roulette.png') 50px 0px / cover no-repeat, linear-gradient(0deg, #103824 0%, #206F47 100%)`,
    },
    {
        name: 'blackJack',
        title: t.liveCasinoPages.blackJack,
        url: 'blackJack',
        icon: blackjackIcon,
        background: `url(${IMG_PATH}blackjack.png) 58px 0px / cover no-repeat, linear-gradient(0deg, #381024 0%, #6F2048 100%)`,
    },
    {
        name: 'poker',
        title: t.liveCasinoPages.poker,
        url: 'poker',
        icon: pokerIcon,
        background: `url(${IMG_PATH}poker.png) 35px 0px  / cover no-repeat, linear-gradient(0deg, #102438 0%, #20486F 100%)`,
    },
    {
        name: 'baccarat',
        title: t.liveCasinoPages.baccarat,
        url: 'baccarat',
        icon: baccaratIcon,
        background: `url(${IMG_PATH}baccarat.png) 30px 0px / cover no-repeat, linear-gradient(0deg, #382410 0%, #6F4720 100%)`,
    },
]

const useLiveCasinoData = (games) => {
    const [categorizedGames, setCategorizedGames] = useState(
        LIVE_CASINO_CATEGORIES
    )

    useEffect(
        () => {
            if (games?.length) {
                const categoryCounts = {}
                games.forEach((game) => {
                    if (game.liveCas) {
                        game.liveCasinoPageCategories.forEach(
                            (categoryName) => {
                                if (!categoryCounts[categoryName]) {
                                    categoryCounts[categoryName] = 0
                                }
                                categoryCounts[categoryName]++
                            }
                        )
                    }
                })

                const updatedCategories = LIVE_CASINO_CATEGORIES.map(
                    (category) => ({
                        ...category,
                        gamesCount: categoryCounts[category.name] || 0,
                    })
                )

                setCategorizedGames(updatedCategories)
            }
        },
        [games]
    )

    return {
        categorizedGames,
    }
}

export default useLiveCasinoData
