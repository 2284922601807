import styled from 'styled-components'
import {
    marginProps,
    paddingProps,
    borderProps,
    borderRadiusProps,
} from '@/ui/SpacingUtility'
import PropTypes from 'prop-types'

const Box = styled.div`
    display: inline-block;
    ${marginProps};
    ${paddingProps};
    ${borderRadiusProps};
    background: ${(props) => (props.background ? props.background : 'none')};
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : 'auto')};
`

Box.propTypes = {
    background: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    width: PropTypes.string,
    height: PropTypes.string,
}

export default Box
