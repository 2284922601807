import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'ui/Icon'
import arrowRight from 'images/icons/basic-icons/arrowRightThin.svg'
import COLORS from 'themes/primary/palette'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { BREAKPOINTS } from '../../themes'
import { useHistory } from 'react-router-dom'

const Title = styled.h3`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.dark};
    margin: 0;
    font-size: 32px;
    cursor: ${(props) => (props.hoverEffect ? 'pointer' : 'auto')};

    span {
        color: ${COLORS.gray};
        margin: 0 0 0 5px;
    }

    @media screen and (max-width: ${BREAKPOINTS.sm + 'px'}) {
        font-size: 20px;
    }
`

const CategoryTitle = ({
    title,
    labelTitle,
    isShowMore = false,
    titleLink = '',
}) => {
    const history = useHistory()

    const onClickTitle = () => {
        titleLink ? history.push(titleLink) : null
    }

    return (
        <Title
            hoverEffect={isShowMore}
            onClick={isShowMore ? onClickTitle : null}
        >
            {title} {labelTitle && <span>{labelTitle}</span>}
            {isShowMore &&
                !detectMobile() && (
                    <Icon size={'lg'} src={arrowRight} color={'black'} m={2} />
                )}
        </Title>
    )
}

CategoryTitle.propTypes = {
    title: PropTypes.string.isRequired, // 'title' should be a required string
    labelTitle: PropTypes.string, // 'labelTitle' is an optional string
    isShowMore: PropTypes.bool, // 'isShowMore' should be a boolean
}

export default CategoryTitle
