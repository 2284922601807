import styled from 'styled-components'

const Thumb = styled.img`
    overflow: hidden;
    border-radius: 8px;
    height: ${(props) => (props.height ? props.height : 'auto')};
    width: ${(props) => (props.width ? props.width : '100%')};
    object-fit: cover;
    aspect-ratio: ${(props) =>
        props.aspectRatio ? props.aspectRatio : 'auto'};
`

export default Thumb
