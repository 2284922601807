import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import Icon from 'ui/Icon'
import Text from 'ui/Text'
import { renderLoader } from 'ui/RenderLoader'
import { thousandsDots } from 'utils/formatters'

import diamondIcon from 'images/icons/diamond.svg'
import arrowIcon from 'images/icons/basic-icons/trailing-arrow-transparent.svg'

const scaleAnimation = keyframes`
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
`

const SmallCardsContainer = styled.div`
    gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.lg + 'px'}) {
        display: none;
    }
`
const SmallCard = styled.div`
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${({ background }) => background ?? 'white'};
    cursor: pointer;
    transition: transform 200ms ease-in-out;

    &:hover .diamond {
        animation: ${scaleAnimation} 0.5s ease-in-out forwards;
    }

    &:hover {
        transform: scale(1.01);
    }

    @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.lg + 'px'}) {
        margin: 5px;
    }
`
const IconsContainer = styled.div`
    display: flex;
    margin-bottom: 8px;

    & .diamond {
        transition: transform 300ms ease-in-out;
        transform-origin: center;
    }

    & .diamond:nth-child(1) {
        animation-delay: 0s;
    }

    & .diamond:nth-child(2) {
        animation-delay: 0.1s;
    }

    & .diamond:nth-child(3) {
        animation-delay: 0.2s;
    }

    & .diamond:nth-child(4) {
        animation-delay: 0.3s;
    }
`
const Title = styled(Text)`
    font-family: 'ATG Rubrik', sans-serif;
    font-size: 1.5em !important;
`
const AmountContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`
const AmountButton = styled.div`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    line-height: 32px;
    transition: background-color 200ms ease-in-out;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
`

const StyledText = styled(Text)`
    font-family: 'ATG Rubrik', sans-serif;
    letter-spacing: 2px;
    line-height: 32px;
`

const JackpotCard = ({ item }) => {
    const history = useHistory()

    const onCategoryClick = (levelName) => {
        history.push(`/automater/jackpots`, { levelName })
    }
    return (
        <SmallCard
            background={item.background}
            onClick={() => onCategoryClick(item.name)}
        >
            <div>
                <IconsContainer>
                    {Array.from({ length: item.iconsNumber }).map(
                        (_, index) => (
                            <Icon
                                key={index}
                                src={diamondIcon}
                                color="white"
                                height="24px"
                                width="24px"
                                className="diamond"
                            />
                        )
                    )}
                </IconsContainer>
                <Title size={'xl'} color={'#fff'}>
                    {item.title}
                </Title>
                <AmountContainer>
                    <Text size={'sm'} color={'#fff'}>
                        {item.gamesCount || 0} spil
                    </Text>
                    <Icon
                        src={arrowIcon}
                        height={'24px'}
                        width={'24px'}
                        color={'white'}
                    />
                </AmountContainer>
            </div>
            <AmountButton>
                {!item?.amount ? (
                    renderLoader()
                ) : (
                    <StyledText size={'lg'} color={'#fff'}>
                        {thousandsDots(item.amount)} kr.
                    </StyledText>
                )}
            </AmountButton>
        </SmallCard>
    )
}

const NewJackpotCard = ({ jackpotsData }) => {
    return (
        <SmallCardsContainer>
            {Object.values(jackpotsData.levels).map((item, key) => {
                return <JackpotCard item={item} key={key} />
            })}
        </SmallCardsContainer>
    )
}

export { JackpotCard }
export default NewJackpotCard
