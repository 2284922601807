import React, { useEffect, useState } from 'react'
import Flex from 'ui/Flex'
import Loader from 'ui/Loader'
import { CategoryWrapper } from 'features/ProvidersContent/styled'
import GameList from 'features/GamesList/GameList'
import Box from 'ui/Box'
import MediaGrid from 'ui/MediaGrid'
import moment from 'moment'
import { sortingByHotClicks } from 'utils/sorting'
import GamesService from '../../services/GamesService'
import { useHistory, useRouteMatch } from 'react-router-dom'
import GamesSlider from '../GamesSlider'
import CategoryTitle from '../GamesSlider/CategoryTitle'
import {
    byMedia,
    detectMobile,
} from '../../common/adapters/DeviceDetectAdapter'
import { PROVIDERS } from '../../common/providers'

const onPageTop = byMedia({
    sm: 1.5,
    md: 2,
    lg: 2,
    xl: 3,
})

const onPageNew = byMedia({
    sm: 1.5,
    md: 3,
    lg: 3,
    xl: 4,
})

const ProviderItem = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const [allGames, setAllGames] = useState([])
    const [preparedGames, setPreparedGames] = useState(allGames)
    const [newGames, setNewGames] = useState([])
    const [topGames, setTopGames] = useState([])
    const [provider, setProvider] = useState({})

    const findProvider = PROVIDERS.find(
        (item) => item.value === match.params.subCategory
    )

    const filterByOpened = (games) => {
        const filteredGames = games.filter(
            (game) =>
                !game.liveCas || (game.liveCas && game.liveCas.open !== false)
        )

        return filteredGames
    }

    const filterNewGames = (games) =>
        games
            .sort(
                (a, b) =>
                    (parseInt(
                        moment(b.release_date).format('YYYYMMDDHHmmss')
                    ) || 0) -
                        (parseInt(
                            moment(a.release_date).format('YYYYMMDDHHmmss')
                        ) || 0) ||
                    b.rod_new - a.rod_new ||
                    parseInt(a.priority) - parseInt(b.priority) ||
                    a.name.localeCompare(b.name)
            )
            .slice(0, 4)

    const fetchGames = (subproviderName) => {
        const filterName =
            subproviderName === 'scientific'
                ? 'scientific,gdm'
                : subproviderName
        GamesService.searchGames(`filter[subprovider]`, filterName, 200)
            .then((res) => {
                if (res.length) {
                    setAllGames(res)
                } else {
                    history.push('/404')
                }
            })
            .catch(console.error)
    }

    useEffect(() => {
        fetchGames(match.params.subCategory)
        setProvider(findProvider)
    }, [])

    useEffect(
        () => {
            if (allGames.length) {
                const filteredGames = filterByOpened(allGames)
                const newGamesSorting = new Array(...filteredGames)
                setPreparedGames(sortingByHotClicks(filteredGames))
                setNewGames(filterNewGames(newGamesSorting))
                setTopGames(filteredGames.slice(0, 3))
            }
        },
        [allGames]
    )

    return !Object.keys(provider).length && !allGames.length ? (
        <Flex mt={3}>
            <Loader />
        </Flex>
    ) : (
        <>
            {!!topGames.length && (
                <CategoryWrapper>
                    <GamesSlider
                        gamesData={topGames}
                        showNumberLabel={true}
                        title={'Top 3 fra ' + provider.label}
                        onPage={onPageTop}
                    />
                </CategoryWrapper>
            )}
            {!!newGames.length && (
                <CategoryWrapper>
                    <GamesSlider
                        gamesData={newGames}
                        vertical
                        title={'Nyheder'}
                        onPage={onPageNew}
                    />
                </CategoryWrapper>
            )}
            <CategoryWrapper>
                <CategoryTitle title={'Alle spil'} />
                <Box my={3}>
                    <MediaGrid>
                        <GameList games={preparedGames} />
                    </MediaGrid>
                </Box>
            </CategoryWrapper>
        </>
    )
}

export default ProviderItem
