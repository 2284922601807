import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Flex from 'ui/Flex'
import Text from 'ui/Text'
import helpImageFirst from 'images/homepage/help-image-1.png'
import helpImageSecond from 'images/homepage/livecasino-highlight.png'
import helpImageThird from 'images/homepage/help-image-2.png'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import { detectMobile } from '../../common/adapters/DeviceDetectAdapter'

const cardProps = [
    {
        title: 'KUNDESERVICE',
        subtitle: 'Brug for hjælp?',
        description: 'Chat med os hver dag fra 10:00 til 21:00.',
        actionText: 'Kontakt os',
        handleAction: () => {
            IntercomAdapter.showMessages()
        },
        cardBackground: `url(${helpImageFirst})  
        center 55px / cover no-repeat, 
        linear-gradient(180deg, rgba(255, 139, 0, 0.00) 50%, #FF8B00 100%), #FFAD58;`,
    },
    {
        title: 'KUNDESERVICE',
        subtitle: 'Hjælpecenter',
        description: 'Find selv svar på det meste',
        actionText: 'Gå til hjælpecenter',
        handleAction: () => {
            IntercomAdapter.showHelp()
        },
        cardBackground: `url(${helpImageSecond})  
        center 55px / cover no-repeat, 
        linear-gradient(180deg, rgba(88, 255, 175, 0.00) 50%, #58FFAF 100%), #58E5A1`,
    },
    {
        title: 'KUNDESERVICE',
        subtitle: '25 Nyt',
        description: 'Kampagner og tilbud',
        actionText: 'Gå til 25 nyt',
        handleAction: (history) => {
            history.push('/25nyt')
        },
        cardBackground: `url(${helpImageThird})  
        center 55px / cover no-repeat, 
        linear-gradient(180deg, rgba(121, 88, 255, 0.00) 50%, #7958FF 100%), #7958FF`,
    },
]

const StyledFlex = styled(Flex)`
    flex-wrap: wrap;
    ${() => detectMobile() && `padding-right: 16px;`};
`

const SectionWrapper = styled.div`
    position: relative;
    height: 320px;
    border-radius: 16px;
    padding: 20px;
    width: 100%;
    ${({ bg }) => bg && `background: ${bg}`};
    ${({ bigSection }) => (bigSection ? `flex-basis: 50%` : `flex-basis: 20%`)};
    flex-grow: 1;
    @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.md + 'px'}) {
        ${({ bigSection }) => bigSection && `flex-basis: 100%`};
    }
`
const ActionSection = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 100%;
    border-radius: 0 0 16px 16px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
`
const ActionButton = styled.div`
    display: flex;
    height: 40px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    background: #fff;
    cursor: pointer;
    transition: transform 200ms ease-in-out;

    &:hover {
        transform: scale(1.02);
    }
`

const HelpCenter = (props) => {
    const history = useHistory()

    return (
        <StyledFlex justify={'space-between'} gap={'16px'}>
            {cardProps.map((card, i) => (
                <SectionWrapper
                    bg={card.cardBackground}
                    bigSection={i === 0}
                    key={i}
                >
                    <Text color={'rgba(255,255,255,0.5)'} bold size={'sm'}>
                        {card.title}
                    </Text>
                    <Text color={'white'} bold size={'xl'}>
                        {card.subtitle}
                    </Text>
                    <Text color={'white'} size={'md'}>
                        {card.description}
                    </Text>
                    <ActionSection>
                        <ActionButton
                            onClick={() => card.handleAction(history)}
                        >
                            <Text
                                size={'sm'}
                                color={'#1E1E1E'}
                                bold
                                align={'center'}
                            >
                                {card.actionText}
                            </Text>
                        </ActionButton>
                    </ActionSection>
                </SectionWrapper>
            ))}
        </StyledFlex>
    )
}

export default HelpCenter
