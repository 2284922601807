import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import CardWithAction from 'features/CardHighlightSection/CardWithAction'
import CardHighlight from 'features/CardHighlightSection/CardHighlight'
import liveCasinoHighlight from 'images/homepage/livecasino-highlight.png'
import danishFlag from 'images/icons/danish-flag.svg'
import LiveCasinoCard from './LiveCasinoCard'
import useLiveCasinoData from './useLiveCasinoData'
import { DANSK_ROULETTE_ID } from '../../configs/categories'

const cardProps = {
    title: 'Live fra hele verden',
    subtitle: 'LIVE CASINO',
    amountDisplayText: 'Åben',
    amountTitleColor: 'rgba(0, 0, 0, 0.50)',
    arrowColor: 'rgba(0, 0, 0, 0.50)',
    amountColor: '#DB1C1B',
    icon: danishFlag,
    cardBackground: `url(${liveCasinoHighlight}) center / cover no-repeat,
    linear-gradient(180deg, rgba(61, 27, 32, 0.00) 50%, #3D1B20 100%),
    #582830`,
}

const SmallCardsContainer = styled.div`
    gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: ${(props) => props.theme.breakpoints.lg + 'px'}) {
        display: none;
    }
`

const LiveCasinoHighlight = ({ games, buttonLink, title }) => {
    const { categorizedGames } = useLiveCasinoData(games)
    const [highlightGame, setHighlightGame] = useState(null)

    useEffect(
        () => {
            if (games?.length) {
                const selectedGameId =
                    window.location.hostname.indexOf('dev') > -1 ||
                    window.location.hostname.indexOf('localhost') > -1
                        ? 3192
                        : DANSK_ROULETTE_ID
                const selectedGame = games.find(
                    (game) => game.id === selectedGameId
                )
                if (selectedGame) {
                    setHighlightGame(selectedGame)
                } else {
                    setHighlightGame(games[0])
                }
            }
        },
        [games]
    )

    return (
        <CardHighlight games={games} buttonLink={buttonLink} title={title}>
            <CardWithAction
                {...cardProps}
                amountTitle={highlightGame?.name ?? ''}
                selectedGame={highlightGame ?? []}
                additionalIcon={
                    highlightGame?.id === DANSK_ROULETTE_ID && cardProps.icon
                }
            />
            <SmallCardsContainer>
                {categorizedGames.map((game, key) => (
                    <LiveCasinoCard game={game} key={key} />
                ))}
            </SmallCardsContainer>
        </CardHighlight>
    )
}

LiveCasinoHighlight.propTypes = {
    games: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            category: PropTypes.string,
        })
    ), // Array of game objects
    buttonLink: PropTypes.string.isRequired, // URL string for the button link
    title: PropTypes.string.isRequired, // Title for the component
}

export default LiveCasinoHighlight
