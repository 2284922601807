import React, { useEffect, useState } from 'react'
import PageWrapper from 'ui/PageWrapper'
import PageContent from 'ui/PageContent'
import ProvidersList from 'features/ProvidersContent/ProvidersList'
import ProviderItem from 'features/ProvidersContent/ProviderItem'
import { useHistory, useRouteMatch } from 'react-router-dom'
import GamesService from 'services/GamesService'
import { PROVIDERS } from 'common/providers'
import Flex from 'ui/Flex'
import Loader from 'ui/Loader'
import bg1 from 'images/providers/slidesTemp/All_providers.jpg'
import { CASINO_CATEGORIES } from 'configs/categories'

const ProvidersPage = () => {
    const defaultSlides = [bg1] // temp

    const [pageState, setPageState] = useState('')
    const [pageData, setPageData] = useState({})
    const [providersForShowing, setProvidersForShowing] = useState([])
    const [slides, setSlides] = useState(defaultSlides)

    const match = useRouteMatch()
    const history = useHistory()

    const onProviderClick = (data) => {
        setPageState(match.params?.subCategory || '')
        setPageData(data)
        history.push(`/automater/spiludviklere/${data.value}`)
    }

    const getCurrentCategory = () =>
        CASINO_CATEGORIES.find((e) => e.url === match.params?.category)

    useEffect(
        () => {
            const category = getCurrentCategory()?.name

            //TODO: requires to be changed || probably removed
            setPageState(match.params?.subCategory || '')
            if (category === 'providers') {
                if (match.params?.subCategory && providersForShowing.length) {
                    const findProvider = PROVIDERS.find(
                        (item) => item.value === match.params.subCategory
                    )

                    if (findProvider?.background) {
                        setSlides([findProvider.background])
                    }
                } else {
                    setSlides(defaultSlides)
                }
            }
        },
        [match.params?.subCategory, providersForShowing]
    )

    useEffect(() => {
        const providersStatusSub = GamesService.getProvidersGames().subscribe(
            (res) => {
                if (res) {
                    setProvidersForShowing(res)
                }
            }
        )

        return () => {
            providersStatusSub.unsubscribe()
        }
    }, [])

    return (
        <PageContent maxWidth={'1140px'}>
            {!providersForShowing.length ? (
                <Flex mt={3}>
                    <Loader />
                </Flex>
            ) : pageState.length ? (
                <ProviderItem provider={pageData} />
            ) : (
                <ProvidersList
                    providerClick={onProviderClick}
                    providersList={providersForShowing}
                />
            )}
        </PageContent>
    )
}

export default ProvidersPage
