import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import gamesService from 'services/GamesService'
import { byMedia, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import Text from 'ui/Text'
import { renderLoader } from 'ui/RenderLoader'
import SliderControl from 'ui/controls/SliderControl'
import Box from 'ui/Box'
import CustomSlider from './CustomSlider'
import Img from '../../ui/Img'
import Flex from '../../ui/Flex'
import backIcon from '../../images/icons/back_icon.svg'
import starIcon from '../../images/icons/star_3d.svg'
import GamesUtilsService from '../../services/GamesUtilsService'
import { BREAKPOINTS } from '../../themes'
import COLORS from '../../themes/primary/palette'

const onPage = byMedia({
    sm: 2.8,
    md: 5,
    lg: 6,
    xl: 6,
})

const StyledWrapper = styled(Box)`
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NoGamesWrapper = styled.div`
    display: flex;
    height: 140px;
    border: 1px dashed ${({ theme }) => theme.stroke_400};
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;

    @media screen and (max-width: ${BREAKPOINTS.sm + 'px'}) {
        margin-right: 1rem;
    }
`

const HOME_TABS = {
    lastPlayed: {
        name: 'Seneste',
        icon: backIcon,
        noGamesTitle: 'Ingen seneste spillede',
        noGamesSubTitle: 'Fortsæt nemt og hurtigt, hvor du slap.',
    },
    favorite: {
        name: 'Favoritter',
        icon: starIcon,
        noGamesTitle: 'Ingen favoritter endnu',
        noGamesSubTitle: 'Find de spil du elsker, og tryk på stjernen!',
    },
}

const SliderWithTabs = ({
    userProfile = null,
    infinite = false,
    slidesToScroll = 1,
    gamesUpdateHandler = (games) => {},
}) => {
    const [tabGames, setTabGames] = useState([])
    const [favoriteGames, setFavoriteGames] = useState([])
    const [recentlyGames, setRecentlyGames] = useState([])
    const [activeTab, setActiveTab] = useState('lastPlayed')
    const [loading, setLoading] = useState(true)

    useEffect(
        () => {
            if (userProfile && userProfile.userId) {
                const allGamesSubscription = gamesService
                    .getAllProvidersGames()
                    .subscribe((games) => {
                        gamesService.setRecentlyPlayedGames(userProfile.userId)
                    })

                const favoriteSub = GamesUtilsService.getFavoriteGames().subscribe(
                    (res) => {
                        setFavoriteGames(res)
                        gamesUpdateHandler(res)
                        setLoading(false)
                    }
                )
                const recentlyGamesSub = gamesService
                    .getRecentlyPlayedGames()
                    .subscribe((res) => {
                        if (res.received) {
                            setRecentlyGames(res.games)
                            setLoading(false)
                        }
                    })

                return () => {
                    allGamesSubscription.unsubscribe()
                    recentlyGamesSub.unsubscribe()
                    favoriteSub.unsubscribe()
                    setTabGames([])
                }
            }
        },
        [userProfile.userId]
    )

    useEffect(
        () => {
            if (activeTab === 'lastPlayed') {
                setTabGames(recentlyGames)
            } else if (activeTab === 'favorite') {
                setTabGames(favoriteGames)
            }
        },
        [activeTab, recentlyGames, favoriteGames]
    )

    return (
        <div style={{ position: 'relative' }}>
            {loading ? (
                renderLoader('100px')
            ) : (
                <>
                    <StyledWrapper>
                        <Flex gap={'8px'} justify={'start'}>
                            <SliderControl
                                onClick={() => setActiveTab('lastPlayed')}
                                active={activeTab === 'lastPlayed'}
                                bg={COLORS.neutral}
                            >
                                <Img
                                    src={HOME_TABS.lastPlayed.icon}
                                    mr={1}
                                    width={'16px'}
                                />
                                {HOME_TABS.lastPlayed.name}
                            </SliderControl>
                            <SliderControl
                                onClick={() => setActiveTab('favorite')}
                                active={activeTab === 'favorite'}
                                bg={COLORS.neutral}
                            >
                                <Img
                                    src={HOME_TABS.favorite.icon}
                                    mr={1}
                                    width={'16px'}
                                />
                                {HOME_TABS.favorite.name}
                            </SliderControl>
                        </Flex>
                    </StyledWrapper>
                    {tabGames.length ? (
                        <CustomSlider
                            key={activeTab}
                            slides={tabGames}
                            showMoreBtn={false}
                            squareThumb={true}
                            infinite={infinite}
                            slidesToScroll={slidesToScroll}
                            hideControl={tabGames.length <= onPage}
                            aspectRatioThumbs={'1/1'}
                            onPage={onPage}
                            showJackpotAmount={!detectMobile()}
                        />
                    ) : activeTab === 'favorite' ? (
                        <NoGamesWrapper>
                            <Text bold>
                                {HOME_TABS[activeTab].noGamesTitle}
                            </Text>
                            {HOME_TABS[activeTab].noGamesSubTitle && (
                                <Text>
                                    {HOME_TABS[activeTab].noGamesSubTitle}
                                </Text>
                            )}
                        </NoGamesWrapper>
                    ) : null}
                </>
            )}
        </div>
    )
}

export default SliderWithTabs
