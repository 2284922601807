import Slider from 'features/Carousel/Slider'
import styled, { css } from 'styled-components'
import React, { useEffect, useState } from 'react'
import GamesService from 'services/GamesService'
import { useHistory } from 'react-router-dom'
import Icon from 'ui/Icon'
import arrowRight from 'images/icons/basic-icons/arrowRightThin.svg'
import rankingBg from 'images/icons/basic-icons/ranking-bg.svg'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import SliderControl from 'ui/controls/SliderControl'
import GameCard from 'features/GamesList/GameCard'
import { modalService } from 'services/ModalService'
import { EXCLUSIVE_GAME_LABEL } from '../../configs/ui'
import COLORS from '../../themes/primary/palette'
import SliderControls from './SliderControls'

const SlideWrapper = styled.div`
    justify-content: center;
    padding: 5px;
    transition: all 0.3s ease;

    ${({ disable }) =>
        !disable &&
        css`
            &:hover {
                transform: scale(1.03);
                cursor: pointer;
            }
        `};
`

const Slide = styled(GameCard)`
    border-radius: 8px;
    display: flex;
    justify-content: center;
    position: relative;
`

const TopGamesLabel = styled.div`
    position: absolute;
    width: 46px;
    height: 46px;
    top: -1px;
    left: -1px;
    background: url(${rankingBg}) no-repeat;
    display: flex;
    border-radius: 6px 0 0 0;
`

const TopGamesLabelNumber = styled.div`
    width: 24px;
    height: 24px;
    margin: 8px 0 0 8px;
    background: ${COLORS.primary};
    color: ${COLORS.white};
    font-family: 'ATG Rubrik', sans-serif;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`

const GamesSlider = ({
    slides = [],
    showMoreBtn = false,
    showMoreBtnValue = '',
    showMoreBtnLink = '',
    infinite = false,
    slidesToScroll = 1,
    hideControl = false,
    aspectRatioThumbs = '',
    onPage = 5,
    labelProps,
    vertical = false,
    showNumberLabel = false,
    showJackpotAmount = true,
}) => {
    const history = useHistory()

    const startGame = (slide) => {
        if (slide.game_id) {
            GamesService.checkLoginBeforeStart(slide, history)
            if (
                localStorage.getItem('logged') !== null &&
                localStorage.getItem('logged') !== 'false'
            ) {
                GamesService.pushGameToRecentlyPlayed(slide)
            }
        }
    }

    return (
        <>
            {slides.length ? (
                <SliderControls
                    slidesCount={slides.length}
                    showMoreBtn={showMoreBtn}
                    showMoreBtnValue={showMoreBtnValue}
                    showMoreBtnLink={showMoreBtnLink}
                    infinite={infinite}
                    slidesToScroll={slidesToScroll}
                    hideControl={hideControl}
                    onPage={onPage}
                >
                    {slides.map((slide, index) => (
                        <SlideWrapper
                            key={index}
                            onClick={(e) => startGame(slide)}
                        >
                            <Slide
                                key={slide.id}
                                game={slide}
                                gameClickHandler={() => startGame(slide)}
                                aspectRatio={aspectRatioThumbs}
                                labelProps={labelProps}
                                vertical={vertical}
                                showJackpotAmount={showJackpotAmount}
                            >
                                {showNumberLabel ? (
                                    <TopGamesLabel>
                                        <TopGamesLabelNumber>
                                            {index + 1}
                                        </TopGamesLabelNumber>
                                    </TopGamesLabel>
                                ) : null}
                            </Slide>
                        </SlideWrapper>
                    ))}
                </SliderControls>
            ) : null}
        </>
    )
}

export default GamesSlider
